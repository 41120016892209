import { IReportType } from "~/pages/Dashboard/Reports/types"

export interface IRequestReports {
  ReportType?: number
  page?: number
  pageSize?: number
  name?: string
  ReportName?: string
  Username?: string
  DateSince?: string
  DateUntil?: string
}

export interface IReportHistoryDto {
  id: string
  reportType: EReportType
  siteName?: string
  userName?: string
  reportDate: string
  reportName?: string
  inspectionDate: string
  completed: boolean
}

export type IReportHistoryArray = IReportHistoryDto[]

export interface IRequestReportExecutive {
  reportId?: string
  inspectionId?: string
  name?: string
}

export interface IRequestReport {
  reportId?: string
  inspectionId?: string
  name?: string
  type?: IReportType
  filters?: IReportFIlters
}

export interface IReportFIlters {
  subparkIds: number[],
  anomalyTypeIds: number[]
  minimumTemperature: number
  maximumTemperature: number
}

export interface IResponseReport {
  reportUrl?: string
}

export interface IOptionDto {
  id: number
  name: string
}

export interface IResponseFilters {
  anomalyTypes: IOptionDto[]
  subparks: IOptionDto[]
}

export enum EReportType {
  InspectionShort = 1,
  InspectionExecutive = 2,
  InspectionCsv = 3
}

export const ReportTypeDescriptions: { [key in EReportType]: string } = {
  [EReportType.InspectionShort]: "Térmico",
  [EReportType.InspectionExecutive]: "Executivo",
  [EReportType.InspectionCsv]: "CSV"
};
