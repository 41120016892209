export class RecoveryPasswordError {
  email = '';
  password = '';
  confirmationPassword = '';
}

export class RecoveryPassword {
  email = '';
  password = '';
  confirmationPassword = '';
}