import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import * as S from './styles'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const style = {
  position: 'relative',
  minWidth: 400,
  zIndex: 99,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
}

interface IModalProps {
  readonly children: React.ReactNode
  readonly isOpen: boolean
  readonly handleClose: () => void
  readonly title?: string
}

export default function MaterialModal({ children, isOpen, handleClose, title }: IModalProps) {
  const { t } = useTranslation()

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box sx={style}>
        {children}
        <Tooltip title={<h2>{title ?? t('Fechar')}</h2>}>
          <S.CloseButtonWrapper onClick={handleClose}>
            <CancelRoundedIcon />
          </S.CloseButtonWrapper>
        </Tooltip>
      </Box>
    </Modal>
  )
}
