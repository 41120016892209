import styled from 'styled-components'

import { theme } from '~/styles/theme'

export const ButtonStyled = styled.button`
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 5%;
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.primary};
  align-items: center;
  justify-content: center;

  @media (max-width: 380px) {
    width: 100%;
    padding: 0 4%;
  }

  .label {
    margin: auto;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    font-size: 14px;
    border: none;
  }

  &:hover {
    opacity: 0.9;
    background-color: ${(props) => props.theme.colors.primary};
  }

  .icon_loading {
    color: ${(props) => props.theme.colors.white};
    font-size: 20px;
    animation: loading 1s infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
