import styled from 'styled-components'

export const Container = styled.main`
  /* display: flex; */
  width: 100%;
`

export const Body = styled.main<{ signed: boolean }>`
  margin-left: ${props => props.signed ? '60px' : '0px'}
`
