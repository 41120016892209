/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { RequestSites, ISite, IDate, Data } from './types'
import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import { ApiAnomalies, ApiEditAnomalies } from '~/components/Map/types'
import { SiteSortingOrder } from '~/pages/Dashboard/Sites'
const defaultRoot = 'sites'

export const getSites = async (data: RequestSites) => {
  let url = `${defaultRoot}?`

  if (data.NameOrLocal) {
    const encodedLocal = encodeURIComponent(data.NameOrLocal)
    url += `NameOrLocal=${encodedLocal}`
  }

  switch (data.filter) {
    case SiteSortingOrder.LastInspectionNewestFirst.toString():
      url += `&Ordering=1`
      break
    case SiteSortingOrder.LastInspectionOldestFirst.toString():
      url += `&Ordering=2`
      break
    case SiteSortingOrder.FirstInspectionNewestFirst.toString():
      url += `&Ordering=3`
      break
    case SiteSortingOrder.FirstInspectionOldestFirst.toString():
      url += `&&Ordering=4`
      break
    default:
      url += `&Ordering=0`
      break
  }

  url += `&PageNumber=${data.page}&PageSize=${data.pageSize}`

  return await getJson<ISite[]>(url)
}

export const getSite = async (id?: string) => {
  let url = `${defaultRoot}`
  if (id) {
    url += `?Id=${id}`
  }
  return await getJson<ISite[]>(url)
}

export const deleteSite = async (id: string) => {
  let url = `${defaultRoot}?Id=${id}`
  return await deleteJson<boolean, boolean>(url)
}

export const getDates = async (siteId: string) => {
  let url = `${defaultRoot}/datadeinspecao/${siteId}`
  url += `?PageNumber=1&PageSize=999`
  return await getJson<IDate[]>(url)
}

export const putAnomalies = async (data: ApiEditAnomalies, id: string) => {
  let url = `${defaultRoot}/${id}`
  return await putJson<ApiEditAnomalies, string>(url, data)
}
export const postSite = async (data: FormData) => {
  let url = `${defaultRoot}`
  return await postJson<FormData, boolean>(url, data)
}

export const putSite = async (data: FormData, id: string) => {
  let url = `${defaultRoot}`
  return await putJson<FormData, boolean>(url, data)
}

export const postImages = async (data: FormData) => {
  let url = `images`
  return await postJson<FormData, boolean>(url, data)
}

export const getInspecion = async (id?: string, date?: string) => {
  let url = `${defaultRoot}/anomalia?SiteId=${id}&Date=${date}`
  return await getJson<ApiAnomalies>(url)
}

export const getSimpleSite = async () => {
  let url = `${defaultRoot}/simple`
  return await getJson<Data>(url)
}

export const getSiteId = async (id?: string) => {
  let url = `${defaultRoot}/${id}`
  return await getJson<ISite>(url)
}

export const getPark = async (ArcGisName: string) => {
  let url = `${defaultRoot}/checkpark?ArcGisName=${ArcGisName}`
  return await getJson<boolean>(url)
}
