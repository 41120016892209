import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '~/components/Button'
import { Registration, RegistrationError } from './types'
import ToastNotification from '~/components/ToastNotification'
import {
  getCompanyAndBranch,
  getCompanySimple,
  postCompaniesAndBranches,
  putCompaniesAndBranches,
} from '~/services/CompaniesAndBranches'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ModalCustom from '~/components/ModalCustom'
import { cnpjMask } from '~/utils/Mask'
import { IBaseResponse } from '~/services/RequestService/types'
import ViewLoading from '~/components/ViewLoading'
import ViewError from '~/components/ViewError'
import IMAGE from '../../../assets/images/image.png'
import InfoIcon from '@mui/icons-material/Info';
import * as R from '../SiteCreateAndUpdate/styles'
import * as S from './styles'
import { ICompaniesSimple } from '../CompaniesAndBranches/types'
import handleResponse from '~/utils/responseEdit'
import CustomButton from '~/components/CustomButton'
import ErrorToast from '~/utils/toastErrorCatch'
import { useAuth } from '~/contexts/Auth'
import BaseLayout from '~/Layouts/BaseLayout'
import { postFile } from '~/services/File'

export default function RegisterAndEditUser() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useParams()
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [registration, setRegistration] = useState<Registration>(new Registration())
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError())
  const [loadingData, setLoadingData] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [companyList, setCompanyList] = useState<ICompaniesSimple[]>([])
  const [type, setType] = useState<any>("1")
  const [imageSet, setImageSet] = useState<File>()
  const [image, setImage] = useState<string | null>(IMAGE)
  const [displayedPhone, setDisplayedPhone] = useState(registration.phone);

  const location = useLocation()
  const { edit } = location.state || {}
  const { user } = useAuth()

  const [selectedCompany, setSelectedCompany] = useState<ICompaniesSimple | null>(null)

  useEffect(() => {
    if (token) {
      loadCompaniesAndBranches()
    }
  }, [])

  useEffect(() => {
    const handleOpen = async () => {
      try {
        const response = await getCompanySimple({
          ConsiderUserCompany: false,
        })
        setCompanyList(response.data)
      } catch (error) {
        return <ErrorToast message={t('Não foi possível buscar as empresas.')} />
      }
    }

    handleOpen()
  }, [])

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDisplayedPhone(value); 
    const numericValue = value.replace(/\D/g, ''); 
    setRegistration({ ...registration, phone: numericValue }); 
};

  const loadCompaniesAndBranches = async () => {
    setLoadingData(true)

    try {
      const response = await getCompanyAndBranch(token)
      if (response.success) {
        setRegistration({
          cnpj: cnpjMask(response.data.cnpj),
          corporateName: response.data.corporateName,
          fantasyName: response.data.fantasyName,
          status: response.data.status,
          state: response.data.state,
          city: response.data.city,
          neighborhood: response.data.neighborhood,
          publicPlace: response.data.street,
          number: response.data.number,
          complement: response.data.complement,
          headId: response.data.headId,
          email: response.data.email,
          phone: response.data.phone,
          image: response.data.logoUrl,
          isInspector: response.data.isInspector,
          isForeign: response.data.isForeign
        })
        setDisplayedPhone(response.data.phone)
        setRequestSuccess(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoadingData(false)
  }

  const RegisterAndEditCompaniesAndBranches = async () => {
    setLoading(true)

    const errors = { ...registrationError };
    let isValid = true;

    if (!registration.corporateName.trim()) {
      errors.corporateName = t('Razão Social é obrigatória.');
      isValid = false;
    } else if (registration.corporateName.trim().length < 5) {
      errors.corporateName = t('Razão Social deve ter mais de 5 caracteres.');
      isValid = false;
    } else if (registration.corporateName.trim().length > 200) {
      errors.corporateName = t('Razão Social deve ter menos de 200 caracteres.');
      isValid = false;
    } else {
      errors.corporateName = '';
    }

    if (!registration.isForeign) {
      if (!(registration.cnpj ?? '').trim()) {
        errors.cnpj = t('CNPJ é obrigatório.');
        isValid = false;
      } else {
        errors.cnpj = '';
      }
    } else {
      errors.cnpj = '';
    }

    if (!(registration.fantasyName ?? '').trim()) {
      errors.fantasyName = t('Nome Fantasia é obrigatório.');
      isValid = false;
    } else if ((registration.fantasyName ?? '').trim().length > 200) {
      errors.fantasyName = t('Nome Fantasia deve ter menos de 200 caracteres.');
      isValid = false;
    } else {
      errors.fantasyName = '';
    }


    if (!registration.state.trim()) {
      errors.state = t('Estado é obrigatório.');
      isValid = false;
    } else if (registration.state.trim().length > 100) {
      errors.state = t('Estado deve ter menos de 100 caracteres.');
      isValid = false;
    } else {
      errors.state = '';
    }

    if (!registration.city.trim()) {
      errors.city = t('Cidade é obrigatório.');
      isValid = false;
    } else if (registration.city.trim().length > 200) {
      errors.city = t('Cidade deve ter menos de 200 caracteres.');
      isValid = false;
    } else {
      errors.city = '';
    }

    if (!registration.neighborhood.trim()) {
      errors.neighborhood = t('Bairro é obrigatório.');
      isValid = false;
    } else if (registration.neighborhood.trim().length > 200) {
      errors.neighborhood = t('Bairro deve ter menos de 200 caracteres.');
      isValid = false;
    } else {
      errors.neighborhood = '';
    }

    if (!registration.publicPlace.trim()) {
      errors.publicPlace = t('Logradouro é obrigatório.');
      isValid = false;
    } else if (registration.publicPlace.trim().length > 200) {
      errors.publicPlace = t('Logradouro deve ter menos de 200 caracteres.');
      isValid = false;
    } else {
      errors.publicPlace = '';
    }

    if (!registration.number.trim()) {
      errors.number = t('Número é obrigatório.');
      isValid = false;
    } else if (registration.number.trim().length > 50) {
      errors.number = t('Número deve ter menos de 50 caracteres.');
      isValid = false;
    } else {
      errors.number = '';
    }

    if (type === '2' && !(registration.headId ?? '').trim()) {
      errors.headId = t('O campo Matriz é obrigatório para uma Filial.');
      isValid = false;
    } else {
      errors.headId = '';
    }

    if (registration.complement && registration.complement.trim().length > 500) {
      errors.complement = t('Complemento deve ter menos de 500 caracteres.');
      isValid = false;
    } else {
      errors.complement = '';
    }

    setRegistrationError(errors);

    if (!isValid) {
      setLoading(false);
      return;
    }

    try {
      const body = {
        id: token ?? '',
        cnpj: registration.cnpj ? onlyDigits(registration.cnpj) : null,
        corporateName: registration.corporateName,
        fantasyName: registration?.fantasyName ? registration?.fantasyName : null,
        status:
          registration.status === '1'
            ? 1
            : registration.status === '2'
              ? 2
              : Number(registration.status),
        state: registration.state,
        city: registration.city,
        neighborhood: registration.neighborhood,
        street: registration.publicPlace,
        number: registration.number,
        complement: registration?.complement ? registration?.complement : null,
        headId: registration.headId ? registration.headId : null,
        phone: registration.phone,
        email: registration.email,
        isInspector: registration.isInspector,
        isForeign: registration.isForeign ? registration.isForeign : false

      }
      let response: IBaseResponse<string>
      if (edit) response = await putCompaniesAndBranches(body)
      else response = await postCompaniesAndBranches(body)

      if (response.success) {
        if (imageSet) {
          const companyId = edit ? token : response.data
          if (companyId) {
            createImageLogo(imageSet, companyId)
          }
        }
        handleResponse(response, token, t, navigate)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message:
            response?.errors
              ? `${t(
                'Não foi possível realizar a edição, tente novamente mais tarde.',
              )} Detalhes do erro: ${response.errors[0].message}`
              : t('Não foi possível realizar o cadastro, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: token
          ? t('Não foi possível realizar a edição, tente novamente mais tarde.')
          : t('Não foi possível realizar o cadastro, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  function createImageLogo(logo: File, companyId: string) {
    const formData = new FormData();

    formData.append('Id', companyId);
    formData.append('Type', '2');
    formData.append('File', logo);

    postFile(formData);
  }

  useEffect(() => {
    const company = companyList.find((c) => c.id === registration.headId)
    setSelectedCompany(company ?? null)
    if (registration.headId) {
      setType('2')
    } else {
      setType('1')
    }
  }, [companyList, registration.headId])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedImage = e.target.files?.[0]
    if (selectedImage) {
      setImageSet(selectedImage)
      setImage(URL.createObjectURL(selectedImage))
    }
  }

  const getImageComponent = () => {
    if (token) {
      if (image !== IMAGE) {
        return image ? <S.Imagem src={image} width="250px" height="250px" /> : null
      } else {
        return <S.Imagem src={registration.image ?? IMAGE} width="250px" height="250px" />
      }
    } else {
      return image ? <S.Imagem src={image} width="250px" height="250px" /> : null
    }
  }

  function onlyDigits(value: string): string {
    return value.replace(/\D+/g, '')
  }

  return (
    <BaseLayout
      title={token ? t('Editar Empresa/Filial') : t('Cadastrar Empresa/Filial')}
      extraContent={
        token && (
          <>
            {user?.accessLevel === 1 && (
              <CustomButton
                onClick={() => navigate(`/cadastro-de-empresas-e-filiais`)}
                label={t('Cadastrar Filial')}
              />
            )}
            <CustomButton
              onClick={() => navigate(`/cadastro-de-usuarios`)}
              label={t('Cadastrar Usuário')}
            />
          </>
        )
      }
    >
      <S.FlexWrapper>
        {loadingData ? (
          <ViewLoading />
        ) : (token ? requestSuccess : true) ? (
          <S.ContainerForm>
            <S.TextForm>
              {t(
                "Para concluir o processo, precisamos que você informe corretamente os dados e depois clique em 'Salvar'. É importante revisar as informações antes de salvar para garantir que tudo esteja correto.",
              )}
            </S.TextForm>

            <S.ContainerInput>
              <Box display="flex" justifyContent="center" alignItems="flex-start" gap={2}>
                <Box>
                  <S.Label
                    htmlFor="input-file-imagem-company"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      <span style={{ display: 'flex', marginBottom: '4px', justifyContent: 'center' }}>{t("Logo da Empresa")}</span>
                      {getImageComponent()}
                      <S.Input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="input-file-imagem-company"
                        onChange={handleChange}
                      />
                      <R.EditIcon>
                        <EditIcon color="inherit" />
                      </R.EditIcon>
                    </Box>
                  </S.Label>
                </Box>

                <Box flexGrow={1}>
                  <S.ContainerDoubleInput>
                    <Box display="flex" alignItems="center">
                      <Tooltip title={t("Marque esta opção se a empresa for estrangeira.")}>
                        <InfoIcon fontSize="small" style={{ color: '#c4c4c4', marginRight: 8, cursor: "pointer" }} />
                      </Tooltip>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={registration.isForeign}
                            onChange={(e) => {
                              setRegistration({
                                ...registration,
                                isForeign: e.target.checked,
                                cnpj: e.target.checked ? '' : registration.cnpj
                              });
                            }}
                            color="primary"
                          />
                        }
                        label={<Typography variant="body2">{t("Estrangeira")}</Typography>}
                        labelPlacement="end"
                      />
                    </Box>

                    <TextField
                      label={t('CNPJ*')}
                      type="text"
                      inputProps={{ maxLength: 18 }}
                      value={registration.cnpj}
                      onChange={(e) => {
                        setRegistration({ ...registration, cnpj: cnpjMask(e.target.value) });
                        if (registrationError.cnpj) {
                          setRegistrationError({ ...registrationError, cnpj: '' });
                        }
                      }}
                      disabled={registration.isForeign}
                      size="small"
                      fullWidth
                      helperText={registrationError.cnpj ?? ''}
                      error={!!registrationError.cnpj}
                      onClick={() => setRegistrationError({ ...registrationError, cnpj: null })}
                      style={{ marginRight: '8px', width: '300px' }}
                    />
                    <TextField
                      label={t('Razão Social*')}
                      type="corporateName"
                      value={registration.corporateName}
                      onChange={(e) => {
                        setRegistration({ ...registration, corporateName: e.target.value });
                        if (registrationError.corporateName) {
                          setRegistrationError({ ...registrationError, corporateName: '' });
                        }
                      }}
                      size="small"
                      fullWidth
                      error={!!registrationError.corporateName}
                      helperText={registrationError.corporateName || ''}
                      onClick={() => setRegistrationError({ ...registrationError, corporateName: '' })}

                    />
                  </S.ContainerDoubleInput>

                  <TextField
                    label={t('Nome Fantasia*')}
                    type="fantasyName"
                    value={registration.fantasyName}
                    onChange={(e) => setRegistration({ ...registration, fantasyName: e.target.value })}
                    size="small"
                    fullWidth
                    error={!!registrationError.fantasyName}
                    helperText={registrationError.fantasyName}
                    onClick={() => setRegistrationError({ ...registrationError, fantasyName: '' })}
                    style={{ marginTop: '24px' }}
                  />

                  <TextField
                    label={t('E-mail')}
                    type="email"
                    value={registration.email}
                    onChange={(e) => setRegistration({ ...registration, email: e.target.value })}
                    size="small"
                    fullWidth
                    error={!!registrationError.email}
                    helperText={registrationError.email}
                    onClick={() => setRegistrationError({ ...registrationError, email: '' })}
                    style={{ marginTop: '24px' }}
                  />

                  <TextField
                    label={t('Telefone')}
                    type='text'
                    value={displayedPhone}
                    onChange={handlePhoneChange}
                    size="small"
                    fullWidth
                    error={!!registrationError.phone}
                    helperText={registrationError.phone}
                    onClick={() => setRegistrationError({ ...registrationError, phone: '' })}
                    style={{ marginTop: '24px' }}
                    inputProps={{
                      maxLength: 32
                    }}
                  />

                </Box>
              </Box>

              <S.ContainerDoubleInput style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <FormControl style={{ display: 'flex', width: '100%', marginRight: '24px' }}>
                  <InputLabel id="status-select">{t('Status')}</InputLabel>
                  <Select
                    size="small"
                    label={t('Status')}
                    labelId="status-select"
                    disabled={edit && user?.accessLevel != 1}
                    id="status-select"
                    value={registration.status}
                    onChange={(e) =>
                      setRegistration({ ...registration, status: String(e.target.value) })
                    }
                    onClick={() => setRegistrationError({ ...registrationError, status: '' })}
                    error={!!registrationError.status}
                  >
                    <MenuItem value={1}>{t('Ativo')}</MenuItem>
                    <MenuItem value={2}>{t('Inativo')}</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: '#d32f2f' }}>
                    {registrationError.status}
                  </FormHelperText>
                </FormControl>

                <FormControl style={{ display: 'flex', width: '100%', marginRight: '24px' }}>
                  <InputLabel id="tipo-label">{t('Tipo')}</InputLabel>
                  <Select
                    size="small"
                    disabled={edit && user?.accessLevel != 1}
                    labelId="tipo-label"
                    id="tipo-label"
                    value={type}
                    label={t("Tipo")}
                    onChange={(e) => {
                      setType(e.target.value)
                      if (e.target.value !== '2') {
                        setSelectedCompany(null)
                        setRegistration((prevRegistration) => ({ ...prevRegistration, headId: '' }))
                      }
                    }}
                  >
                    <MenuItem value={'1'}>{t('Matriz')}</MenuItem>
                    <MenuItem value={'2'}>{t('Filial')}</MenuItem>
                  </Select>
                </FormControl>

                <FormControl style={{ display: 'flex', width: '100%' }}>
                  <Autocomplete
                    size="small"
                    options={companyList}
                    getOptionLabel={(option) => option.corporateName || option.fantasyName || ''}
                    value={selectedCompany}
                    onChange={(_, newValue) => {
                      setSelectedCompany(newValue)
                      if (newValue) {
                        setRegistration((prevRegistration) => ({
                          ...prevRegistration,
                          headId: newValue.id,
                        }))
                      } else {
                        setRegistration((prevRegistration) => ({ ...prevRegistration, headId: '' }))
                      }
                    }}
                    disabled={type !== '2' || (edit && user?.accessLevel != 1)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Matriz*')}
                        variant="outlined"
                        error={!!registrationError.headId}
                        helperText={registrationError.headId || ''}
                        fullWidth
                        disabled={type !== '2' || (edit && user?.accessLevel != 1)}
                      />
                    )}
                  />
                </FormControl>
              </S.ContainerDoubleInput>

              <TextField
                label={t('Estado*')}
                type="state"
                value={registration.state}
                onChange={(e) => setRegistration({ ...registration, state: e.target.value })}
                size="small"
                fullWidth
                error={!!registrationError.state}
                helperText={registrationError.state}
                onClick={() => setRegistrationError({ ...registrationError, state: '' })}
                style={{ marginTop: '24px' }}
              />

              <TextField
                label={t('Cidade*')}
                type="city"
                value={registration.city}
                onChange={(e) => setRegistration({ ...registration, city: e.target.value })}
                size="small"
                fullWidth
                error={!!registrationError.city}
                helperText={registrationError.city}
                onClick={() => setRegistrationError({ ...registrationError, city: '' })}
                style={{ marginTop: '24px' }}
              />

              <TextField
                label={t('Bairro*')}
                type="neighborhood"
                value={registration.neighborhood}
                onChange={(e) => setRegistration({ ...registration, neighborhood: e.target.value })}
                size="small"
                fullWidth
                error={!!registrationError.neighborhood}
                helperText={registrationError.neighborhood}
                onClick={() => setRegistrationError({ ...registrationError, neighborhood: '' })}
                style={{ marginTop: '24px' }}
              />

              <S.ContainerDoubleInput>
                <TextField
                  label={t('Logradouro*')}
                  type="publicPlace"
                  value={registration.publicPlace}
                  onChange={(e) => setRegistration({ ...registration, publicPlace: e.target.value })}
                  size="small"
                  fullWidth
                  error={!!registrationError.publicPlace}
                  helperText={registrationError.publicPlace}
                  onClick={() => setRegistrationError({ ...registrationError, publicPlace: '' })}
                  style={{ marginRight: '8px' }}
                />
                <TextField
                  label={t('Número*')}
                  type="text"
                  value={registration.number}
                  onChange={(e) => setRegistration({ ...registration, number: e.target.value })}
                  size="small"
                  fullWidth
                  error={!!registrationError.number}
                  helperText={registrationError.number}
                  onClick={() => setRegistrationError({ ...registrationError, number: '' })}
                  style={{ width: '200px' }}
                />
              </S.ContainerDoubleInput>

              <TextField
                label={t('Complemento')}
                type="complement"
                value={registration.complement}
                onChange={(e) => setRegistration({ ...registration, complement: e.target.value })}
                size="small"
                fullWidth
                error={!!registrationError.complement}
                helperText={registrationError.complement}
                onClick={() => setRegistrationError({ ...registrationError, complement: '' })}
                style={{ marginTop: '24px' }}
              />

              <Box display="flex" alignItems="center">
                <Tooltip title={t("Marque esta opção se a empresa for inspetora.")}>
                  <InfoIcon fontSize="small" style={{ color: '#c4c4c4', marginRight: 8, cursor: "pointer" }} />
                </Tooltip>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={registration.isInspector}
                      onChange={(e) => setRegistration({ ...registration, isInspector: e.target.checked })}
                      color="primary"
                    />
                  }
                  label={<Typography variant="body2">{t("Empresa Inspetora")}</Typography>}
                  labelPlacement="end"
                />
              </Box>

            </S.ContainerInput>

            <S.ContainerButton>
              <Button
                type="button"
                onClick={() => setOpenConfirmationModal(true)}
                label={t('Cancelar')}
                style={{ marginTop: 0 }}
              />
              <Button
                type="button"
                onClick={RegisterAndEditCompaniesAndBranches}
                loading={loading}
                label={t('Salvar')}
                style={{ marginTop: 0, marginLeft: '40px' }}
              />
            </S.ContainerButton>
          </S.ContainerForm>
        ) : (
          <ViewError message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
        )}

        <ModalCustom
          open={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
          onClick={() => {
            setOpenConfirmationModal(false)
            navigate(-1)
          }}
          title={token ? t('Cancelar edição') : t('Cancelar cadastro')}
          description={
            token
              ? t(
                'Tem certeza de que deseja cancelar a edição? Após a confirmação, não há como voltar atrás.',
              )
              : t(
                'Tem certeza de que deseja cancelar o cadastro? Após a confirmação, não há como voltar atrás.',
              )
          }
          confirmationButtonText={t('Confirmar')}
          textDeclineButton={t('Cancelar')}
        />
      </S.FlexWrapper>
    </BaseLayout>
  )
}
