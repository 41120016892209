import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';
import { ImSpinner2 } from 'react-icons/im'

import * as S from './styles'

export interface IProps {
  message?: string;
  children?: ReactNode;
}

const ViewLoading: React.FC<IProps> = (props: IProps) => {
  const { message, children } = props;
  const { t } = useTranslation();
  return (
    <S.Container>
      <ImSpinner2 size={40} className="icon_loading" />
      <S.Message>
        {message ?? children ?? t('Carregando os dados')}
      </S.Message>
    </S.Container>
  )
}

export default ViewLoading;
