import { Box } from '@mui/material'
import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const Form = styled.form`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 20px;
`

export const InputWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;

  .MuiFormControl-root {
    background-color: ${theme.colors.white};
  }
`

export const SelectValue = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ValueAdornment = styled.span`
  margin-left: auto;
`
