import { Navigate, Route, Routes } from 'react-router-dom'

import { useAuth } from './contexts/Auth'
import CompaniesAndBranches from './pages/Dashboard/CompaniesAndBranches'
import Home from './pages/Dashboard/Home'
import RegisterAndEditCompaniesAndBranches from './pages/Dashboard/RegisterAndEditCompaniesAndBranches'
import RegisterAndEditUser from './pages/Dashboard/RegisterAndEditUser'
import Reports from './pages/Dashboard/Reports'
import SiteCreateAndUpdate from './pages/Dashboard/SiteCreateAndUpdate'
import Sites from './pages/Dashboard/Sites'
import UploadImages from './pages/Dashboard/Sites/EnviarImagens/index'
import SystemLogs from './pages/Dashboard/SystemLogs'
import Users from './pages/Dashboard/Users'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import RecoverPassword from './pages/RecoverPassword'

import Mapbox from './components/Map'
import InspectionSite from './pages/Dashboard/Home/components/SiteInfo'
import List from './pages/Dashboard/Sites/ListInspections'
import SettingsIndex from './pages/Settings'

const RoutesApp = () => {
  const { isAuthenticated, loading } = useAuth()

  const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    if (loading) return null
    return isAuthenticated ? children : <Navigate to="/login" replace />
  }

  const LoginRoute = () => {
    if (loading) return null
    return isAuthenticated ? <Navigate to="/" replace /> : <Login />
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginRoute />} />
      <Route path="/recuperarSenha/:token?" element={<RecoverPassword />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/filiais/:token"
        element={
          <ProtectedRoute>
            <CompaniesAndBranches />
          </ProtectedRoute>
        }
      />
      <Route
        path="/empresas-e-filiais"
        element={
          <ProtectedRoute>
            <CompaniesAndBranches />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cadastro-de-empresas-e-filiais"
        element={
          <ProtectedRoute>
            <RegisterAndEditCompaniesAndBranches />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edicao-de-empresas-e-filiais/:token"
        element={
          <ProtectedRoute>
            <RegisterAndEditCompaniesAndBranches />
          </ProtectedRoute>
        }
      />
      <Route
        path="/usuarios"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cadastro-de-usuarios"
        element={
          <ProtectedRoute>
            <RegisterAndEditUser />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edicao-de-usuarios/:token"
        element={
          <ProtectedRoute>
            <RegisterAndEditUser />
          </ProtectedRoute>
        }
      />
      <Route
        path="/logs-do-sistema"
        element={
          <ProtectedRoute>
            <SystemLogs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/upload-images/:siteId"
        element={
          <ProtectedRoute>
            <UploadImages />
          </ProtectedRoute>
        }
      />
      <Route
        path="/relatorios"
        element={
          <ProtectedRoute>
            <Reports />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites"
        element={
          <ProtectedRoute>
            <Sites />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/inspections"
        element={
          <ProtectedRoute>
            <List />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/inspections/:siteId"
        element={
          <ProtectedRoute>
            <List />
          </ProtectedRoute>
        }
      />

      <Route
        path="/sites/criar"
        element={
          <ProtectedRoute>
            <SiteCreateAndUpdate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sites/editar/:siteId"
        element={
          <ProtectedRoute>
            <SiteCreateAndUpdate />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<NotFound />} />

      <Route path="/inspection/:siteId" element={<InspectionSite />} />
      <Route
        path="/mapa/:siteId?"
        element={
          <ProtectedRoute>
            <Mapbox />
          </ProtectedRoute>
        }
      />

      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <SettingsIndex />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}

export default RoutesApp
