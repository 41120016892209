import { Box } from '@mui/material'
import styled, { css } from 'styled-components/macro'

const closeSidebar = css`
  animation: close 0.5s ease-out;
  right: -500px;
`
const openSidebar = css`
  animation: open 0.5s ease-out;
  right: 0;
`

export const ContainerSidebar = styled.div<{ isOpen?: boolean }>`
  font-family: Montserrat !important;
  position: absolute;
  z-index: 999;
  background-color: #f3f5fa;
  width: 35%;
  display: flex;
  flex-direction: column;

  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 8px;
    width: 4px;
    background: ${(props) => props.theme.colors.secondary};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 4px;
  }

  @keyframes close {
    from {
      right: 0px;
    }
    to {
      right: -500px;
    }
  }
  @keyframes open {
    to {
      right: 0px;
    }
    from {
      right: -500px;
    }
  }

  ${(props) => (!props.isOpen ? closeSidebar : openSidebar)};

  .anomalias__card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
    border-left: 4px solid gray; //
    font-size: 12px;
  }

  .anomalias__card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    .info__separador {
      color: #c7c7c7;
      font-size: 16px;
    }
  }

  .anomalias__info {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
  }

  .anomalias__card-status {
    align-self: flex-end;
  }

  .anomalias__card-tipoAnomalia {
    font-weight: 600;
  }

  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    font-family: Montserrat !important;
    font-size: 12px !important;
  }
`
export const ToggleSidebar = styled.span`
  background-color: #fff;
  position: absolute;
  left: -30px;
  width: 30px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  border-radius: 5px;
  border: 1px solid #f3f5fa;
`
export const SidebarHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  gap: 20px;
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;

  .site__title {
    font-size: 12px;
    font-weight: 600;
    align-self: flex-start;
  }

  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 4px 15px !important;
  }

  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    background-color: #f8f8f8;
    padding: 8px !important;
  }

  .container__inputs {
    width: 100%;
    gap: 10px;
  }

  .wrapper__input {
    position: relative;

    .icon__buscaid {
      position: absolute;
      top: 10px;
      left: 9px;
      fill: #cacaca;
    }

    .input__buscaid {
      padding: 10px 30px;
      background-color: #f8f8f8;
      border-radius: 5px;
      width: 200px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }

      &::placeholder {
        color: #cfcfcf;
      }
    }
  }
`

export const SidebarListAnomalias = styled.div`
  padding: 10px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c9c9c9;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #949494;
  }

  .MuiAutocomplete-clearIndicator {
    display: none;
  }
`
export const SidebarNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  border-bottom: 1px solid #dadada;

  .nav__item {
    width: 100%;
    text-align: center;
    padding: 15px;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 2px solid #fff;
  }

  & .active {
    border-color: #3fc0c2;
  }
`
export const BoxNav = styled.div`
  font-size: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    background-color: #ffffff;
  }
`

export const Label = styled.div``

export const TableOneAnomalia = styled.div`
  background-color: #fff;
  display: flex;
  border: 1px solid #dadada;
  border-radius: 10px;

  .table__indice {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    border-right: 1px solid #dadada;
  }
  .table__item {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .table__itens {
    padding: 10px;
    height: 45px;
    display: flex;
    align-items: center;

    &.right {
      justify-content: flex-end;
      padding-right: 20px;
    }
  }

  .table__itens + .table__itens {
    border-top: 1px solid #dadada;
  }
`
export const Button = styled.button`
  background-color: #3fc0c2;
  border: none;
  border-radius: 7px;
  padding: 10px 20px;
  width: fit-content;
  color: #fff;
`

export const Flex = styled.div`
  display: flex;
`

export const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`

export const ButtonsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 6px;
`

export const Text = styled.p`
  display: flex;
`

export const BoxMenuItem = styled.div`
  display: flex;
  flex-direction: row;

  .icon {
    margin-right: 8px;
    color: red;
  }
`


