import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillInfoCircle } from 'react-icons/ai'

import * as S from './styles'

export interface IViewErrorProps {
  message?: string | null
  children?: ReactNode
}

const ViewError: React.FC<IViewErrorProps> = (props: IViewErrorProps) => {
  const { message, children } = props
  const { t } = useTranslation();
  return (
    <S.Container>
      <AiFillInfoCircle size={40} />
      <S.Message>
        {message ?? children ?? t('Não foi possível carregar os dados, tente novamente mais tarde.')}
      </S.Message>
    </S.Container>
  )
}

export default ViewError
