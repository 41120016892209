import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: var(--bg-login);

  display: grid;
  grid-template-columns: 1.4fr 0.6fr;

  /* @media (max-width: 1080px) { */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  /* } */

  > img:first-child {
      width: 100%;
      object-fit: cover;
      height: 100vh;

      @media (max-width: 1080px) {
        display: none;
      }
    }
  }
`

export const ContentRecoverPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20% 0;

  padding: 0 32px;

  > img {
    width: 70%;
    object-fit: cover;
  }

  h1 {
    color: #21282d;
    margin-top: 48px;
    font-size: 16px;
  }

  h2 {
    color: ${(props) => props.theme.colors.black};
    margin-top: 12px;
    margin-bottom: 16%;
    font-size: 12px;
  }

  span {
    color: ${(props) => props.theme.colors.black};
    margin: 0 auto;
    margin-top: 12px;
    margin-bottom: 5%;
    width: 90%;
    font-size: 12px;
    text-align: center;
  }

  button {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
    font-size: 12px;
    border: none;

    &:last-child {
      padding: 0;
      width: 100%;
      height: 40px;
      text-decoration: none;
      background: ${(props) => props.theme.colors.primary};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.colors.white};
      border-radius: 5px;
      font-size: 14px;
      margin-top: 5%;

      @media (max-width: 380px) {
        width: 100%;
        padding: 0 4%;
      }
    }
  }

  .link {
    text-align: right;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: ${(props) => props.theme.colors.black};
  }
`
export const Divlink = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`