import { Box } from '@mui/material'
import styled from 'styled-components/macro'

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 10%;

  @media (max-width: 1100px) {
    width: calc(100% - 50px);
    margin: 0 0 0 auto;
  }
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px 0;
`

export const ContainerNav = styled.div`
  width: min-content;
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const UserContainer = styled(Box)`
  margin-left: 20px;
  width: min-content;
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const UserInfo = styled(Box)`
  width: min-content;
  white-space: nowrap;

  p {
    font-size: 12px;
  }
`

export const HeaderExtraContent = styled.div`
  width: 100%;
  display: flex;
`

export const GridData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

export const ContentBoxDown = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 15px;
  padding: 0 20px;
`

export const ContainerReactFlagsSelect = styled.div`
  display: flex;
  align-items: center;

  .flag-selected {
    padding-bottom: 0px;
  }
`

export const ContainerUserInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
