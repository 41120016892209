import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material'
import { EtapaProps, ICompanyRemoved } from '../types'
import { ICompaniesSimple } from '~/pages/Dashboard/CompaniesAndBranches/types';
import { validateEtapa1Data } from '../validations';
import { useNavigate } from 'react-router-dom';

const Etapa1View: React.FC<EtapaProps & {
  etapa1Data: {
    selectedDate: string,
    companyName: string,
    companycontact: string,
    termografista: string,
    auxiliar: string,
    uas: string,
    payload: string,

  },
  companies: ICompaniesSimple[]
  empresaInexistente?: ICompanyRemoved
  selectCompany: string | null
  onFieldChange: (fieldName: string, value: string) => void
  setSelectedCompanyId: (id: string | null) => void
}> = ({ handleBack, handleNext, etapa1Data, onFieldChange, companies, selectCompany, setSelectedCompanyId, empresaInexistente }) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleValidationAndNext = async () => {
    const result = await validateEtapa1Data(etapa1Data, t);
    if (result === true) {
      handleNext();
    } else {
      setErrors(result);
    }
  };

  const initialCompanyValue = empresaInexistente ? {
    id: empresaInexistente.companyId ?? '',
    corporateName: empresaInexistente.companyName ?? '',
  } : null;

  const selectedCompany = companies.find(c => c.id === selectCompany) || initialCompanyValue;
  const navigate = useNavigate()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'selectedDate') {
      if (value) {
        const date = new Date(value);
        if (!isNaN(date.getTime())) {
          const isoDate = date.toISOString();
          onFieldChange(name, isoDate);
        } else {
          onFieldChange(name, '');
        }
      } else {
        onFieldChange(name, '');
      }
    } else {
      onFieldChange(name, value);
    }
  };

  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        margin: '24px 10%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '16px',
        border: '1px solid #C4C4C4'
      }}
    >
      <Stack direction="column" gap={2} sx={{ width: '100%' }}>
        <Typography variant="h6" fontWeight="bold">
          {t("Data da Inspeção")}
        </Typography>

        <TextField
          type="date"
          name="selectedDate"
          value={etapa1Data.selectedDate ? new Date(etapa1Data.selectedDate).toISOString().substring(0, 10) : ''}
          onChange={handleInputChange}
          label={t('Data')}
          variant="outlined"
          size="small"
          style={{ width: '300px' }}
          id="input-date"
          required
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors['selectedDate'])}
          helperText={errors['selectedDate'] || ''}
        />
      </Stack>
      <Stack direction="column" gap={2} sx={{ width: '100%' }}>
        <Typography variant="h6" fontWeight="700">
          {t("Empresa")}
        </Typography>
        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <Autocomplete
            value={selectedCompany}
            onChange={(event, newValue) => {
              setSelectedCompanyId(newValue ? newValue.id : null);
              onFieldChange('companyName', newValue ? newValue.corporateName : '');
            }}
            options={companies}
            getOptionLabel={(option) => option.corporateName}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Empresa Inspetora')}
                variant="outlined"
                size="small"
                required
                error={Boolean(errors['companyName'])}
                helperText={errors['companyName'] || ''}
              />
            )}
            clearOnEscape={false}
            clearIcon={null}
            sx={{ width: '50%' }}
          />

          <TextField
            type="text"
            name="companycontact"
            value={etapa1Data.companycontact}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '50%' }}
            id="input-contato"
            label={t('Contato')}
            error={Boolean(errors['companycontact'])}
            helperText={errors['companycontact'] || ''}
          />
        </Stack>
      </Stack>
      <Stack direction="column" gap={2}>
        <Typography variant="h6" fontWeight="700">
          {t("Inspetores")}
        </Typography>

        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <TextField
            type="text"
            value={etapa1Data.termografista ?? ''}
            variant="outlined"
            name="termografista"
            onChange={handleInputChange}
            size="small"
            sx={{ width: '50%' }}
            id="input-termografista"
            label={t('Termografista')}
            error={Boolean(errors['termografista'])}
            helperText={errors['termografista'] || ''}
          />

          <TextField
            type="text"
            name="auxiliar"
            value={etapa1Data.auxiliar}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '50%' }}
            id="input-auxiliar"
            label={t('Auxiliar')}
            error={Boolean(errors['auxiliar'])}
            helperText={errors['auxiliar'] || ''}
          />
        </Stack>
      </Stack>
      <Stack direction="column" gap={2}>
        <Typography variant="h6" fontWeight="700">
          {t("Equipamentos")}
        </Typography>

        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <TextField
            type="text"
            value={etapa1Data.uas ?? ''}
            variant="outlined"
            name="uas"
            onChange={handleInputChange}
            size="small"
            sx={{ width: '50%' }}
            id="input-uas"
            label={t('UAS')}
            error={Boolean(errors['uas'])}
            helperText={errors['uas'] || ''}
          />

          <TextField
            type="text"
            name="payload"
            value={etapa1Data.payload}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '50%' }}
            id="input-payload"
            label={t('Payload')}
            error={Boolean(errors['payload'])}
            helperText={errors['payload'] || ''}
          />
        </Stack>
      </Stack>

      <Stack
        width={'100%'}
        gap={2}
        py={2}
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          {t('Voltar')}
        </Button>

        <Button variant="contained" onClick={handleValidationAndNext} sx={{
          backgroundColor: '#0C4A7D',
          color: 'white',
          '&:hover': {
            backgroundColor: '#084a5d'
          }
        }}>
          {t('Avançar')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default Etapa1View
