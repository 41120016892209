import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import { IRequestActivity, IResponseActivity } from './types'

const defaultRoot = 'activity'

export const getActivity = async () => {
  const url = `${defaultRoot}`
  return await getJson<IResponseActivity[]>(url)
}

export const postActivity = async (data: IRequestActivity) => {
  let url = `${defaultRoot}`
  return await postJson<IRequestActivity, IResponseActivity>(url, data)
}

export const patchActivity = async (data: IRequestActivity) => {
  let url = `${defaultRoot}`
  return await putJson<IRequestActivity, IResponseActivity>(url, data)
}

export const deleteActivity = async (data: IRequestActivity) => {
  let url = `${defaultRoot}`

  url += `?id=${data.id}`

  return await deleteJson<IRequestActivity, IResponseActivity>(url, data)
}
