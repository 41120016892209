import { useState } from 'react'
import * as S from './styles'
import { Autocomplete, Button, TextField, Typography } from '@mui/material'
import { IAnomaliePageInfoProps } from '~/services/Anomaly/types'
import { ISelectOptionsProps } from '~/components/Map/types'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../Modal/index'

type ComponentProps = {
  anomalyPageInfo: IAnomaliePageInfoProps | null
  removeAnomalies(anomalies: number[]): void
}

const RemoveAnomalies = ({ anomalyPageInfo, removeAnomalies }: ComponentProps) => {
  const { t } = useTranslation()

  const [anomaliaValues, setAnomaliaValues] = useState<ISelectOptionsProps[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedAnomalyIds, setSelectedAnomalyIds] = useState<ISelectOptionsProps[]>([])

  const handleOpenModal = () => {
    setSelectedAnomalyIds(anomaliaValues)
    setOpenModal(true)
  }

  const handleRemoveAnomalies = () => {
    if (anomaliaValues.length > 0) {
      handleOpenModal()
    }
  }

  const handleConfirmRemoveAnomaly = () => {
    removeAnomalies(selectedAnomalyIds.map((anomaly) => anomaly.value))
    setOpenModal(false)
    setSelectedAnomalyIds([])
  }

  const getSelectOptions = (
    value: IAnomaliePageInfoProps,
    property: 'anomalyTypes' | 'anomalyStatuses' | 'anomalySeverities',
  ): ISelectOptionsProps[] => {
    return value[property].map((anomaly) => ({
      label: t(`${anomaly.name}`),
      value: anomaly.id,
    }))
  }

  return (
    <S.RemoveAnomalyWrapper>
      <Typography variant="h6" fontWeight="bold">
        {t('Remover anomalias por tipo')}
      </Typography>
      <S.InputWrapper>
        <Autocomplete
          size="small"
          multiple
          value={anomaliaValues}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          id="remover anomalias"
          options={anomalyPageInfo ? getSelectOptions(anomalyPageInfo, 'anomalyTypes') : []}
          onChange={(_, newValue) => setAnomaliaValues(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <div>
          <Button color="error" variant="contained" onClick={handleRemoveAnomalies}>
            {t('Remover Anomalias')}
          </Button>
        </div>
      </S.InputWrapper>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={t('Remover Anomalias')}
        confirmButtonText={t('Remover') ?? ''}
        cancelButtonText={t('Cancelar') ?? ''}
        cancelButtonAction={() => setOpenModal(false)}
        confirmButtonAction={handleConfirmRemoveAnomaly}
      >
        {t('Você tem certeza que deseja excluir as anomalias selecionadas?')}
        <strong>
          {selectedAnomalyIds.map((anomaly) => ` #${anomaly.value} ${anomaly.label}`).join(', ')}
        </strong>
      </Modal>
    </S.RemoveAnomalyWrapper>
  )
}

export default RemoveAnomalies
