/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { STR } from '~/assets/Strings'
import { USER_GET } from '~/config/constants'
import RuntimeEnvironment from '~/config/RuntimeEnvironment'
import { IUser } from '~/contexts/Auth/types'
import { cleanLocalStorage, getLocalStorage } from '~/utils/StorageLocal'
import { IBaseResponse, IMethod } from './types'

const apiUrl = RuntimeEnvironment.ServiceURL
const headers = new Headers()

function appendHeader(prop: string, value: string): void {
  if (headers.has(prop)) {
    headers.set(prop, value)
  } else {
    headers.append(prop, value)
  }
}

function removeHeader(key: string) {
  if (headers.has(key)) {
    headers.delete(key)
  }
}

async function sendRequestResponse<TResponse>(result: Response) {
  try {
    const contentType = result.headers.get('Content-Type') ?? ''

    if (contentType.includes('application/octet-stream') || contentType.includes('text/csv')) {
      const blob = await result.blob()
      const filename = result.headers.get('Content-Disposition') ?? 'downloaded_file'

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
      window.URL.revokeObjectURL(url)

      const data: any = {
        requestId: result.headers.get('x-requestid'),
        data: null,
        success: result.ok,
        message: 'Downloaded Successfully',
        errorDetails: null,
        errors: [],
      }

      return data
    }

    const data: IBaseResponse<TResponse> = await result.json()
    data.success = result.ok
    return data
  } catch (err) {
    throw mountError(STR.REQUEST_EXCEPTION.APP_JSON_PROCESS_ERROR)
  }
}

export async function sendRequest<TResponse, TRequest = unknown>(
  url: string,
  method: IMethod,
  body?: TRequest,
  appendHeaderName?: string,
  appendHeaderValue?: string,
): Promise<IBaseResponse<TResponse>> {
  try {
    let payload: any
    const user = getLocalStorage<IUser>(USER_GET)

    if (user?.accessToken) appendHeader('Authorization', `Bearer ${user.accessToken}`)

    if (method !== 'GET') {
      if (body instanceof FormData) {
        appendHeader('Content-Type', 'multipart/form-data')
        removeHeader('Content-Type')
        payload = body
      } else {
        if (appendHeaderName) appendHeader(appendHeaderName, appendHeaderValue ?? '')
        appendHeader('Content-Type', 'application/json-patch+json')
        payload = JSON.stringify(body)
      }
    }

    const requestInit: RequestInit = {
      method,
      headers,
      cache: 'no-cache',
      body: payload,
      mode: 'cors',
    }

    const result = await fetch(`${apiUrl}/${url}`, requestInit)

    verifyStatus(result)
    if (result.status === 401 && user) {
      cleanLocalStorage()
      window.location.href = '/login'
    }

    return sendRequestResponse(result)
  } catch (err) {
    console.error('ex', err)
    throw mountError(STR.REQUEST_EXCEPTION.NETWORK_ERROR)
  }
}

const verifyStatus = (result: Response) => {
  if (result.status === 500) {
    mountError(STR.REQUEST_EXCEPTION.API_INTERNAL_ERROR)
  }

  if (result.status === 404) {
    mountError(STR.REQUEST_EXCEPTION.NOT_FOUND_ERROR)
  }
}

const mountError = (message: string): IBaseResponse<undefined> => {
  return {
    data: undefined,
    message: message,
    errorDetails: message,
    errors: [],
    success: false,
  }
}

export async function getJson<TResponse>(url: string) {
  return await sendRequest<TResponse>(url, 'GET')
}

export async function postJson<TRequest, TResponse>(url: string, body: TRequest) {
  return await sendRequest<TResponse, TRequest>(url, 'POST', body)
}

export async function putJson<TRequest, TResponse>(url: string, body: TRequest) {
  return await sendRequest<TResponse, TRequest>(url, 'PATCH', body)
}

export async function deleteJson<TRequest, TResponse>(url: string, body?: TRequest) {
  return await sendRequest<TResponse, TRequest>(url, 'DELETE', body)
}
