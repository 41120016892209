import { TFunction } from 'i18next';
import * as yup from 'yup';
import { IEtapaOne, IEtapaTwo } from './types';

export const etapa1ValidationSchema = (t: TFunction) => yup.object({
  selectedDate: yup.date()
    .nullable()
    .transform((value, originalValue) => originalValue === '' ? null : value)
    .required(t('A data é obrigatória.') ?? 'A data é obrigatória.'),
  companyName: yup.string()
    .required(t('Empresa inspetora é obrigatório.') ?? 'Empresa inspetora é obrigatório.'),
  companycontact: yup.string()
    .max(100, t('Contato não pode ter mais de 100 caracteres.') ?? 'Contato não pode ter mais de 100 caracteres.'),
  termografista: yup.string()
    .max(100, t('Termografista não pode ter mais de 100 caracteres.') ?? 'Termografista não pode ter mais de 100 caracteres.'),
  auxiliar: yup.string()
    .max(200, t('Auxiliar não pode ter mais de 200 caracteres.') ?? 'Auxiliar não pode ter mais de 200 caracteres.'),
  uas: yup.string()
    .max(100, t('UAS não pode ter mais de 100 caracteres.') ?? 'UAS não pode ter mais de 100 caracteres.'),
  payload: yup.string()
    .max(100, t('Payload não pode ter mais de 100 caracteres.') ?? 'Payload não pode ter mais de 100 caracteres.'),
});

export const validateEtapa1Data = async (data: IEtapaOne, t: TFunction) => {
  try {
    await etapa1ValidationSchema(t).validate(data, { abortEarly: false });
    return true;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const errors = {};
      err.inner.forEach((error) => {
        if (error.path && !errors[error.path]) {
          errors[error.path] = error.message;
        }
      });
      return errors;
    }
    throw err;
  }
};


export const etapa2ValidationSchema = (t: TFunction) => yup.object({
  humidadeMedia: yup.string()
    .max(50, t('Umidade Média não pode ter mais de 50 caracteres.') ?? 'Umidade Média não pode ter mais de 50 caracteres.'),
  temperaturaAmbienteMedia: yup.string()
    .max(50, t('Temperatura ambiente média não pode ter mais de 50 caracteres.') ?? 'Temperatura ambiente média não pode ter mais de 50 caracteres.'),
  temperaturaMediaModulos: yup.string()
    .max(50, t('Temperatura média dos módulos não pode ter mais de 50 caracteres.') ?? 'Temperatura média dos módulos não pode ter mais de 50 caracteres.'),
  velocidadeMediaVento: yup.string()
    .max(50, t('Velocidade média do vento não pode ter mais de 50 caracteres.') ?? 'Velocidade média do vento não pode ter mais de 50 caracteres.'),
  coberturaDasNuvens: yup.string()
    .max(50, t('Cobertura das nuvens não pode ter mais de 50 caracteres.') ?? 'Cobertura das nuvens não pode ter mais de 50 caracteres.'),
  nTotalModulos: yup.string()
    .required(t('Número total de módulos é obrigatório.') ?? 'Número total de módulos é obrigatório.')
    .matches(/^\d+$/, t('Número total de módulos deve ser um número positivo.') ?? 'Número total de módulos deve ser um número positivo.'),
  totalMdwcInspecionado: yup.string()
    .required(t('Total MWDC a ser inspecionado é obrigatório.') ?? 'Total MWDC a ser inspecionado é obrigatório.')
    .matches(/^\d+(\.\d+)?$/
    , t('Total MWDC a ser inspecionado deve ser um número positivo.') ?? 'Total MWDC a ser inspecionado deve ser um número positivo.'),
  irradianciaMedia: yup.string()
    .max(50, t('Irradiância média não pode ter mais de 50 caracteres.') ?? 'Irradiância média não pode ter mais de 50 caracteres.'),
  temperaturaRefletida: yup.string()
    .max(50, t('Temperatura refletida não pode ter mais de 50 caracteres.') ?? 'Temperatura refletida não pode ter mais de 50 caracteres.'),
  emissividade: yup.string()
    .max(50, t('Emissividade não pode ter mais de 50 caracteres.') ?? 'Emissividade não pode ter mais de 50 caracteres.'),
  informacoesAdicionais: yup.string()
    .max(400, t('Informações adicionais não pode ter mais de 400 caracteres.') ?? 'Informações adicionais não pode ter mais de 400 caracteres.'),
  recomendacoes: yup.string()
    .max(1000, t('Recomendações não pode ter mais de 1000 caracteres.') ?? 'Recomendações não pode ter mais de 1000 caracteres.'),



});


export const validateEtapa2Data = async (data: IEtapaTwo, t: TFunction) => {
  try {
    await etapa2ValidationSchema(t).validate(data, { abortEarly: false });
    return true;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const errors = {};
      err.inner.forEach((error) => {
        if (error.path && !errors[error.path]) {
          errors[error.path] = error.message;
        }
      });
      return errors;
    }
    throw err;
  }
};
