import styled from 'styled-components'
import { Popup } from 'react-map-gl'
import { theme } from '~/styles/theme'
import { Box, FormControl as MUIFormControl } from '@mui/material'

export const Sidebar = styled.div`
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 24;
  left: 24;
  margin: 12px;
  border-radius: 8px;
`

export const FlexWrapper = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
`

export const FormWrapper = styled(Box)`
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 0 20px;
`

export const FormControl = styled(MUIFormControl)`
  width: 100%;
  display: flex;
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`

export const Container = styled.div`
  margin: 0 10%;
`

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const PopupContent = styled(Popup)`
  padding: 0 !important;
  border-radius: 8px;
  background: #fff;

  img {
    min-width: 180px;
    width: 100%;
    height: 50%;
    border-radius: 8px 8px 0 0;
  }

  height: 280px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const InfoContainer = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  padding: 8px;
  font-weight: bold;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  font-size: 0.8rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  span {
    padding: 2px;
  }
`

export const AnomalyStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 8px;
  border: 3px solid #e1f5ff;
  background: #e1f5ff3d;
  width: 100px;
  padding: 2px;
  margin-top: 8px;
`

export const StatusBar = styled.div`
  height: 100%;
  border-radius: 8px;
  width: 5px;
`

export const ButtonPosition = styled.button`
  position: absolute;
  bottom: 40px;
  left: 170px;
  width: 300px;
  height: 70px;
  border-radius: 8px;
  border: none;

  h1 {
    font-size: 14px;
    align-items: center;
    color: ${theme.colors.black};
  }

  h5 {
    margin-top: 8px;
    font-size: 10px;
    align-items: center;
    color: ${theme.colors.black};
  }
`

export const ButtonDiagram = styled.button`
  position: absolute;
  bottom: 40px;
  left: 495px;
  width: 300px;
  height: 70px;
  border-radius: 8px;
  border: none;

  h1 {
    font-size: 14px;
    align-items: center;
    color: ${theme.colors.black};
  }

  h5 {
    margin-top: 8px;
    font-size: 10px;
    align-items: center;
    color: ${theme.colors.black};
  }
`

export const ButtonLayer = styled.button`
  position: absolute;
  bottom: 40px;
  left: 80px;
  width: 70px;
  height: 70px;
  border-radius: 8px;
  border: none;

  h1 {
    font-size: 12px;
    align-items: center;
    color: ${theme.colors.black};
  }
`

export const Imagem = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;

  // ter o controle da opacidade
`

export const TextStringId = styled.h5`
  font-weight: 500;
  font-size: 32px;
  color: ${(props) => props.theme.colors.blue};
  font-align: center;
`

export const TextModuleId = styled.h5`
  font-weight: 500;
  font-size: 32px;
  color: ${(props) => props.theme.colors.orange};
  font-align: center;
`
