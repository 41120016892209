import { Box } from '@mui/material'
import styled from 'styled-components/macro'

export const ContainerFilter = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;

  margin-top: 40px;
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    white-space: nowrap;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.primary};
  }
`
export const ContainerSearch = styled.div`
  display: flex;

  padding: 0 24px;
`

export const SearchBtn = styled.div`
  width: 180px;

  .btn_icon {
    color: white;
    font-size: 20px;
  }

  @media (max-width: 1024px) {
    width: 190px;
  }
`

export const Main = styled.div`
  width: 100%;
  height: 61%;
  padding-bottom: 20px;
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`

export const Message = styled.p`
  font-size: 18px;
  padding: 12px 24px;
`

export const ContainerButton = styled.div`
  justify-content: center;
  align-items: center;
  width: 300px;
`

export const TextBranch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;

  label {
    font-size: 22px;
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.colors.black};
  }
`
