import { IAnomalySeverityProps, ISeverityGraphProps } from '~/services/Dashboard/types'
import * as S from '../../styles'
import { useMemo } from 'react'
import { Box } from '@mui/material'
import { getColor } from '../../utils'
import { useTranslation } from 'react-i18next'

type AnomalieSeverityComponentProps = {
  severityGraph: ISeverityGraphProps
}

const AnomalieSeverityComponent = ({ severityGraph }: AnomalieSeverityComponentProps) => {
  const { t } = useTranslation()

  const handleFilterSeverity = (data?: any) => {
    let safePercentage = data.percentage ? parseFloat(data.percentage.toFixed(2)) : 0;

    switch (data.name) {
      case 'Baixa':
        return {
          ...data,
          key: 3,
          percentage: safePercentage,
        }
      case 'Média':
        return {
          ...data,
          key: 2,
          percentage: safePercentage,
        }
      case 'Alta':
        return {
          ...data,
          key: 1,
          percentage: safePercentage,
        }
      default:
        return {
          ...data,
          percentage: safePercentage,
        }
    }
  }

  const normalizeString = (str: string): string => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
  }

  const severityData = useMemo(() => {
    if (severityGraph)
      return {
        totalAnomalies: severityGraph.totalAnomalies,
        anomalySeverities: severityGraph.anomalySeverities
          .map((anomaly) => handleFilterSeverity(anomaly))
          .sort((a, b) => {
            return a.key - b.key
          }),
      }
  }, [severityGraph])

  return (
    <S.AnomalieSeverityWrapper>
      <S.AnomalieTitle>{t('Anomalias - Severidade')}</S.AnomalieTitle>
      <S.HorizontalBar>
        {severityData?.anomalySeverities.map((severity: IAnomalySeverityProps) => {
          return (
            <Box
              key={severity.value}
              className="bar"
              sx={{
                width: `${Number(severity.percentage ?? 0)}%`,
                backgroundColor: getColor(severity.name),
              }}
            />
          )
        })}
      </S.HorizontalBar>
      <S.DataContent>
        <S.TotalAnomalies>
          <p>{t('Total')}</p>
          <h2>{severityData?.totalAnomalies}</h2>
        </S.TotalAnomalies>
        <S.ValuesContent>
          {severityData?.anomalySeverities.map((sevetiry) => (
            <Box key={sevetiry.value}>
              <S.AlignLine>
                <Box className={`circle ${normalizeString(sevetiry.name)} `} />
                <Box>{t(`Severidade_.${sevetiry.name}`)}</Box>
              </S.AlignLine>
              <S.SeverityValues>
                <h4>{sevetiry.percentage}%</h4>
                <>({sevetiry.value})</>
              </S.SeverityValues>
            </Box>
          ))}
        </S.ValuesContent>
      </S.DataContent>
    </S.AnomalieSeverityWrapper>
  )
}

export default AnomalieSeverityComponent
