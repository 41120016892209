import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseLayout from '~/Layouts/BaseLayout'
import ViewLoading from '~/components/ViewLoading'

import { useAuth } from '~/contexts/Auth'
import { AccessLevel } from '~/utils/General'
import Anomalias from './Abas/Anomalias'
import Atividades from './Abas/Atividades'
import Geral from './Abas/Geral'
import Outros from './Abas/Outros'

const SettingsIndex = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()

  const tabComponents = [
    { id: 'geral', label: t('Geral'), component: <Geral /> },
    {
      id: 'anomalias',
      label: t('Anomalias'),
      component: <Anomalias />,
    },
    {
      id: 'atividades',
      label: t('Atividades'),
      component: <Atividades />,
    },
    { id: 'outros', label: t('Outros'), component: <Outros />, requiredAccess: AccessLevel.Master },
  ]

  const firstAccessibleTab =
    tabComponents.find((tab) => !tab.requiredAccess || user?.accessLevel === tab.requiredAccess)?.id ??
    'anomalias'

  const [activeTab, setActiveTab] = useState(firstAccessibleTab)

  useEffect(() => {
    setActiveTab(firstAccessibleTab)
  }, [user, firstAccessibleTab])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }

  const renderTabContent = () => {
    const activeTabComponent = tabComponents.find((tab) => tab.id === activeTab)
    if (
      activeTabComponent &&
      (!activeTabComponent.requiredAccess || user?.accessLevel === activeTabComponent.requiredAccess)
    ) {
      return activeTabComponent.component
    }
    return null
  }

  return (
    <BaseLayout title={t('Configurações')}>
      {loading ? (
        <ViewLoading />
      ) : (
        <>
          <Box
            sx={{
              padding: '0 calc(10% + 20px)',
              borderBottom: '1px solid #e0e0e0',
              boxShadow: 'none',
              bgcolor: '#f5f5f5',
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleChangeTab}
              aria-label="configurações de abas"
              variant="standard"
              indicatorColor="primary"
              textColor="primary"
              sx={{
                '.MuiTab-root': {
                  fontWeight: 'bold',
                  color: 'black',
                  '&.Mui-selected': {
                    color: '#1976d2',
                    backgroundColor: '#e3f2fd',
                  },
                },
              }}
            >
              {tabComponents.map(
                (tab) =>
                  (!tab.requiredAccess || user?.accessLevel === tab.requiredAccess) && (
                    <Tab key={tab.id} label={tab.label} value={tab.id} />
                  ),
              )}
            </Tabs>
          </Box>
          <Box sx={{ padding: '20px', border: '1px solid #e0e0e0', borderTop: 'none' }}>
            {renderTabContent()}
          </Box>
        </>
      )}
    </BaseLayout>
  )
}

export default SettingsIndex
