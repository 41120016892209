import { IUsersList } from './types'

export const emptyItem: IUsersList = {
  id: '',
  name: '',
  status: 0,
  accessLevel: 0,
  branch: '',
  company: '',
  email: '',
}
