import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Box,
  Typography,
  Button,
} from '@mui/material'

import { LogDto, getLogTypeString } from '~/services/Logs/types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { TableCell } from '~/components/Material/Table/styles'

interface LogTableProps {
  data: LogDto[]
  loadMoreLogs: () => void
  hasNextLogs: boolean
  lastLogRef: React.RefObject<HTMLTableRowElement>
  prevLogsCount: number 
}

const LogTable: React.FC<LogTableProps> = ({
  data,
  loadMoreLogs,
  hasNextLogs,
  lastLogRef,
  prevLogsCount,
}) => {
  const [open, setOpen] = useState<Record<string, boolean>>({})
  const { t } = useTranslation()

  const handleToggle = (id: string, hasChanges: boolean) => {
    if (hasChanges) {
      setOpen((prevOpen) => ({
        ...prevOpen,
        [id]: !prevOpen[id],
      }))
    }
  }

  const rowColors = {
    even: '#EFF3F9',
    odd: '#FFFFFF',
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ width: '80%', margin: '0 auto', marginBottom: 10, padding: '20px' }}
    >
      <Table sx={{ minWidth: 650, margin: 0 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: 'bold',
                backgroundColor: '#dbe1ee',
                borderStartStartRadius: '4px',
              }}
            >
              {t('Tipo')}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                backgroundColor: '#dbe1ee',
              }}
              align="center"
            >
              {t('Usuário')}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                backgroundColor: '#dbe1ee',
              }}
              align="center"
            >
              {t('Componente')}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                backgroundColor: '#dbe1ee',
              }}
              align="center"
            >
              {t('Ação')}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                backgroundColor: '#dbe1ee',
              }}
              align="center"
            >
              {t('Data')}
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                backgroundColor: '#dbe1ee',
                borderStartEndRadius: '4px',
              }}
              align="center"
            >
              {t('Alteração')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Typography variant="body2" color="textSecondary" align="center">
                  {t('Nenhum log encontrado')}
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            data.map((log, index) => (
              <React.Fragment key={`${log.dateTime}-${log.userName}-${log.logType}-${log.action}`}>
                <TableRow
                  onClick={() => handleToggle(log.dateTime, log.changes.length > 0)}
                  sx={{
                    borderBottom: 'unset',
                    cursor: log.changes.length > 0 ? 'pointer' : 'default',
                    backgroundColor: index % 2 === 0 ? rowColors.even : rowColors.odd,
                  }}
                  ref={index === prevLogsCount - 1 ? lastLogRef : null}
                >
                  <TableCell component="th" scope="row">
                    {t(getLogTypeString(log.logType))}
                  </TableCell>
                  <TableCell align="center">{log.userName}</TableCell>
                  <TableCell align="center">{t(log.componentType)}</TableCell>
                  <TableCell align="center">{t(log.action)}</TableCell>
                  <TableCell align="center">
                    {moment(log.dateTime).format('DD/MM/YYYY - HH:mm')}
                  </TableCell>
                  <TableCell align="center">{log.changes.length > 0 ? t('Ver Detalhes') : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open[log.dateTime]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        {log.changes.map((change) => (
                          <div key={change.propertyName}>
                            {`${t(change.propertyName)}: ${change.originalValue} -> ${change.updatedValue}`}
                          </div>
                        ))}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))
          )}
        </TableBody>
      </Table>
      {hasNextLogs && (
        <Box textAlign="center" padding={2}>
          <Button onClick={loadMoreLogs}>Mostrar Mais</Button>
        </Box>
      )}
    </TableContainer>
  )
}

export default LogTable
