import moment from 'moment'
import 'moment/locale/pt-br'
import 'moment/locale/es'
import * as S from '../../styles'
import { Error as ErrorIcon, Info as InfoIcon } from '@mui/icons-material'
import { ISitesMapProps } from '~/services/Dashboard/types'
import { Box, Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import { handleSeverityIcon } from '~/components/Map/Detalhes/utils'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import i18n from '~/components/translate/i18n'

type OlderInspectionsComponentProps = {
  sitesMap: ISitesMapProps[]
}

const OlderInspectionsComponent = ({ sitesMap = [] }: OlderInspectionsComponentProps) => {
  const { t } = useTranslation()

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[10],
      fontSize: 11,
    },
  }))

  useEffect(() => {
    moment.locale(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    moment.locale(i18n.language)
  }, [])

  useEffect(() => {
    const handleLanguageChange = () => {
      moment.locale(i18n.language);
    };

    i18n.on('languageChanged', handleLanguageChange);

    moment.locale(i18n.language);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
    <S.OlderInspectionsWrapper>
      <S.HeaderInfos>
        <h4>{t('Inspeções mais antigas')}</h4>
        <LightTooltip
          title={
            <Box>
              <S.StatusTooltipWrapper>
                <ErrorIcon color="success" />
                <h3>{t('Inspeções feitas nos últimos 9 meses')}</h3>
              </S.StatusTooltipWrapper>
              <S.StatusTooltipWrapper>
                <ErrorIcon color="warning" />
                <h3>{t('Inspeções feitas entre 9 e 12 meses')}</h3>
              </S.StatusTooltipWrapper>
              <S.StatusTooltipWrapper>
                <ErrorIcon color="error" />
                <h3>{t('Inspeções feitas a mais de 12 meses')}</h3>
              </S.StatusTooltipWrapper>
              <S.StatusTooltipWrapper>
                <ErrorIcon color="disabled" />
                <h3>{t('Inspeção não finalizada.')}</h3>
              </S.StatusTooltipWrapper>
            </Box>
          }
          placement="left"
        >
          <InfoIcon color="info" />
        </LightTooltip>
      </S.HeaderInfos>
      <S.OptionWrapper>
        {sitesMap.map((site) => {
          const lastInspectionDate = site.lastInspectionDate
            ? moment(site.lastInspectionDate).format('ddd, MMMM DD YYYY')
            : ''
          return (
            <S.OlderInspectionItem key={site.id}>
              <S.OlderInspectionLine>
                <p>{site.name}</p>
                <>{lastInspectionDate !== '' ? lastInspectionDate : t('Inspeção não finalizada.')}</>

              </S.OlderInspectionLine>
              {handleSeverityIcon(site.status)}
            </S.OlderInspectionItem>
          )
        })}
      </S.OptionWrapper>
      <S.BottomInfo>{t('Sítios ordenados por tempo desde a última inspeção')}</S.BottomInfo>
    </S.OlderInspectionsWrapper>
  )
}

export default OlderInspectionsComponent
