import { IoCloseSharp } from 'react-icons/io5'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { IModalCancelCustomProps } from './types'
import { Button, CircularProgress, IconButton } from '@mui/material'

import * as S from './styles'

export default function ModalCustom(props: Readonly<IModalCancelCustomProps>) {
  const { open, onClose, onClick, title, description, confirmationButtonText, textDeclineButton, loading = false, secondDescription } = props

  return (
    <S.Container>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } } }}
      >
        <S.StyleBox>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <S.HeaderModal>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {title}
              </Typography>
              <IconButton onClick={onClose} aria-label="close">
                <IoCloseSharp size={24} />
              </IconButton>
            </S.HeaderModal>
          </Typography>
          <S.MainModal>
            <Typography variant="body1" component="p">{description}</Typography>
            <Typography variant="body1" component="p">{secondDescription}</Typography>
            <S.Buttons>
              <Button
                variant="outlined"
                onClick={onClose}
                disabled={loading}
                sx={{ mt: 0, width: '50%' }}
              >
                {textDeclineButton}
              </Button>
              <Button
                variant="contained"
                color='error'
                onClick={onClick}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : null}
                sx={{ ml: 4, width: '50%' }}
              >
                {confirmationButtonText}
              </Button>
            </S.Buttons>
          </S.MainModal>
        </S.StyleBox>
      </Modal>
    </S.Container>
  )
}
