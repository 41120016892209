// ImageUploadModal.tsx
import React, { useState, ChangeEvent } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import * as S from '../../SiteCreateAndUpdate/styles';
import EditIcon from '@mui/icons-material/Edit';
import ErrorToast from '~/utils/toastErrorCatch';
import ToastNotification from '~/components/ToastNotification';
import { useTranslation } from 'react-i18next';
import { postFile } from '~/services/File';

interface ImageUploadModalProps {
  open: boolean;
  onClose: () => void;
  existingImage: string;
  originalFileName: string;
  uploadDate: string
  sitioId: string

}

const ImageUploadModal: React.FC<ImageUploadModalProps> = ({ open, onClose, existingImage, uploadDate, originalFileName, sitioId }) => {
  const [imagePreview, setImagePreview] = useState<string>(existingImage);
  const [fileName, setFileName] = useState<string>(originalFileName);
  const [updateDate, setUpdateDate] = useState<string>(uploadDate);
  const [imageSet, setImageSet] = useState<File | null>(null)

  const { t } = useTranslation()

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setImageSet(file)
      setImagePreview(URL.createObjectURL(file));
      setFileName(file.name);
    }
  };

  const RegisterImagens = async () => {

    if (!imageSet) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Selecione um arquivo para fazer upload.')
      });
      return; 
    }
    
    try {

      const formData = new FormData();
      formData.append('Id', sitioId);
      formData.append('Type', '3');
      formData.append('File', imageSet);

      const response = await postFile(formData)
      if (response.success) {
        setUpdateDate(new Date().toISOString().slice(0, 10));
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Imagem atualizada com sucesso!') ?? t('Imagem atualizada com sucesso!'),
        })

      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t(response.message),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível atualizar a imagem, tente novamente mais tarde.')} />
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="upload-image-modal-title"
      aria-describedby="upload-image-modal-description"
    >
      <Box sx={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 4,
        bgcolor: 'background.paper', boxShadow: 24, borderRadius: 2,
        width: 800,
        height: 760
      }}>
        <Typography id="upload-image-modal-title" variant="h6" component="h2" marginBottom={1.2}>
          {t("Imagem Referência de Identificação de Módulos")}
        </Typography>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
          <Typography variant="caption">{t("Última Atualização:")} {updateDate}</Typography>
        </Box>
        <Box
          sx={{
            position: 'relative',
            width: "100%",
            height: 560,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >

          {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '750px', height: '550px' }} />}
          <IconButton
            component="label"
            color="primary"
            aria-label="upload picture"
            sx={{ position: 'absolute', top: '15px', right: '1px' }}
          >
            <S.EditIcon topPosition={10}>
              <EditIcon color="inherit" />
            </S.EditIcon>
            <input
              type="file"
              hidden
              accept="image/*"
              id="input-file-imagem-site"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </IconButton>
        </Box>
        <Box>
          <Typography>{t("Nome")}: {fileName}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            paddingTop: 2.5,
            gap: 2
          }}
        >
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              width: "120px",
              backgroundColor: 'rgb(12, 74, 125)',
              ':hover': {
                backgroundColor: 'rgb(10, 62, 105)'
              }
            }}
          >
            {t("Voltar")}
          </Button>
          <Button
            onClick={RegisterImagens}
            variant="contained"
            sx={{
              width: "120px",
              backgroundColor: 'rgb(12, 74, 125)',
              ':hover': {
                backgroundColor: 'rgb(10, 62, 105)'
              }
            }}
          >
            {t("Salvar")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageUploadModal;
