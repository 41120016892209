import { deleteJson, getJson, postJson } from '../RequestService'
import { IRequestSiteUser, ISiteUserUpdatedLists } from './types'

const defaultRoot = 'siteuser'

export const getSiteUser = async (siteId?: string) => {
  let url = `${defaultRoot}`
  if (siteId) {
    url += `?siteId=${siteId}`
  }
  return await getJson<ISiteUserUpdatedLists>(url)
}

export const postUserSite = async (data: IRequestSiteUser) => {
  let url = `${defaultRoot}`
  url += `?UserId=${data.UserId}`
  url += `&SiteId=${data.SiteId}`
  url += `&ReturnUpdatedLists=${data.ReturnUpdatedLists}`
  return await postJson<unknown, ISiteUserUpdatedLists>(url, data)
}

export const deleteUserSite = async (data: IRequestSiteUser) => {
  let url = `${defaultRoot}`
  url += `?UserId=${data.UserId}`
  url += `&SiteId=${data.SiteId}`
  url += `&ReturnUpdatedLists=${data.ReturnUpdatedLists}`
  return await deleteJson<unknown, ISiteUserUpdatedLists>(url, data)
}
