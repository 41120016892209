import { makeStyles } from '@mui/material'
import styled from 'styled-components/macro'

export const Container = styled.div`
  margin: 0 10%;
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const ContainerInput = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-self: center;
`

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-self: center;
  padding: 62px 40px 24px 40px;
`

export const ContainerDoubleInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  align-self: center;
`

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  align-self: center;

  border: 2px solid ${(props) => props.theme.colors.blueRGBA2};
  border-radius: 16px;
  padding: 40px 40px 16px 40px;
  margin-top: 4px;
  margin-bottom: 40px;
  background-color: ${(props) => props.theme.colors.white}
`

export const TextForm = styled.p`
  line-height: 24px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 18px;
  align-self: center;
  display: flex;
  width: 80%;
  color: ${(props) => props.theme.colors.black};
`

export const TextPassword = styled.p`
  margin-top: 8px;
  margin-left: 16px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  align-self: center;
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.gray};
`

export const ButtonSelect = styled.button`
  display: flex;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color:  ${(props) => props.theme.colors.primary};
  align-items: center;
  justify-content: center;

  .label {
    margin: auto;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    border: none;
  }

  &:hover {
    opacity: 0.9;
  }
`

export const ErrorMessage = styled.span`
  display: flex;
  width: 100%;
  font-size: 12px;
  font-align: center;
  align-self: center;
  justify-content:center;
  align-items: center;
  color: ${props => props.theme.colors.red};
  padding-top: 4px;
`

export const TextButton = styled.p`
  margin-top: 24px;
  margin-left: 8px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  align-self: center;
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.gray};
`