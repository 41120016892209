/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { RequestLogsExport, ILogRequest, GetLogsResponse } from './types'
import { getJson, postJson } from '../RequestService'

const defaultRoot = 'logs'

export const getExportLogs = async (data: RequestLogsExport) => {
  let url = `${defaultRoot}/export`
  url += `?userId=${data.UserId}`
  url += `&format=${data.TypeLog}`
  return await getJson<any>(url)
}

export const postLogs = async (data: ILogRequest) => {
  let url = `${defaultRoot}`
  return await postJson<ILogRequest, GetLogsResponse>(url, data)
}
