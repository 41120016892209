/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ICompaniesAndBranches, ICompaniesAndBranchsSimple, RequestCompaniesAndBranches } from './types'
import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import {
  ICompaniesAndBranchesGet,
  ICompaniesAndBranchesList,
  ICompaniesSimple,
} from '~/pages/Dashboard/CompaniesAndBranches/types'

const defaultRoot = 'company'

export const getCompaniesAndBranches = async (data: RequestCompaniesAndBranches) => {
  let url = `${defaultRoot}`
  url += `?PageNumber=${data?.page}&PageSize=${data?.pageSize}`
  if (data?.search) url += `&Name=${data.search}`
  if (data?.statusCompany) url += `&Status=${data.statusCompany}`
  if (data?.typeOfCompany) url += `&Tipo=${data.typeOfCompany}`
  if (data?.id) url += `&HeadId=${data.id}`
  return await getJson<ICompaniesAndBranchesList[]>(url)
}

export const getCompanyAndBranch = async (id?: string) => {
  let url = `${defaultRoot}/${id}`
  return await getJson<ICompaniesAndBranchesGet>(url)
}

export const postCompaniesAndBranches = async (data: ICompaniesAndBranches) => {
  let url = `${defaultRoot}`
  return await postJson<ICompaniesAndBranches, string>(url, data)
}

export const putCompaniesAndBranches = async (data: ICompaniesAndBranches) => {
  let url = `${defaultRoot}`
  return await putJson<ICompaniesAndBranches, string>(url, data)
}

export const getCompanySimple = async (data?: ICompaniesAndBranchsSimple) => {
  let url = `${defaultRoot}/simple`

  if (data?.ConsiderUserCompany !== undefined) {
    url += `?ConsiderUserCompany=${data.ConsiderUserCompany}`
  }

  if (data?.OnlyHeadOffices !== undefined) {
    if (url.includes('?')) {
      url += `&OnlyHeadOffices=${data.OnlyHeadOffices}`
    } else {
      url += `?OnlyHeadOffices=${data.OnlyHeadOffices}`
    }
  }
  if (data?.ReturnOnlyInspectorCompanies !== undefined) {
    if (url.includes('?')) {
      url += `&ReturnOnlyInspectorCompanies=${data.ReturnOnlyInspectorCompanies}`
    } else {
      url += `?ReturnOnlyInspectorCompanies=${data.ReturnOnlyInspectorCompanies}`
    }
  }
  return await getJson<ICompaniesSimple[]>(url)
}

export const deleteCompany = async (id: string) => {
  let url = `${defaultRoot}?CompanyId=${id}`
  return await deleteJson<boolean, boolean>(url)
}

export const postLogoCompanies = async (data: FormData) => {
  let url = `${defaultRoot}/addlogo`
  return await postJson<FormData, boolean>(url, data)
}
