import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '~/components/Button'
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  FormControl,
  TextField,
  Tooltip,
} from '@mui/material'
import { UserOption } from './types'

import ToastNotification from '~/components/ToastNotification'

import * as R from '../../Dashboard/Sites/UploadImages/styles'
import * as S from './styles'
import { postLogs } from '~/services/Logs'
import { ELogType, LogDto, getLogTypeString } from '~/services/Logs/types'
import LogTable from './logTable'
import { getUserSimple } from '~/services/Users'
import ViewLoading from '~/components/ViewLoading'
import BaseLayout from '~/Layouts/BaseLayout'

type LogTypeOption = {
  label: string;
  value: ELogType;
};

export default function SystemLogs() {
  const { t } = useTranslation()
  const [loadingLogs, setLoadingLogs] = useState(false)
  const [selectedLogType, setSelectedLogType] = useState<number | ''>('')
  const [nextLogs, setNextLogs] = useState<string | null>(null)
  const [allLogs, setAllLogs] = useState<LogDto[]>([])
  const [userList, setUserList] = useState<UserOption[]>([])
  const [selectedUserId, setSelectedUserId] = useState('')
  const [prevLogsCount, setPrevLogsCount] = useState(0)

  const lastLogRef = useRef<HTMLTableRowElement | null>(null)

  const [translatedLogTypeOptions, setTranslatedLogTypeOptions] = useState<LogTypeOption[]>([])

  const loadUsers = async () => {
    try {
      const response = await getUserSimple()
      if (response.success) {
        const usersOptions = response.data.map((user) => ({
          value: user.id,
          label: user.name ?? user.email ?? 'Usuário sem nome',
        }))
        setUserList(usersOptions)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os usuarios, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os usuarios, tente novamente mais tarde.'),
      })
    }
  }
  const limit = 1000
  const loadLogs = async (nextLogsToken: string | null = null) => {
    setLoadingLogs(true)
    try {
      const params = {
        ...(nextLogsToken && { nextLogsToken }),
        ...(selectedUserId && { userId: selectedUserId }),
        ...(formattedFromDate && { startDateTime: formattedFromDate }),
        ...(formattedToDate && { endDateTime: formattedToDate }),
        ...(selectedLogType && { logType: selectedLogType }),
        ...(limit && { limit: limit }),
      }
      const response = await postLogs(params)
      if (response.success) {
        setAllLogs((prevLogs) => [...prevLogs, ...response.data.logs])
        setNextLogs(response.data.nextLogsToken)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      console.error(error)
     }
    setLoadingLogs(false)
  }

  const logTypeOptions = Object.keys(ELogType)
    .filter((key) => !isNaN(Number(ELogType[key as keyof typeof ELogType])))
    .map((key) => ({
      value: ELogType[key as keyof typeof ELogType],
      label: getLogTypeString(ELogType[key as keyof typeof ELogType]),
    }))
  useEffect(() => {
    loadLogs()
    loadUsers()
  }, [])


  useEffect(() => {
    const translatedLogTypes = logTypeOptions.map(option => ({
      ...option,
      label: t(option.label)  
    }));
    setTranslatedLogTypeOptions(translatedLogTypes);  
  }, [logTypeOptions, t]);

  const handleSelectUser = (
    event: ChangeEvent<{}>,
    value: UserOption | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<UserOption>,
  ) => {
    setSelectedUserId(value ? value.value : '')
  }
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [formattedFromDate, setFormattedFromDate] = useState('')
  const [formattedToDate, setFormattedToDate] = useState('')

  const handleFromDateChange = (e: any) => {
    const inputDate = e.target.value
    setFromDate(inputDate)

    if (inputDate) {
      const [year, month, day] = inputDate.split('-').map((num: any) => parseInt(num, 10))
      let date = new Date(Date.UTC(year, month - 1, day))
      const formattedDateTime = date.toISOString()
      setFormattedFromDate(formattedDateTime)
    }
  }

  const handleToDateChange = (e: any) => {
    const inputDate = e.target.value
    setToDate(inputDate)
    if (inputDate) {
      const [year, month, day] = inputDate.split('-').map((num: any) => parseInt(num, 10))
      let date = new Date(Date.UTC(year, month - 1, day))
      const formattedDateTime = date.toISOString()
      setFormattedToDate(formattedDateTime)
    }
  }
  const handleSearch = () => {
    setNextLogs(null)
    setAllLogs([])
    loadLogs()
  }

  const handleShowMoreLogs = () => {
    setPrevLogsCount(allLogs.length)
    loadLogs(nextLogs)
  }
  useEffect(() => {
    if (lastLogRef.current) {
      lastLogRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [allLogs])

  return (
    <BaseLayout title={t('Logs do Sistema')}>
      <S.FlexWrapper>
        <FormControl
          sx={{
            marginTop: 5,
            marginBottom: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <R.AutocompleteWrapper>
            <Autocomplete
              value={userList.find((user) => user.value === selectedUserId)}
              onChange={handleSelectUser}
              options={userList}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Selecione um usuário')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              )}
              noOptionsText={t('Nenhum usuário encontrado')}
            />
          </R.AutocompleteWrapper>
          <R.AutocompleteWrapper>
            <Autocomplete
              value={
                selectedLogType === ''
                  ? null
                  : translatedLogTypeOptions.find((option) => option.value === selectedLogType) ?? null
              }
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setSelectedLogType('')
                } else {
                  const newLogType = newValue?.value as number
                  setSelectedLogType(newLogType)
                }
              }}
              options={translatedLogTypeOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Tipo de Log')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              )}
              noOptionsText={t('Nenhum tipo de log encontrado')}
            />
          </R.AutocompleteWrapper>
          <R.InputWrapper width="10%">
            <TextField
              size="small"
              type="date"
              value={fromDate || new Date()}
              onChange={handleFromDateChange}
              fullWidth
              label={t("De")}
            />
          </R.InputWrapper>
          <R.InputWrapper width="10%">
            <TextField
              size="small"
              type="date"
              value={toDate || new Date()}
              onChange={handleToDateChange}
              fullWidth
              id="input-date-To"
              label={t("Até")}
            />
          </R.InputWrapper>
          <Tooltip title="Pesquisar">
            <S.ContainerButton>
              <Button
                type="button"
                onClick={handleSearch}
                label={t('Pesquisar')}
                style={{ marginTop: 0 }}
              />
            </S.ContainerButton>
          </Tooltip>
        </FormControl>

        {loadingLogs ? (
          <ViewLoading />
        ) : (
          <>
            {loadingLogs ? (
              <ViewLoading />
            ) : (
              <>
                {allLogs.length > 0 && (
                  <LogTable
                    data={allLogs}
                    loadMoreLogs={handleShowMoreLogs}
                    hasNextLogs={!!nextLogs}
                    lastLogRef={lastLogRef}
                    prevLogsCount={prevLogsCount}
                  />
                )}
              </>
            )}
          </>
        )}
      </S.FlexWrapper>
    </BaseLayout>
  )
}
