import { Box, Paper } from '@mui/material'
import styled from 'styled-components'

export const SearchWrapper = styled(Paper)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const Inputs = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 20px;
  align-items: center;
`

export const Buttons = styled(Box)`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
`

export const IconButton = styled(Box)`
  cursor: pointer;
`
