import styled from 'styled-components/macro'

export const Container = styled.div`
  margin: 0 10%;
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`
export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 16px 40px;
`

export const Filter = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 8px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 0px 40px 40px 0px;
  width: 80px;
  height: 40px;

  &:hover {
    opacity: 0.9;
  }
`
export const ContainerSearch = styled.div`
  width: 100%;

  padding: 0 24px;
`

export const SearchBtn = styled.div`
  width: 180px;

  .btn_icon {
    color: white;
    font-size: 20px;
  }

  @media (max-width: 1024px) {
    width: 190px;
  }
`

export const Main = styled.div`
  width: 100%;
  height: 61%;
  padding: 0 50px;
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`

export const Message = styled.p`
  font-size: 18px;
  padding: 12px 24px;
`

export const ContainerButton = styled.div`
  justify-content: center;
  align-items: center;
  width: 300px;
`

export const TextBranch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;

  label {
    font-size: 22px;
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.colors.black};
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
`

export const ButtonSelect = styled.button`
  display: flex;
  width: 100%;
  min-width: 450px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.colorText};
  border-radius: 40px 0px 0px 40px;
  background-color: transparent;
  align-items: center;
  justify-content: center;

  .label {
    margin: auto;
    color: ${(props) => props.theme.colors.colorText};
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    border: none;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.primary};
    .label {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

export const ContainerButtonSelect = styled.div`
  display: flex;
  width: 100%;
`
