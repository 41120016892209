/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ToastNotification from '~/components/ToastNotification'

import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/pt-br'
import { useTranslation } from 'react-i18next'
import { setLocale } from 'yup'
import { LOCALE_YUP_BR, LOCALE_YUP_ES, LOCALE_YUP_US } from '~/assets/Constants'
import '~/components/translate/i18n'
import { LANGUAGE_GET, USER_GET } from '~/config/constants'
import { login } from '~/services/Auth'
import { cleanLocalStorage, getLocalStorage, setLocalStorage } from '../../utils/StorageLocal'
import { AuthProviderProps, IAuthContext, IUser } from './types'

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<IUser | null>(null)
  const [languageSelected, setLanguageSelected] = useState<string | null>(getLocalStorage(LANGUAGE_GET))
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const { i18n, t } = useTranslation()

  useEffect(() => {
    const loadAsyncStorage = () => {
      const storagedUser = getLocalStorage<IUser>(USER_GET)
      if (storagedUser && storagedUser.accessToken) {
        setUser(storagedUser)
      }
      setLoading(false)
    }
    loadAsyncStorage()
    changeLanguageOnLoad()
  }, [])

  const signIn = async (email: string, password: string) => {
    try {
      const bodyData = {
        grantType: 1,
        email,
        password: password,
      }
      const response = await login(bodyData)
      if (response.success) {
        setUser(response.data)
        setLocalStorage<IUser>(USER_GET, response.data)
        navigate('/')
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao realizar o login, tente novamente.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
        signOut()
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao realizar o login, tente novamente.'),
      })
    }
  }

  const signOut = () => {
    setUser(null)
    cleanLocalStorage()
    navigate('/login')
  }

  const setLocaleValidator = async (language: string | null) => {
    if (language === 'BR') {
      setLocale(LOCALE_YUP_BR)
      moment.locale('pt-br')
    } else if (language === 'US') {
      setLocale(LOCALE_YUP_US)
      moment.locale('en-US')
    } else if (language === 'ES') {
      setLocale(LOCALE_YUP_ES)
      moment.locale('es')
    }
  }

  const changeLanguageOnLoad = async () => {
    try {
      const language = await getLocalStorage<string | null>(LANGUAGE_GET)
      if (language) {
        changeLanguage(language)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const changeLanguage = (language: string) => {
    i18n
      .changeLanguage(language)
      .then(() => {
        setLocaleValidator(language)
        setLanguageSelected(language)
        setLocalStorage(LANGUAGE_GET, language)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: !!user,
        loading,
        signIn,
        signOut,
        changeLanguage,
        languageSelected: languageSelected ?? 'BR',
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  return context
}
