import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ptBR } from '@mui/x-date-pickers/locales'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MapProvider } from 'react-map-gl'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import { Container } from './components/Container'
import { AuthProvider } from './contexts/Auth'
import { LogsProvider } from './contexts/Logs'
import RoutesApp from './routes'
import { GlobalStyled } from './styles/globalStyled'
import { theme } from './styles/theme'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <LogsProvider>
            <Container>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <MapProvider>
                  <RoutesApp />
                </MapProvider>
              </LocalizationProvider>
            </Container>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <GlobalStyled />
          </LogsProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
