import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography } from '@mui/material'
import { useTheme } from 'styled-components'

import Icon from '~/assets/icons'

import * as S from './styles'

const NotFound: React.FC = () => {
  const title = 'Erro'
  const error = '404'
  const description = 'Página não encontrada'
  const theme = useTheme()
  const navigate = useNavigate()

  const handleBack = () => navigate('/')

  return (
    <S.Container>
      <Icon name='Warning' width={48} height={48} fill={theme.colors.primary}/>

      <Typography variant="h2" style={{ marginTop: 24, textAlign: 'center' }}>
        {title} {error}
      </Typography>
      <Typography variant="h4" style={{ marginBottom: 56, marginTop: 16, textAlign: 'center' }}>
        {description}
      </Typography>
      <button onClick={handleBack} > Voltar para o sistema  </button>
    </S.Container>
  )
}

export default NotFound
