import { createGlobalStyle } from 'styled-components'

export const GlobalStyled = createGlobalStyle`
  :root {
    --background: #EFF3F9;
    --bg-login: #fff;
    --light: #fff;
    --color-btn: #0C4A7D;
    --color-line: #EFEFEF;
    --color-orange: #F89722;
    --color-orange500: #FFA73E;

    --red: #EE4B23;
    --blue: #0084FF;
    --gray: #8C9AA4;

    --color-text: #333333;
    --color-black: #000000;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: var(--background);
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    color: var(--purple);
  }

  button {
    cursor: pointer;
    font-family: var(--purple);
  }
`
