/* eslint-disable no-throw-literal */
interface IMaskObj {
  conditional: boolean
  expr1: string
  expr2?: string
}
export const onlyDigits = (input?: string) => {
  if (!input) return ''
  return input.replace(/\D/g, '')
}

export const onlyStr = (input?: string) => {
  if (!input) return ''
  return input.replace(/[^\w]|\d/g, '')
}

export const formatDueDate = (date: string) => {
  const splited = date.split('/')
  const result = splited[1] + '-' + splited[0] + '-' + '01'
  return result
}

const renderTernary = (conditional: boolean, expr1: string, expr2 = '') => {
  return conditional ? expr1 : expr2
}

const reduceList = (list: IMaskObj[]) => {
  return list.reduce((join: string, obj) => {
    join += renderTernary(obj.conditional, obj.expr1, obj.expr2)
    return join
  }, '')
}

export const cepMask = (input?: string) => {
  try {
    const regExp = /(\d{1,5})(\d{0,3})/
    const value = regExp.exec(onlyDigits(input ?? ''))
    if (typeof input !== 'string' || !value) return ''
    return renderTernary(!value[2], value[1], `${value[1]}-${value[2]}`)
  } catch (error) {
    return ''
  }
}

export const cpfMask = (input?: string) => {
  try {
    const regExp = /(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,2})/
    const value = regExp.exec(onlyDigits(input ?? ''))
    if (typeof input !== 'string' || !value) return ''

    const listMask = [
      { conditional: !value[2], expr1: value[1], expr2: `${value[1]}.${value[2]}` },
      { conditional: !!value[3], expr1: `.${value[3]}` },
      { conditional: !!value[4], expr1: `-${value[4]}` },
    ]

    return reduceList(listMask)
  } catch (error) {
    return ''
  }
}

export const cnpjMask = (input?: string | null) => {
  if (!input) return '';
  const digits = onlyDigits(input);
  if (digits.length === 0) return '';

  let formatted = '';
  formatted += digits.slice(0, 2);
  if (digits.length > 2) formatted += '.' + digits.slice(2, 5);
  if (digits.length > 5) formatted += '.' + digits.slice(5, 8);
  if (digits.length > 8) formatted += '/' + digits.slice(8, 12);
  if (digits.length > 12) formatted += '-' + digits.slice(12, 14);

  return formatted;
};



export const cpfCnpjMask = (input?: string) => {
  if (!input) return ''
  if (onlyDigits(input).length <= 11) return cpfMask(input)
  return cnpjMask(input)
}

export const pisMask = (input?: string) => {
  try {
    const regex = /(\d{1,3})(\d{0,5})(\d{0,2})(\d?)/
    const value = regex.exec(onlyDigits(input ?? ''))
    if (typeof input !== 'string' || !value) return ''

    const listMask = [
      { conditional: !value[2], expr1: value[1], expr2: `${value[1]}.${value[2]}` },
      { conditional: !!value[3], expr1: `.${value[3]}` },
      { conditional: !!value[4], expr1: `-${value[4]}` },
    ]

    return reduceList(listMask)
  } catch (error) {
    return ''
  }
}

const listPhoneMask = (value: RegExpMatchArray) => {
  return [
    { conditional: !value[2], expr1: value[1], expr2: `(${value[1]}) ${value[2]}` },
    { conditional: !!value[3], expr1: `-${value[3]}` },
  ]
}

export const phoneMask = (input?: string) => {
  if (!input) return ''

  input = onlyDigits(input)
  if (input.length <= 10) return landlinePhoneMask(input)
  return mobilePhoneMask(input)
}

export const mobilePhoneMask = (input?: string) => {
  try {
    const regex = /^(\d{1,2})(\d{0,5})(\d{0,4})/
    const value = regex.exec(onlyDigits(String(input)))
    if (typeof input !== 'string' || !value) return ''

    const listMask = listPhoneMask(value)
    return reduceList(listMask)
  } catch (error) {
    return ''
  }
}

export const landlinePhoneMask = (input?: string) => {
  try {
    const regex = /^(\d{1,2})(\d{0,4})(\d{0,4})/
    const value = regex.exec(onlyDigits(String(input)))
    if (typeof input !== 'string' || !value) return ''

    const listMask = listPhoneMask(value)
    return reduceList(listMask)
  } catch (error) {
    return ''
  }
}

export const dateMask = (input?: string) => {
  try {
    const regex = /(\d{1,2})(\d{0,2})(\d{0,4})/
    const value = regex.exec(onlyDigits(String(input)))
    if (typeof input !== 'string' || !value) return ''

    const listMask = [
      { conditional: !value[2], expr1: value[1], expr2: `${value[1]}/${value[2]}` },
      { conditional: !!value[3], expr1: `/${value[3]}` },
    ]

    return reduceList(listMask)
  } catch (error) {
    return ''
  }
}

export const dateMaskInverter = (input?: string) => {
  try {
    const value = onlyDigits(String(input))
    if (typeof input !== 'string' || !value) return ''
    const data = value.substring(2, 4) + '-' + value.substring(0, 2) + '-' + value.substring(4, 8)
    return data
  } catch (error) {
    return ''
  }
}

export const dateMaskCardInverter = (input?: string) => {
  try {
    const value = onlyDigits(String(input))
    if (typeof input !== 'string' || !value) return ''
    const data = value.substring(4, 8) + '-' + value.substring(2, 4) + '-' + value.substring(0, 2)
    return data
  } catch (error) {
    return ''
  }
}

export const dateInverterCalendarMask = (input?: string) => {
  try {
    const regExp = /(\d{1,2})(\d{0,2})(\d{0,4})/
    const value = regExp.exec(onlyDigits(String(input)))
    if (typeof input !== 'string' || !value) return ''

    const listMask = [
      { conditional: !value[2], expr1: value[3], expr2: `${value[3]}-${value[2]}` },
      { conditional: !!value[3], expr1: `-${value[1]}` },
    ]

    return reduceList(listMask)
  } catch (error) {
    return ''
  }
}

export const dateTraceInverterMask = (input?: string) => {
  try {
    const regExp = /(\d{1,4})(\d{0,2})(\d{0,2})/
    const value = regExp.exec(onlyDigits(String(input)))
    if (typeof input !== 'string' || !value) return ''

    const listMask = [
      { conditional: !value[2], expr1: value[3], expr2: `${value[3]}/${value[2]}` },
      { conditional: !!value[3], expr1: `/${value[1]}` },
    ]

    return reduceList(listMask)
  } catch (error) {
    return ''
  }
}

export const hourInDateMask = (input: Date) => {
  try {
    const hours = input.getHours() < 10 ? `0${input.getHours()}` : input.getHours()
    const minutes = input.getMinutes() < 10 ? `0${input.getMinutes()}` : input.getMinutes()
    return `${hours}h${minutes}`
  } catch (error) {
    return ''
  }
}

export const hourMask = (input?: string) => {
  try {
    const regExp = /(\d{1,2})(\d{0,2})/
    const value = regExp.exec(onlyDigits(String(input)))
    if (typeof input !== 'string' || !value) return ''

    const listMask = [
      { conditional: !value[2], expr1: `${value[1]}h`, expr2: `${value[1]}h${value[2]}` },
    ]

    return reduceList(listMask)
  } catch (error) {
    return ''
  }
}

export const dateHourMask = (input?: string) => {
  try {
    const regExp = /(\d{1,2})(\d{0,2})(\d{0,4})(\d{0,2})(\d{0,2})/
    const value = regExp.exec(onlyDigits(String(input)))
    if (typeof input !== 'string' || !value) return ''

    const listMask = [
      { conditional: !value[2], expr1: value[1], expr2: `${value[1]}/${value[2]}` },
      { conditional: !!value[3], expr1: `/${value[3]}` },
      { conditional: !!value[4], expr1: ` ${value[4]}h` },
      { conditional: !!value[5], expr1: `${value[5]}` },
    ]

    return reduceList(listMask)
  } catch (error) {
    return ''
  }
}

export const dateOrStringMask = (dateValue: string | Date) => {
  const date: Date = new Date(dateValue)

  if (date instanceof Date && !isNaN(date as unknown as number)) {
    const year = date.getFullYear()
    const month = `00${date.getMonth() + 1}`.slice(-2)
    const day = `00${date.getUTCDate()}`.slice(-2)
    return `${day}/${month}/${year}`
  } else return '**/**/****'
}

export const hourAmPmMask = (date: Date) => {
  let hours = date.getHours()
  let minutes: number | string = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ?? 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  const stringTime = hours + ':' + minutes + ' ' + ampm
  return stringTime
}

export const validateIfNull = (input?: string, inputOptional = '-') => {
  return input ?? inputOptional
}

export const renderStatus = (type: number) => {
  const status = {
    0: '-',
    1: 'Ativo',
    2: 'Inativo',
  }
  return status[type]
}

export const DataMaskFormtData = (DateReceive: string) => {
  const DateTest = new Date(DateReceive)
  const day = DateTest.getUTCDate()
  const month = DateTest.getMonth() + 1
  const year = DateTest.getFullYear()

  let dy
  let mont

  if (Number(day) < 10) {
    dy = `0${day.toString()}`
  } else {
    dy = day
  }

  if (Number(month) < 10) {
    mont = `0${month.toString()}`
  } else {
    mont = month
  }

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const data = dy + '/' + mont + '/' + year

  return data
}

export const globalUnitFormatter = (value: number, language: string) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  switch (language) {
    case 'BR':
      return value.toLocaleString('pt-BR', options)
    case 'US':
      return value.toLocaleString('en-US', options)
    case 'ES':
      return value.toLocaleString('es-ES', options)
    default:
      return value.toFixed(2)
  }
}
