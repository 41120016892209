import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ToastNotification from '~/components/ToastNotification'
import ViewLoading from '~/components/ViewLoading'
import { useAuth } from '~/contexts/Auth'
import { getAdvancedSettings, postAdvancedSettings } from '~/services/CompanyAnomalyType'
import { ICompanyAnomalyTypeGet } from '~/services/CompanyAnomalyType/types'
import * as S from '../styles'

type SeverityLevel = {
  enumCode: string | number
  enumValue: string
}

type AnomalyType = {
  anomalyTypeId: number
  originalName: string
  name?: string
  severity?: number
  powerLossKwh?: number
}

type FormData = {
  [key: string]: any
}

const Anomalias = () => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const { handleSubmit, control, setValue, getValues } = useForm<FormData>()
  const [openDialog, setOpenDialog] = useState<boolean | string>(false)
  const [severityLevels, setSeverityLevels] = useState<SeverityLevel[]>([])
  const [originalFormData, setOriginalFormData] = useState<AnomalyType[]>([])

  const fetchCompanyAnomalyTypesConfigs = async () => {
    try {
      const response = await getAdvancedSettings({
        companyId: user?.companyId,
      } as ICompanyAnomalyTypeGet)

      if (response.success) {
        setSeverityLevels([
          { enumCode: 'null', enumValue: t('Padrão') },
          ...response.data.severityLevels.map((severity: any) => ({
            enumCode: severity.enumCode,
            enumValue: t('Severidade_.' + severity.enumValue),
          })),
        ])
        setOriginalFormData(response.data.anomalyTypes)
        response.data.anomalyTypes.forEach((anomaly: AnomalyType) => {
          setValue(`anomalyTypes[${anomaly.anomalyTypeId}].anomalyTypeId`, anomaly.anomalyTypeId || '')
          setValue(`anomalyTypes[${anomaly.anomalyTypeId}].name`, anomaly.name || '')
          setValue(`anomalyTypes[${anomaly.anomalyTypeId}].severity`, anomaly.severity || 'null')
          setValue(`anomalyTypes[${anomaly.anomalyTypeId}].powerLossKwh`, anomaly.powerLossKwh || '')
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const processFormData = (formData: any[]) => {
    const normalizedFormData: any = formData.map((data: any) => ({
      anomalyTypeId: data.anomalyTypeId,
      name: data.name.trim() === '' ? null : data.name,
      severity: data.severity === 'null' ? null : data.severity,
      powerLossKwh:
        data.powerLossKwh === ''
          ? null
          : typeof data.powerLossKwh === 'number'
          ? data.powerLossKwh
          : Number(data.powerLossKwh.replace(/,/g, '.')),
    }))

    const originalDataMap = new Map(originalFormData.map((item: any) => [item.anomalyTypeId, item]))

    const processedData = normalizedFormData.reduce((acc: any[], data: any) => {
      const originalData = originalDataMap.get(data.anomalyTypeId)
      if (!originalData) {
        acc.push(data)
      } else {
        const isModified = ['name', 'severity', 'powerLossKwh'].some(
          (key) => data[key] !== originalData[key],
        )
        if (isModified) {
          acc.push(data)
        }
      }
      return acc
    }, [])

    return processedData
  }

  const onSubmit = async (formData: FormData) => {
    const processedFormDataArray = processFormData(formData.anomalyTypes)

    const hasHighPowerLoss = processedFormDataArray.some(
      (data: any) => data.powerLossKwh !== null && data.powerLossKwh > 10,
    )
    const powerLossHasMoreThanFourDecimalPlaces = processedFormDataArray.some(
      (data: any) =>
        data.powerLossKwh !== null && data.powerLossKwh.toString().split('.')[1]?.length > 4,
    )
    const isPowerLossNegativeOrAbsoluteZero = processedFormDataArray.some(
      (data: any) => data.powerLossKwh !== null && data.powerLossKwh <= 0,
    )

    if (hasHighPowerLoss) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t(
          t(
            'AdvancedSettings.Não é possível enviar os dados pois um ou mais registros têm um valor de perda de energia maior que 10.',
          ),
        ),
      })
      return
    }
    if (powerLossHasMoreThanFourDecimalPlaces) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t(
          t(
            'AdvancedSettings.Não é possível enviar os dados pois um ou mais registros tem perda de energia com mais de 4 casas decimais.',
          ),
        ),
      })
      return
    }
    if (isPowerLossNegativeOrAbsoluteZero) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t(
          t(
            'AdvancedSettings.Não é possível enviar os dados pois um ou mais registros tem perda de energia negativa ou com zero absoluto.',
          ),
        ),
      })
      return
    }

    const dataToSubmit = {
      companyId: user?.companyId,
      anomalyTypes: processedFormDataArray,
    }

    try {
      const response = await postAdvancedSettings(dataToSubmit)
      if (response.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Os dados foram salvos com sucesso.'),
        })
        fetchCompanyAnomalyTypesConfigs()
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível enviar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível enviar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const setDefaultValues = () => {
    originalFormData.forEach((anomaly: AnomalyType) => {
      setValue(`anomalyTypes[${anomaly.anomalyTypeId}].anomalyTypeId`, anomaly.anomalyTypeId || '')
      setValue(`anomalyTypes[${anomaly.anomalyTypeId}].name`, '')
      setValue(`anomalyTypes[${anomaly.anomalyTypeId}].severity`, 'null')
      setValue(`anomalyTypes[${anomaly.anomalyTypeId}].powerLossKwh`, '')
    })

    onSubmit(getValues())
  }

  const cleanFormToOriginalValues = () => {
    originalFormData.forEach((anomaly: AnomalyType) => {
      setValue(`anomalyTypes[${anomaly.anomalyTypeId}].anomalyTypeId`, anomaly.anomalyTypeId || '')
      setValue(`anomalyTypes[${anomaly.anomalyTypeId}].name`, anomaly.name || '')
      setValue(`anomalyTypes[${anomaly.anomalyTypeId}].severity`, anomaly.severity || 'null')
      setValue(`anomalyTypes[${anomaly.anomalyTypeId}].powerLossKwh`, anomaly.powerLossKwh || '')
    })
  }

  useEffect(() => {
    fetchCompanyAnomalyTypesConfigs()
  }, [])

  return (
    <Paper
      sx={{
        padding: 3,
        margin: '2px calc(10%)',
        backgroundColor: 'white',
        boxShadow: 1,
      }}
    >
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: '100%' }}>
          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }}>
              <TableHead sx={{ bgcolor: 'rgb(21, 95, 153)', height: 80 }}>
                <TableRow>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: 16 }}>
                    {t('AdvancedSettings.Nome Padrão')}
                  </TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} align="right">
                    {t('AdvancedSettings.Nome Alternativo')}
                  </TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} align="right">
                    {t('AdvancedSettings.Nível de Severidade')}
                  </TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: 16 }} align="right">
                    {t('AdvancedSettings.Perda de Energia')} (kWh)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {originalFormData.length == 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      align="center"
                      style={{ height: 180, fontSize: 22, fontWeight: 'bold', paddingTop: 60 }}
                    >
                      <ViewLoading />
                    </TableCell>
                  </TableRow>
                ) : (
                  originalFormData.map((anomalyType: AnomalyType) => (
                    <TableRow key={anomalyType.anomalyTypeId}>
                      <TableCell sx={{ fontWeight: 'bold' }}>{t(anomalyType.originalName)}</TableCell>
                      <TableCell align="right">
                        <Controller
                          name={`anomalyTypes[${anomalyType.anomalyTypeId}].name`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              variant="outlined"
                              placeholder={t('Padrão') as string}
                              inputProps={{ maxLength: 64 }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Controller
                          name={`anomalyTypes[${anomalyType.anomalyTypeId}].severity`}
                          control={control}
                          render={({ field }) => (
                            <FormControl size="small" variant="outlined" sx={{ width: 250 }}>
                              <Select
                                {...field}
                                value={field.value || ''}
                                onChange={(event) => {
                                  field.onChange(
                                    event.target.value === 'null' ? 'null' : Number(event.target.value),
                                  )
                                }}
                              >
                                {severityLevels.map((level) => (
                                  <MenuItem key={level.enumCode} value={level.enumCode}>
                                    {level.enumValue}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Controller
                          name={`anomalyTypes[${anomalyType.anomalyTypeId}].powerLossKwh`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              variant="outlined"
                              placeholder={t('Padrão') as string}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {originalFormData.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', gap: 2 }}>
            <Tooltip title={t('Salvar todos os dados alterados')} placement="top">
              <Button type="submit" variant="contained">
                {t('Salvar')}
              </Button>
            </Tooltip>
            <Tooltip title={t('Restaurar todos os valores padrões e salvar')} placement="top">
              <Button onClick={() => setOpenDialog('restore')} variant="outlined">
                {t('Restaurar')}
              </Button>
            </Tooltip>
            <Tooltip title={t('Limpar todos os valores alterados')} placement="top">
              <Button onClick={() => setOpenDialog('clear')} variant="outlined">
                {t('Limpar')}
              </Button>
            </Tooltip>
          </Box>
        )}
      </S.Form>

      <Dialog open={!!openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle id="alert-dialog-title">
          {openDialog == 'restore' && t('Restaurar todos os valores padrões e salvar') + '?'}
          {openDialog == 'clear' && t('Limpar todos os valores alterados') + '?'}
        </DialogTitle>
        {openDialog == 'restore' && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ fontWeight: 'bold', color: 'red' }}>
                {t('ATENÇÃO: Essa operação não pode ser desfeita.')}
              </p>
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              openDialog == 'restore' ? setDefaultValues() : cleanFormToOriginalValues()
              setOpenDialog(false)
            }}
            variant="contained"
          >
            {t('Sim')}
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false)
            }}
            variant="outlined"
            autoFocus
          >
            {t('Não')}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default Anomalias
