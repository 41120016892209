import ToastNotification from '~/components/ToastNotification'
import { TFunction } from 'i18next'
import { NavigateFunction } from 'react-router-dom'

interface ResponseType {
  success?: boolean
  errorDetails?: string
  errors?: any[]
}

type Token = string | undefined

const handleResponse = (
  response: ResponseType,
  token: Token,
  t: TFunction,
  navigate?: NavigateFunction,
) => {
  if (response.success) {
    ToastNotification({
      id: 'success',
      type: 'success',
      message: token ? t('Edição realizada com sucesso!') : t('Cadastro realizado com sucesso!'),
    })
    if (navigate) {
      navigate(-1)
    }
  } else {
    ToastNotification({
      id: 'error',
      type: 'error',
      message: token
        ? t('Não foi possível realizar a edição, tente novamente mais tarde.')
        : t('Não foi possível realizar o cadastro, tente novamente mais tarde.'),
      errorMessage: response.errorDetails,
      errors: response.errors,
    })
  }
}

export default handleResponse
