import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Main } from './styles'

interface IButtonLink {
  route: string
  onClick?: () => void
  text?: string
  icon?: ReactNode
  target?: string
}

export const ButtonLink = (props: IButtonLink) => {
  const { route, text, icon, target, onClick } = props
  return (
    <Main>
      <button onClick={onClick}>
        <Link to={route} target={target}>
          {icon}{text}
        </Link>
      </button>
    </Main>
  )
}
