import { useTranslation } from 'react-i18next'
import * as S from '../../../styles'

const CustomTooltip = (props: any) => {
  const { t } = useTranslation()

  const { active, payload, label } = props
  if (active && payload?.length) {
    const getLabel = (payload: any) => {
      switch (payload[0].dataKey) {
        case 'estimatedHourlyPowerLossInMegawatt':
          return `${t('Perda de energia por hora (MW)')}: ${payload[0].value}`
        case 'countSubtotal':
          return `${t('Total')}: ${payload[0].value}`
        case 'countPercentage':
          return `${t('Porcentagem')}: ${payload[0].value}`
        default:
          return ``
      }
    }
    return (
      <S.CustomTooltipWrapper>
        <h4>{label}</h4>
        <h4>{`${getLabel(payload)}`}</h4>
      </S.CustomTooltipWrapper>
    )
  }

  return null
}

export default CustomTooltip
