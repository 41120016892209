import React from "react";
import { Typography, Box } from "@mui/material";

interface CustomStepIconProps {
    active: boolean;
    completed: boolean;
    icon: number;
}

const CustomStepIcon: React.FC<CustomStepIconProps> = ({
    active,
    completed,
    icon,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 24,
                height: 24,
                borderRadius: '50%',
                border: completed ? "none" : active ? "2px solid #FFA500" : "2px solid #C4C4C4",
                backgroundColor: active ? '#FFA500' : completed ? '#0C4A7D' : "none",
                color: active || completed ? 'white' : 'black'
            }}
        >
            <Typography variant="caption" component="span">
                {icon}
            </Typography>
        </Box>
    );
};

export default CustomStepIcon;
