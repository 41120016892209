import React, { CSSProperties, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getInspectionsExampleImages } from '~/services/Inspections';
import ToastNotification from '~/components/ToastNotification';
import { useTranslation } from 'react-i18next';
import ErrorToast from '~/utils/toastErrorCatch';
import { IItemsImagesExample } from '~/services/Inspections/types';
import Button from '~/components/Button';
import { Box } from '@mui/system';

interface InspectionStatusModalProps {
  open: boolean;
  onClose: () => void;
  inspectionId: string;
  onConfirm: () => void;
}

const InspectionStatusModal: React.FC<InspectionStatusModalProps> = ({
  open,
  onClose,
  inspectionId,
  onConfirm
}) => {
  const [items, setItems] = useState<IItemsImagesExample[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 1;
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const getImagesExamples = async (page: number) => {
    setLoading(true);
    try {
      const response = await getInspectionsExampleImages({
        InspectionId: inspectionId,
        PageNumber: page,
        PageSize: pageSize
      });
      if (response.success) {
        setItems(response.data.items);
        setCurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível buscar as imagens, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        });
      }
    } catch (error) {
      ErrorToast({ message: t('Não foi possível buscar as imagens, tente novamente mais tarde.') });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      getImagesExamples(currentPage);
    }
  }, [open, inspectionId]);

  const handleNext = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      getImagesExamples(nextPage);
      setCurrentPage(nextPage);
    }
  };

  const handlePrevious = () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      getImagesExamples(prevPage);
      setCurrentPage(prevPage);
    }
  };

  const imageStyle: CSSProperties = {
    width: '100%',
    height: '500px',
    objectFit: 'cover',
    transition: 'opacity 1 ease-in-out',
    opacity: loading ? 0.5 : 1
  };

  const filenameStyle: CSSProperties = {
    fontSize: '0.8rem',
    color: 'gray'
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{t(`Verificação de Imagens`)}</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" justifyContent="center">
          <IconButton onClick={handlePrevious} disabled={currentPage <= 1}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Grid item xs>
            {items.map((item) => (
              <Grid container spacing={2} key={item.uploadId}>
                <Grid item xs={6}>
                  <img src={item.thermalUrl} alt={item.thermalFilename} style={imageStyle} />
                  <Typography variant="body2">{t("Nome do arquivo:")}</Typography>
                  <Typography style={filenameStyle}>{item.thermalFilename}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <img src={item.rgbUrl} alt={item.rgbFilename} style={imageStyle} />
                  <Typography variant="body2">{t("Nome do arquivo:")}</Typography>
                  <Typography style={filenameStyle}>{item.rgbFilename}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <IconButton onClick={handleNext} disabled={currentPage >= totalPages}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: "flex", width: "100%", padding: "0px 20px 24px 20px" }}>
        <Box sx={{ display: "flex", width: "30%", gap: 2 }}>
          <Button style={{ flex: '1 1 20%' }} label={t('Voltar')} onClick={onClose} />
          <Button style={{ flex: '1 1 20%' }} label={t('Avançar')} autoFocus onClick={onConfirm} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};


export default InspectionStatusModal;
