export const modalBackgroundStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
}

export const modalContentStyle: React.CSSProperties = {
  display: 'flex',
  gap: '20px',
  padding: '20px',
  background: '#fff',
  borderRadius: '8px',
  justifyContent: 'center',
}

export const modalImageStyle: React.CSSProperties = {
  width: '50%', // Cada imagem terá 50% da largura do modalContent
  height: 'auto', // Altura auto para manter a proporção da imagem
  objectFit: 'cover', // Isso vai cobrir o espaço disponível sem distorcer a imagem
  borderRadius: '4px', // Borda arredondada para as imagens
}

export const closeButtonStyle: React.CSSProperties = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  color: '#fff', // A cor do 'X', ajuste conforme necessário
  cursor: 'pointer',
  fontSize: '24px', // O tamanho do 'X', ajuste conforme necessário
}
