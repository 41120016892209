export class Registration {
  name = ''
  email = ''
  companyId = ''
  status = '1'
  accessLevel = '0'
  password = ''
}

export class RegistrationError {
  name = ''
  email = ''
  companyId = ''
  status = ''
  accessLevel = '0'
  password = ''
}
