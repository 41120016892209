export class Registration {
  email = '';
  password = '';
}

export class RegistrationError {
  email = '';
  password = '';
}

