import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '~/components/Button'
import ModalCustom from '~/components/ModalCustom'
import ToastNotification from '~/components/ToastNotification'
import ViewError from '~/components/ViewError'
import ViewLoading from '~/components/ViewLoading'
import { IBaseResponse } from '~/services/RequestService/types'
import { getAcessLevel, getUser, postUser, putUser } from '~/services/Users'
import { Registration, RegistrationError } from './types'

import { useAuth } from '~/contexts/Auth'
import BaseLayout from '~/Layouts/BaseLayout'
import { getCompanySimple } from '~/services/CompaniesAndBranches'
import { IUserAcessLevel } from '~/services/Users/types'
import handleResponse from '~/utils/responseEdit'
import ErrorToast from '~/utils/toastErrorCatch'
import { ICompaniesSimple } from '../CompaniesAndBranches/types'
import * as S from './styles'
import { registerValidation } from './validations'

export default function RegisterAndEditUser() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useParams()
  const { user } = useAuth()

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [registration, setRegistration] = useState<Registration>(new Registration())
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError())
  const [loadingData, setLoadingData] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [companies, setCompanies] = useState<ICompaniesSimple[]>([])

  useEffect(() => {
    if (token) {
      loadUser()
    }
  }, [])
  useEffect(() => {
    if (!registration.companyId && user?.accessLevel === 2) {
      setRegistration({ ...registration, companyId: user.companyId ?? '' })
    }
  }, [user, registration, setRegistration])

  const [accessLevels, setAccessLevels] = useState<IUserAcessLevel[]>([])

  useEffect(() => {
    const fetchAccessLevels = async () => {
      try {
        const levels = await getAcessLevel()
        const translatedLevels = translateAccessLevels(levels.data)
        setAccessLevels(translatedLevels)
      } catch (error) {
        return <ErrorToast message={t('Não foi possível buscar os niveis de acesso.')} />
      }
    }

    fetchAccessLevels()
  }, [])

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesData = await getCompanySimple({
          ConsiderUserCompany: true,
          OnlyHeadOffices: false,
        })
        setCompanies(companiesData.data)
      } catch (error) {
        return <ErrorToast message={t('Não foi possível buscar as empresas.')} />
      }
    }

    fetchCompanies()
  }, [])

  const loadUser = async () => {
    setLoadingData(true)
    try {
      const response = await getUser(token)
      if (response.success) {
        setRegistration({
          name: response.data.name,
          email: response.data.email,
          companyId: response.data.companyId,
          status: String(response.data.status),
          accessLevel: String(response.data.accessLevel),
          password: '',
        })
        setRequestSuccess(true)
      } else {
        setRequestSuccess(false)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t(response.message),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
      )
    }
    setLoadingData(false)
  }

  const RegisterAndEditUser = async () => {
    setLoading(true)

    let isEditing = false

    if (token) {
      isEditing = true
    }
    const validationResult = await registerValidation(registration, t, { isEditing })

    if (validationResult === true) {
      try {
        const body = {
          id: token ?? '',
          name: registration.name,
          email: registration.email,
          status: Number(registration.status),
          accessLevel: Number(registration.accessLevel),
          password: registration.password,
          companyId: registration.companyId,
        }

        let response: IBaseResponse<boolean>
        if (token) response = await putUser(body, token)
        else response = await postUser(body)

        handleResponse(response, token, t, navigate)
      } catch (error) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: token
            ? t('Não foi possível realizar a edição, tente novamente mais tarde.')
            : t('Não foi possível realizar o cadastro, tente novamente mais tarde.'),
        })
      }
    } else {
      // Se houver erros, atualizar o estado de erros do formulário
      setRegistrationError(validationResult)
      console.error(registrationError)
    }

    setLoading(false)
  }

  const translateAccessLevels = (levels: IUserAcessLevel[]): IUserAcessLevel[] => {
    return levels.map((level) => ({
      ...level,
      name: t(`AccessLevels.${level.name}`),
      description: t(`AccessLevelDescriptions.${level.description}`),
    }))
  }

  return (
    <BaseLayout title={token ? t('Editar Usuário') : t('Cadastrar Usuário')}>
      <S.FlexWrapper>
        {loadingData ? (
          <ViewLoading />
        ) : (token ? requestSuccess : true) ? (
          <S.ContainerForm>
            <S.TextForm>
              {t(
                "Para concluir o processo, precisamos que você informe corretamente os dados e depois clique em 'Salvar'. É importante revisar as informações antes de salvar para garantir que tudo esteja correto.",
              )}
            </S.TextForm>

            <S.ContainerInput>
              <TextField
                label={t('Nome*')}
                type="state"
                value={registration.name}
                onChange={(e) => setRegistration({ ...registration, name: e.target.value })}
                size="small"
                fullWidth
                error={!!registrationError.name}
                helperText={registrationError.name}
                onClick={() => setRegistrationError({ ...registrationError, name: '' })}
                style={{ marginTop: '24px' }}
              />

              <TextField
                label={t('E-mail*')}
                type="fantasyName"
                value={registration.email}
                onChange={(e) => setRegistration({ ...registration, email: e.target.value })}
                size="small"
                fullWidth
                error={!!registrationError.email}
                helperText={registrationError.email}
                onClick={() => setRegistrationError({ ...registrationError, email: '' })}
                style={{ marginTop: '24px' }}
              />

              <Autocomplete
                size="small"
                id="companies"
                options={companies}
                getOptionLabel={(option) => option.corporateName + ' - ' + option.fantasyName}
                value={
                  companies.find(
                    (company) =>
                      company.id === (user?.accessLevel === 2 ? user.companyId : registration.companyId),
                  ) ?? null
                }
                onChange={(_, newValue) => {
                  setRegistration({ ...registration, companyId: newValue ? newValue.id : '' })
                }}
                onClick={() => setRegistrationError({ ...registrationError, companyId: '' })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Empresas')}
                    variant="outlined"
                    disabled={user?.accessLevel === 2}
                    error={!!registrationError.companyId}
                    helperText={registrationError.companyId || ' '}
                  />
                )}
                clearOnEscape
                disabled={user?.accessLevel === 2}
                sx={{ marginTop: '24px' }}
              />

              <S.ContainerDoubleInput style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <FormControl style={{ display: 'flex', width: '100%', marginRight: '24px' }}>
                  <InputLabel id="demo-simple-select-helper-label">{t('Status*')}</InputLabel>
                  <Select
                    size="small"
                    label={t('Status*')}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={registration.status}
                    onChange={(e) =>
                      setRegistration({ ...registration, status: String(e.target.value) })
                    }
                    onClick={() => setRegistrationError({ ...registrationError, status: '' })}
                    error={!!registrationError.status}
                    disabled={user?.id === token}
                  >
                    <MenuItem value={'1'}>{t('Ativo')}</MenuItem>
                    <MenuItem value={'2'}>{t('Inativo')}</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: '#d32f2f' }}>
                    {registrationError.status}
                  </FormHelperText>
                </FormControl>

                <FormControl style={{ display: 'flex', width: '100%' }}>
                  {accessLevels && (
                    <Autocomplete
                      size="small"
                      id="autocomplete-access-level"
                      options={accessLevels}
                      getOptionLabel={(option) => option.name}
                      value={
                        accessLevels.find(
                          (level) => Number(level.id) === Number(registration.accessLevel),
                        ) ?? null
                      }
                      onChange={(event, newValue) => {
                        setRegistration({ ...registration, accessLevel: newValue ? newValue.id : '' })
                      }}
                      disabled={user?.id === token}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('Nível de acesso*')}
                          variant="outlined"
                          disabled={user?.id === token}
                        />
                      )}
                    />
                  )}
                </FormControl>
              </S.ContainerDoubleInput>

              {!token && (
                <TextField
                  type={'password'}
                  label={t('Senha*')}
                  size="small"
                  value={registration.password}
                  onChange={(e) => setRegistration({ ...registration, password: e.target.value })}
                  error={!!registrationError.password}
                  helperText={t(registrationError.password)}
                  onClick={() => setRegistrationError({ ...registrationError, password: '' })}
                  sx={{
                    marginTop: 2,
                  }}
                  fullWidth
                  style={{ marginTop: '24px' }}
                />
              )}
            </S.ContainerInput>

            {!token && (
              <S.TextPassword>
                {t(
                  'Para uma maior segurança, a senha deve ter ao menos 8 caracteres, letra maiúscula, números e símbolos',
                )}
              </S.TextPassword>
            )}

            <S.ContainerButton>
              <Button
                type="button"
                onClick={() => setOpenConfirmationModal(true)}
                label={t('Cancelar')}
                style={{ marginTop: 0 }}
              />
              <Button
                type="button"
                onClick={RegisterAndEditUser}
                loading={loading}
                label={t('Salvar')}
                style={{ marginTop: 0, marginLeft: '40px' }}
              />
            </S.ContainerButton>
          </S.ContainerForm>
        ) : (
          <ViewError message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
        )}

        <ModalCustom
          open={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
          onClick={() => {
            setOpenConfirmationModal(false)
            navigate(-1)
          }}
          title={token ? t('Cancelar edição') : t('Cancelar cadastro')}
          description={
            token
              ? t(
                  'Tem certeza de que deseja cancelar a edição? Após a confirmação, não há como voltar atrás.',
                )
              : t(
                  'Tem certeza de que deseja cancelar o cadastro? Após a confirmação, não há como voltar atrás.',
                )
          }
          confirmationButtonText={t('Sim')}
          textDeclineButton={t('Não')}
        />
      </S.FlexWrapper>
    </BaseLayout>
  )
}
