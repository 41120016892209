import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import NoImagePlaceholder from '~/assets/images/no-image-placeholder.png'
import Button from '~/components/Button'
import {
  dialogTitleStyle,
  dialogTitleTextStyle,
  imagesContainerStyle,
  imageStyle,
  infoStyle,
  valueStyle,
} from './styles'

interface InspectionStatusModalProps {
  open: boolean
  onClose: () => void
  thermalImageUrl: string
  rgbImageUrl: string
  complementaryImageUrls?: any[]
  handlePrevious: () => void
  handleNext: () => void
  anomalyCode: number
  anomalyName?: string
  anomalyStringName?: string
  anomalyModuleNumber?: string
}

const AnomalyImagesModal: React.FC<InspectionStatusModalProps> = ({
  open,
  onClose,
  thermalImageUrl,
  rgbImageUrl,
  complementaryImageUrls,
  handlePrevious,
  handleNext,
  anomalyCode,
  anomalyName,
  anomalyModuleNumber,
  anomalyStringName,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" sx={{ width: '100%' }}>
      <DialogTitle style={dialogTitleStyle}>
        <span style={dialogTitleTextStyle}>{t(`Verificação de Imagens de Anomalias`)}</span>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" justifyContent="center">
          <IconButton onClick={handlePrevious}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Grid item xs>
            <div style={imagesContainerStyle}>
              <img
                src={thermalImageUrl !== '' ? thermalImageUrl : NoImagePlaceholder}
                alt={'Imagem Térmica'}
                style={imageStyle}
              />
              <img
                src={rgbImageUrl !== '' ? rgbImageUrl : NoImagePlaceholder}
                alt={'Imagem RGB'}
                style={imageStyle}
              />
              {complementaryImageUrls &&
                complementaryImageUrls.map((image: any) => (
                  <img
                    key={image.fileId}
                    src={image.url !== '' ? image.url : NoImagePlaceholder}
                    alt={'Imagem Complementar'}
                    style={imageStyle}
                  />
                ))}
            </div>
            <Grid container spacing={2} style={infoStyle}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>{t('Código da Anomalia')}: </strong>
                  <span style={valueStyle}>#{anomalyCode}</span>
                </Typography>
              </Grid>
              {anomalyName && (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>{t('Nome da Anomalia')}: </strong>
                    <span style={valueStyle}>{t(anomalyName)}</span>
                  </Typography>
                </Grid>
              )}
              {anomalyModuleNumber && (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>{t('Número do Módulo')}: </strong>
                    <span style={valueStyle}>{anomalyModuleNumber}</span>
                  </Typography>
                </Grid>
              )}
              {anomalyStringName && (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>{t('Nome da String')}: </strong>
                    <span style={valueStyle}>{anomalyStringName}</span>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <IconButton onClick={handleNext}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', width: '100%', padding: '0px 24px 16px 24px' }}>
        <Box sx={{ display: 'flex', width: '15%', gap: 2 }}>
          <Button style={{ flex: '1 1 10%' }} label={t('Fechar')} onClick={onClose} />
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default AnomalyImagesModal
