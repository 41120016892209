import React from 'react'

import { useAuth } from '~/contexts/Auth'

import Sidebar from '../Sidebar'
import * as S from './styles'

export const Container: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth()
  return (
    <S.Container>
      {isAuthenticated && <Sidebar />}
      <S.Body signed={isAuthenticated}>
        {children}
      </S.Body>
    </S.Container>
  )
}
