import React, { useEffect, useRef, useState } from "react";
import { Coordinates, EtapaProps, ImageGroup } from "../types";
import { Box, Button, IconButton, LinearProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactMapGL, { MapRef, Marker, NavigationControl } from 'react-map-gl'
import { StyledTableRow } from "~/utils";
import exifr from 'exifr'
import ToastNotification from "~/components/ToastNotification";
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { postInspectionsImages, postUpdateStatus } from "~/services/Inspections";
import PlaceIcon from '@mui/icons-material/Place';
import { IResponseInspectionUpload } from "~/services/Inspections/types";
import { useNavigate } from "react-router-dom";
import html2canvas from 'html2canvas';
import LoadingComponent from "~/components/Loading/Loading";
import ErrorToast from "~/utils/toastErrorCatch";



const Etapa3View: React.FC<EtapaProps & {
  inspecId: string
  dadosInspecao?: IResponseInspectionUpload
}> = ({ handleBack, handleNext, inspecId, dadosInspecao }) => {
  const [mapData, setMapData] = useState({
    longitude: -100,
    latitude: 40,
    zoom: 3,
    preserveDrawingBuffer: true
  });


  const [imageGroups, setImageGroups] = useState<{ coordinates: Coordinates, images: File[], directoryKey: string }[]>([]);
  const { t } = useTranslation();
  const [groupIndex, setGroupIndex] = useState(0);
  const [sendStatus, setSendStatus] = useState<Map<string, string>>(() => {
    const initialStatus = new Map<string, string>();
    imageGroups.forEach(group => {
      const key = `${group.coordinates.latitude}_${group.coordinates.longitude}`;
      initialStatus.set(key, "Aguardando");
    });
    return initialStatus;
  });

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);

  const [totalPairsSent, setTotalPairsSent] = useState(0);
  const [activeRequests, setActiveRequests] = useState(0);
  const maxConcurrentRequests = 3;
  const [sendQueue, setSendQueue] = useState<ImageGroup[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [concludedDisabled, setConcludedDisabled] = useState(true);

  const totalImages = imageGroups.reduce((total, group) => total + group.images.length, 0);
  const sentImages = totalPairsSent * 2;
  const sendProgress = totalImages > 0 ? (sentImages / totalImages) * 100 : 0;

  const [textLoading, setTextLoading] = useState('');


  useEffect(() => {
    if (imageGroups.length > 0) {
      const firstGroup = imageGroups[0].coordinates;
      setMapData({
        longitude: firstGroup.longitude,
        latitude: firstGroup.latitude,
        zoom: 30,
        preserveDrawingBuffer: true
      });
    }
  }, [imageGroups]);

  const mapRef = useRef<MapRef | null>(null);

  const handleDownloadScreenshot = () => {

    if (!mapRef.current) {
      return;
    }
    setIsLoading(true);
    setTextLoading("Capturando Imagem...")


    const mapElement = mapRef.current.getMap().getContainer();

    html2canvas(mapElement, {
      useCORS: true,
      logging: true,
      onclone: (document) => {
        ;
      },
    }).then(canvas => {
      try {
        const dataURL = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'map-screenshot.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Falha ao converter o canvas para URL:', error);
      }
    }).catch(error => {
      console.error('Erro ao capturar screenshot:', error);
    }).finally(() => {
      setIsLoading(false);
      setTextLoading("")
    });;

  };

  const headCells = [
    {
      label: t('Arquivos'),
      value: 'siteName',
    },
    {
      label: t('Status'),
      value: 'inspectedPower',
    },
    {
      label: t('Ações'),
      value: 'estimatedYearlyPowerLossInMegawatt',
    },

  ]

  const handleImagesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    setIsLoading(true);
    setTextLoading("Carregando Imagens...")

    const sortedFiles = Array.from(files).filter(file => file.type.startsWith('image/')).sort((a, b) => a.name.localeCompare(b.name));
    let newGroups = [];

    for (let i = 0; i < sortedFiles.length; i += 2) {
      const file1 = sortedFiles[i];
      const file2 = sortedFiles[i + 1] || null;

      if (file2) {
        const gpsData = await exifr.gps(file1);
        if (!gpsData?.latitude || !gpsData?.longitude) continue;

        const coordinates = { latitude: gpsData.latitude, longitude: gpsData.longitude };
        newGroups.push({
          directoryKey: `group-${groupIndex + newGroups.length}`,
          images: [file1, file2],
          coordinates
        });
      }
    }

    setImageGroups([...imageGroups, ...newGroups]);
    setGroupIndex(prevIndex => prevIndex + newGroups.length);
    setIsLoading(false);
    setTextLoading("")

    event.target.value = '';
  };


  const [unpairedImage, setUnpairedImage] = useState<File | null>(null);

  const handleDirectoryChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    setIsLoading(true);
    setTextLoading("Carregando Imagens...")

    const sortedFiles = Array.from(files).filter(file => file.type.startsWith('image/'))
      .sort((a, b) => {
        if (a.webkitRelativePath < b.webkitRelativePath) return -1;
        if (a.webkitRelativePath > b.webkitRelativePath) return 1;
        return a.name.localeCompare(b.name);
      });

    let newUnpaired = unpairedImage ? [unpairedImage, ...sortedFiles] : sortedFiles;
    let newGroups: ImageGroup[] = [];
    let leftoverImage = null;

    for (let i = 0; i < newUnpaired.length; i += 2) {
      const file1 = newUnpaired[i];
      const file2 = newUnpaired[i + 1] || null;

      if (file1 && file2) {
        const gpsData1 = await exifr.gps(file1);
        const gpsData2 = await exifr.gps(file2);

        if (gpsData1 && gpsData2) {
          const coordinates = {
            latitude: (gpsData1.latitude + gpsData2.latitude) / 2,
            longitude: (gpsData1.longitude + gpsData2.longitude) / 2
          };

          const alreadyUploaded = dadosInspecao?.some(upload => {
            const latDiff = Math.abs(upload.latitude - coordinates.latitude) < 0.0001;
            const lonDiff = Math.abs(upload.longitude - coordinates.longitude) < 0.0001;
            const sizeMatch1 = upload.rgbSize === file1.size || upload.thermalSize === file1.size;
            const sizeMatch2 = upload.rgbSize === file2.size || upload.thermalSize === file2.size;
            return latDiff && lonDiff && (sizeMatch1 || sizeMatch2);
          });

          const alreadyInGroups = imageGroups.some(group => group.images.some(img => img.name === file1.name || img.name === file2.name));

          if (alreadyUploaded || alreadyInGroups) {
            ToastNotification({
              id: 'error',
              type: 'error',
              message: `${t('Imagem duplicada encontrada:')} ${file1.name} ${t('ou')} ${file2.name}`,
            });
            continue;
          }
          newGroups.push({
            directoryKey: `group-${groupIndex + newGroups.length}`,
            images: [file1, file2],
            coordinates
          });
        } else {
          leftoverImage = file2;
        }
      } else if (file1) {
        leftoverImage = file1;
      }
    }

    setImageGroups(prevGroups => [...prevGroups, ...newGroups]);
    setUnpairedImage(leftoverImage);
    setGroupIndex(prevIndex => prevIndex + newGroups.length);
    setIsLoading(false);
    setTextLoading("")

    event.target.value = '';
  };

  const handleDeleteImage = (groupKey: string) => {
    setImageGroups(prevGroups => prevGroups.filter(group => group.directoryKey !== groupKey));
    setSendStatus(prevStatus => {
      if (prevStatus.has(groupKey)) {
        const newStatus = new Map(prevStatus);
        newStatus.delete(groupKey);
        return newStatus;
      }
      return prevStatus;
    });
  };

  const updateStage = async () => {
    try {
      await postUpdateStatus({
        inspectionId: inspecId,
        isUploadingNewImages: true
      })
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível avançar a etapa, tente novamente mais tarde.')} />
      )
    }
  }
  useEffect(() => {
    if (sendProgress === 100) {
      updateStage();
      setConcludedDisabled(false)
    } else {
      setConcludedDisabled(true)
    }
  }, [sendProgress]);

  useEffect(() => {
    if (imageGroups.length === 1) {
      const firstGroup = imageGroups[0].coordinates;
      setMapData({
        longitude: firstGroup.longitude,
        latitude: firstGroup.latitude,
        zoom: 25,
        preserveDrawingBuffer: true
      });
    } else if (imageGroups.length > 1) {
      const firstGroup = imageGroups[0].coordinates;
      setMapData({
        longitude: firstGroup.longitude,
        latitude: firstGroup.latitude,
        zoom: 17,
        preserveDrawingBuffer: true

      });
    }
  }, [imageGroups]);


  useEffect(() => {
    const newStatus = new Map(sendStatus);

    imageGroups.forEach(group => {
      const key = group.directoryKey;
      if (!newStatus.has(key)) {
        newStatus.set(key, "Aguardando");
      }
    });

    newStatus.forEach((_, key) => {
      if (!imageGroups.some(group => group.directoryKey === key)) {
        newStatus.delete(key);
      }
    });

    setSendStatus(newStatus);
  }, [imageGroups]);



  const prepareAndSendImage = async (group: ImageGroup, inspecId: string) => {
    const key = group.directoryKey;
    let thermalImage: File | null = null;
    let rgbImage: File | null = null;

    group.images.forEach(image => {
      // Verifica se thermalImage ainda não foi atribuída ou se a imagem atual é menor que a imagem térmica atribuída
      if (thermalImage === null || image.size < thermalImage.size) {
        // Se já houver uma imagem térmica atribuída, reatribui essa imagem como a imagem RGB
        if (thermalImage !== null) {
          rgbImage = thermalImage;
        }
        // Atribui a imagem atual como a imagem térmica
        thermalImage = image;
      } else if (rgbImage === null || image.size > rgbImage.size) {
        // Se a imagem RGB ainda não foi atribuída ou a imagem atual é maior que a imagem RGB atribuída
        rgbImage = image;
      }
    });

    if (thermalImage && rgbImage) {
      const formData = new FormData();
      formData.append('InspectionId', inspecId);
      formData.append('ThermalFile', thermalImage);
      formData.append('RGBFile', rgbImage);
      formData.append('ThermalFilePath', (thermalImage as any).webkitRelativePath);
      formData.append('RGBFilePath', (rgbImage as any).webkitRelativePath);

      try {
        setSendStatus(prev => new Map(prev).set(group.directoryKey, "Enviando"));
        const response = await postInspectionsImages(formData);
        setTotalPairsSent(prevTotal => prevTotal + 1);
        setSendStatus(prev => new Map(prev).set(group.directoryKey, "Enviado"));
      } catch (error) {
        setSendStatus(prev => new Map(prev).set(key, "Erro"));
      } finally {
        setActiveRequests(current => current - 1);

      }
    } else {
      setActiveRequests(current => current - 1);

    }
  };

  const enqueueSendImage = (group: ImageGroup) => {
    const currentStatus = sendStatus.get(group.directoryKey);
    if (currentStatus !== "Enviado") {
      setSendQueue(prevQueue => [...prevQueue, group]);
      if (!isSending) {
        sendImages();
      }
    }
  };


  const sendImages = async () => {
    setIsSending(true);

    while (activeRequests < maxConcurrentRequests && sendQueue.length > 0) {
      const groupsToSend = sendQueue.splice(0, maxConcurrentRequests - activeRequests);
      setActiveRequests(current => current + groupsToSend.length);

      await Promise.all(groupsToSend.map(group => prepareAndSendImage(group, inspecId)));
      setActiveRequests(current => current - groupsToSend.length);
    }

    setIsSending(false);
  };

  useEffect(() => {
    if (!isSending && sendQueue.length > 0) {
      sendImages();
    }
  }, [sendQueue, isSending]);

  return (
    <>
      <LoadingComponent loading={isLoading} text={textLoading ?? 'Carregando Imagens...'} />
      <Stack
        sx={{
          margin: '24px 5%',
          display: 'flex',
          minHeight: '60vh',
          flexDirection: 'column',
          gap: '30px',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '16px',
          border: '1px solid #C4C4C4'
        }}
      >
        <Stack direction={"row"} sx={{ flexGrow: 1, gap: "30px", height: "70vh" }}>
          <Stack flex={1} sx={{ flexBasis: '45%', height: "auto", backgroundColor: 'transparent', }}>
            <ReactMapGL
              {...mapData}
              onMove={(evt) => {
                setMapData(prevData => ({
                  ...prevData,
                  longitude: evt.viewState.longitude,
                  latitude: evt.viewState.latitude,
                  zoom: evt.viewState.zoom
                }));
              }}
              style={{
                width: '100%', height: '100%', position: 'relative',

              }}
              mapStyle={'mapbox://styles/mapbox/satellite-v9'}
              mapboxAccessToken="pk.eyJ1IjoiYW5kcmV5Y2FzZXR0YSIsImEiOiJja2c3MHN1YnowMnQ4MnZvOXRhcWFyZnp2In0.DaCc8a68xzWJpBK3cdU8jw"
              ref={mapRef}


            >
              {imageGroups.map(group => (
                <Marker key={`${group.coordinates.latitude}_${group.coordinates.longitude}`}
                  longitude={group.coordinates.longitude}
                  latitude={group.coordinates.latitude}
                  style={{ zIndex: 9999999999 }}
                >

                  <PlaceIcon style={{ fontSize: '15px', color: 'red', zIndex: 9999999999 }} />
                </Marker>
              ))}
              <NavigationControl style={{ right: 10, top: 10, zIndex: 999999999999 }} />
            </ReactMapGL>

          </Stack>

          <Stack flex={2} sx={{
            flexBasis: '55%',
            border: '1px solid #C4C4C4'
          }} >
            <TableContainer sx={{ height: "100%" }} >
              <Table sx={{ width: "100%" }} aria-labelledby="tableTitle" size="medium">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell, i) => (
                      <TableCell
                        key={headCell.value}
                        align={i === 0 || i === 4 ? 'left' : 'right'}
                        padding="normal"

                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: '#dbe1ee',
                          borderStartEndRadius: i === headCells.length - 1 ? '4px' : '0',
                          borderStartStartRadius: i === 0 ? '4px' : '0',
                        }}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {imageGroups.length > 0 ? imageGroups.map((group, index) => {
                    const key = group.directoryKey;
                    const currentStatus = sendStatus.get(key) ?? "Aguardando";

                    return (
                      <StyledTableRow hover tabIndex={-1} key={key} sx={{ cursor: 'pointer' }}>
                        <TableCell align="left">
                          {group.images.map((file, idx) => (
                            <Stack key={idx} direction="row" alignItems="center" spacing={1}>
                              <PhotoOutlinedIcon />
                              <Typography>
                                {`${file.webkitRelativePath || file.name}`}
                              </Typography>
                            </Stack>
                          ))}
                        </TableCell>
                        <TableCell align="center">
                          <Stack direction="row" sx={{ alignItems: 'center', gap: 1 }}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Typography variant="body2" sx={{ textAlign: 'right' }}>{currentStatus}</Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell align="right">
                          {group.images.length === 2 && (currentStatus === "Aguardando" || currentStatus === "Erro") && (
                            <IconButton
                              onClick={() => prepareAndSendImage(group, inspecId)}
                              style={{ color: "#0C4A7D" }}
                            >
                              <RefreshIcon />
                            </IconButton>
                          )}
                          {(currentStatus == "Aguardando" || currentStatus == "Erro") && (
                            <IconButton onClick={() => handleDeleteImage(key)} color="error">
                              <DeleteIcon />
                            </IconButton>
                          )}

                        </TableCell>
                      </StyledTableRow>
                    );
                  }) : (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center">
                        {t('Nenhuma imagem encontrada. Por favor, carregue uma pasta de imagens.')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

              </Table>
            </TableContainer>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: "0.25rem", borderTop: '2px solid #e0e0e0', mt: 2 }}>
              <Stack sx={{ width: '25%', marginLeft: "0.50rem", borderRight: '1px solid #e0e0e0', pr: 2, alignItems: "center" }}>
                <Typography variant="subtitle2">{t("Imagens Selecionadas")}</Typography>
                <Typography variant="subtitle2">
                  {imageGroups.reduce((total, group) => total + group.images.length, 0)}
                </Typography>
              </Stack>
              <Stack sx={{ width: '25%', marginLeft: "0.50rem", borderRight: '1px solid #e0e0e0', pr: 2, alignItems: "center" }}>
                <Typography variant="subtitle2">{t("Imagens Enviadas")}</Typography>
                <Typography variant="subtitle2">{totalPairsSent * 2}</Typography>
              </Stack>
              <Stack sx={{ width: '25%', marginLeft: "0.50rem", borderRight: '1px solid #e0e0e0', pr: 2, alignItems: "center" }}>
                <Typography variant="subtitle2">{t('Progresso Total')}</Typography>
                <Stack direction="row" sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                  <LinearProgress variant="determinate" value={sendProgress} sx={{ width: '70%', height: 10, borderRadius: 5 }} />
                  <Typography variant="subtitle2">{sendProgress.toFixed(0)}%</Typography>
                </Stack>
              </Stack>
              <Stack sx={{ width: '25%', alignItems: 'center' }}>
                <Button variant="contained" onClick={() => imageGroups.forEach(group => enqueueSendImage(group))}
                  disabled={
                    imageGroups.every(group => sendStatus.get(group.directoryKey) === "Enviado") ||
                    imageGroups.some(group => sendStatus.get(group.directoryKey) === "Enviando")
                  }
                  sx={{
                    backgroundColor: '#3F8A05',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#48a005'
                    },
                    width: "75%"
                  }}>
                  {t('Enviar')}
                </Button>
              </Stack>
            </Stack>

          </Stack>


        </Stack >

        <Stack
          width={'100%'}
          gap={2}
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Button variant="outlined" onClick={handleBack}>
            {t('Voltar')}
          </Button>
          <Stack
            width={'100%'}
            gap={2}
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Box>
              <Tooltip title={t('Ajuste o mapa para de uma forma, que capture todos os marcadores.')}>
                <IconButton color="primary">
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Button onClick={() => {
                setIsLoading(true);
                handleDownloadScreenshot();
              }} variant="contained" color="primary">
                Screenshot
              </Button>

            </Box>

            <input
              type="file"
              multiple
              directory=""
              webkitdirectory=""
              onChange={handleDirectoryChange}
              accept="image/*"
              style={{ display: 'none' }}
              id="file-directory"
            />
            <label htmlFor="file-directory" >
              <Button variant="contained" component="span" fullWidth>
                {t('Selecionar Pasta')}
              </Button>
            </label>

            <input
              type="file"
              multiple
              onChange={handleImagesChange}
              accept="image/*"
              style={{ display: 'none' }}
              id="file-input"
            />
            <label htmlFor="file-input">
              <Button variant="contained" component="span" fullWidth>
                {t('Selecionar Imagens')}
              </Button>
            </label>

            <Button variant="contained"
              disabled={concludedDisabled}
              onClick={() => navigate(`/sites/inspections`)} sx={{
                backgroundColor: '#0C4A7D',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#084a5d'
                }
              }}>
              {t('Concluir')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Etapa3View;
