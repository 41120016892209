import {
  Edit as MUIEdit,
  Delete as MUIDelete,
  Business as MUIBusiness,
  BarChart as MUIBarChart,
  LinkedCamera as MUILinkedCamera,
  Error as MUIError,
  Description as MUIDescription,
  NoteAdd as MUINoteAdd,
  NextPlan as MUINextPlan
} from '@mui/icons-material'
import { Box } from '@mui/material'
import type { CustomMaterialIcons, IIconColorsProps } from './types'
import * as S from './styles'

const defaultColor = (props: CustomMaterialIcons, color: IIconColorsProps = 'primary') => {
  return props.color ?? color
}

const Edit = (props: CustomMaterialIcons) => {
  return <MUIEdit color={defaultColor(props)} {...props} />
}

const LinkedCamera = (props: CustomMaterialIcons) => {
  return <MUILinkedCamera color={defaultColor(props)} {...props} />
}

const Delete = (props: CustomMaterialIcons) => {
  return <MUIDelete color={defaultColor(props, 'error')} {...props} />
}

const Business = (props: CustomMaterialIcons) => {
  return <MUIBusiness color={defaultColor(props)} {...props} />
}

const BarChart = (props: CustomMaterialIcons) => {
  return <MUIBarChart color={defaultColor(props)} {...props} />
}

const ErrorName = (props: CustomMaterialIcons) => {
  return <MUIError color={defaultColor(props)} {...props} />
}

const Description = (props: CustomMaterialIcons) => {
  return <MUIDescription color={defaultColor(props)} {...props} />
}
const NoteAdd = (props: CustomMaterialIcons) => {
  return <MUINoteAdd color={defaultColor(props)} {...props} />
}
const NextPlan = (props: CustomMaterialIcons) => {
  return <MUINextPlan color={defaultColor(props)} {...props} />
}

export const materialIconList = {
  Edit,
  LinkedCamera,
  Delete,
  Business,
  BarChart,
  ErrorName,
  Description,
  NoteAdd,
  NextPlan
}

export const MaterialIcon = (props: CustomMaterialIcons) => {
  const { name, title } = props

  const SelectedIcon = materialIconList[name]

  return (
    <S.IconWrapper title={title}>
      <Box>
        <SelectedIcon {...props} />
      </Box>
    </S.IconWrapper>
  )
}
