import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const Container = styled.div`
  margin: 0 10%;
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`
export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 10px;

  width: 100%;

  .MuiAutocomplete-root {
    background-color: ${theme.colors.white};
  }
`

export const ContainerSearch = styled.div`
  width: 100%;
`
export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  padding: 0 10%;
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    border: none;
    background: none;

    width: auto;
    height: auto;
  }

  .i {
    color: red;
    font-size: 24px;

    &:hover {
      color: ${theme.colors.black};
    }
  }

  .iconBlocked {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }

  .iconDisable {
    opacity: 0.5;
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
  }

  .iconDisableBlocked {
    opacity: 0.5;
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }
`
