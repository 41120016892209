import * as yup from 'yup';

import { verifyClassEmpty } from '~/utils/ClassEmpty';
import { genericValidation } from '~/utils/ValidationGenerics';

import { Registration, RegistrationError } from './types';

export const signInValidation = async (object: Registration) : Promise<true|RegistrationError> => {
  
  const schemaRegister = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required()
  });

  const mountError = await genericValidation<Registration, RegistrationError>(schemaRegister, object, new RegistrationError());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
