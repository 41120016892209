import styled from 'styled-components'
import { theme } from '~/styles/theme'

export const Container = styled.div`
  width: 100%;

  .MuiOutlinedInput-root {
    background-color: ${theme.colors.white};
  }
`
