import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

export interface OverlayProps {
  loading: boolean
}
const Overlay = styled.div<OverlayProps>`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

const Text = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  color: white;
  text-align: center;
`
interface LoadingComponentProps {
  loading: boolean
  text?: string 
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ loading, text }) => (
  <Overlay loading={loading}>
    <CircularProgress style={{ color: 'white' }} />
    {text && <Text>{text}</Text>}
  </Overlay>
)

export default LoadingComponent
