export interface IInspection {
  siteId: string
  siteName: string | null
  inspectionId: string
  inspectionDate: string
  description: string | null
  lastChange: string
  uploadQuantity: number | null
  status: EInspectionStatus
  companyName: string | null
  companyPhone: string | null
  companyContactName: string | null
  companyEmail: string | null
  thermographer: string | null
  assistants: string | null
  uas: string | null
  payload: string | null
  humidity: string | null
  windSpeed: string | null
  outdoorTemperature: string | null
  moduleTemperature: string | null
  cloudCoverage: string | null
  numberOfModules: string | null
  emissivity: string | null
  irradiance: string | null
  reflectedTemperature: string | null
  totalPower: string | null
  companyId: string | null
  recommendations: string | null
}

export enum EInspectionStatus {
  Created = 0,
  ImagesUploaded = 1,
  ProcessingImages = 2,
  ImagesProcessed = 3,
  Complete = 4
}

export interface RequestInspections {
  SiteId?: string
  InspectionId?: string
  FromDate?: string
  ToDate?: string
  Description?: string
  Status?: number
  PageNumber?: number
  PageSize?: number
}
export interface RequestInspectionsId {
  InspectionId: string
}

export interface IRequestInspectionsSimple {
  SiteId: string
  ShowOnlyCompleted: boolean
}

export interface IRequestInspectionsUpload {
  InspectionId: string
}

export interface InspectionData {
  siteId?: string
  date: string
  description?: string
  companyName?: string
  companyContactName?: string
  companyEmail?: string
  companyPhone?: string
  thermographer?: string
  assistants?: string
  uas?: string
  payload?: string
  humidity?: string
  windSpeed?: string
  outdoorTemperature?: string
  moduleTemperature?: string
  cloudCoverage?: string
  numberOfModules?: string
  emissivity?: string
  irradiance?: string
  reflectedTemperature?: string
  recommendations?: string
  id?: string
}

export interface IInspectionPatch {
  date: string
  id: string
  description?: string
  companyName?: string
  companyContactName?: string
  companyEmail?: string
  companyPhone?: string
  thermographer?: string
  assistants?: string
  uas?: string
  payload?: string
  humidity?: string
  windSpeed?: string
  outdoorTemperature?: string
  moduleTemperature?: string
  cloudCoverage?: string
  numberOfModules?: string
  emissivity?: string
  irradiance?: string
  reflectedTemperature?: string
  recommendations?: string
}

interface IInspectionSimpleResponse {
  id: string
  dateAndDescription: string
}

export type Data = IInspectionSimpleResponse[]


interface IInspectionUploadResponse {
  id: string
  rgbFilename?: string
  rgbSize: number
  rgbFileClientPath?: string
  thermalFilename?: string
  thermalFileClientPath?: string
  thermalSize: number
  latitude: number
  longitude: number
  status: EInspectionStatus
}

export interface IAllStatusInspection {
  id: number
  name?: string
  description?: string
}

export interface IResponseStatusInspection {
  OptionWithDescriptionDto: IAllStatusInspection[]
}

export type IResponseInspectionUpload = IInspectionUploadResponse[]

export interface IRequestSubparkAreas {
  SubparkId: string
  GetModules: boolean
}

export interface IRequestUpdateStatus {
  inspectionId: string
  isUploadingNewImages: boolean
}

export interface IRequestInspectionImagesExample {
  InspectionId: string
  PageNumber: number
  PageSize: number
}

export interface IItemsImagesExample {
  uploadId: string
  rgbUrl?: string
  thermalUrl?: string
  rgbFilename?: string
  thermalFilename?: string
}

export interface IResponseInspectionImagesExample {
  items: IItemsImagesExample[]
  currentPage: number
  totalPages: number
}
