import React from 'react'

import { ButtonProps } from './types'
import Load from '~/assets/images/load.gif'

import * as S from './styles'

const Button: React.FC<ButtonProps> = (props) => {
  const { loading, onClick, type, iconStart, label, style } = props

  return (
    <S.ButtonStyled type={type ?? 'button'} onClick={onClick} disabled={loading} style={style}>
      {loading ? (
        <img width="24px" style={{ margin: 'auto' }} height="20px" src={Load} alt="Loading" />
      ) : (
        <>
          {iconStart && <div style={{ marginRight: '8px' }}>{iconStart}</div>}
          <div>
            <h2 className="label">{label}</h2>
          </div>
        </>
      )}
    </S.ButtonStyled>
  )
}

export default Button
