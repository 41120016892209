// ImageModal.tsx
import React from 'react'
import { modalBackgroundStyle, modalContentStyle, modalImageStyle, closeButtonStyle } from './styles'

interface ImageModalProps {
  isOpen: boolean
  onClose: () => void
  thermalImageUrl?: string
  rgbImageUrl?: string
}

const ImageModal: React.FC<ImageModalProps> = ({ isOpen, onClose, thermalImageUrl, rgbImageUrl }) => {
  if (!isOpen) return null

  return (
    <div style={modalBackgroundStyle}>
      <button
        style={{
          ...modalBackgroundStyle,
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: 0,
        }}
        onClick={onClose}
        aria-label="Fechar modal"
      />
      <div style={modalContentStyle}>
        <button style={closeButtonStyle} onClick={onClose}>
          &times;
        </button>
        {thermalImageUrl && <img alt="Imagem Térmica" src={thermalImageUrl} style={modalImageStyle} />}
        {rgbImageUrl && <img alt="Imagem RGB" src={rgbImageUrl} style={modalImageStyle} />}
      </div>
    </div>
  )
}

export default ImageModal
