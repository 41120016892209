import React, { createContext, useEffect, useState, useContext } from 'react'

import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'
import { USER_GET } from '~/config/constants'
import { IUser } from '../Auth/types'
import { getLocalStorage } from '../../utils/StorageLocal'
import Logger from '~/utils/Logger'
import { LogsContextProps, ILogs } from './types'
import { PARAMS_SERVICE_URL_LOGS } from '~/config/constants'

export const LogsContext = createContext<LogsContextProps>({} as LogsContextProps)

export const LogsProvider: React.FC = ({ children }) => {
  const hubConnectionBuilder = new HubConnectionBuilder()
  const [connection, setConnection] = useState<HubConnection>()
  const [logs, setLogs] = useState<ILogs | null>()
  const [count, setCount] = useState(3)

  useEffect(() => {
    if (connection) {
      startConnection()
    }
  }, [connection])

  const handleRefreshToken = async (attempts = 3) => {
    Logger('attempts Logs', attempts)
    if (attempts <= 0) return
  }

  const initSocketConnection = async () => {
    try {
      const storage = await getLocalStorage<IUser>(USER_GET)
      if (storage) {
        const connect = hubConnectionBuilder
          .withUrl(`${PARAMS_SERVICE_URL_LOGS}`, {
            accessTokenFactory: () => storage.accessToken,
          })
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build()

        setConnection(connect)
      }
    } catch (error) {
      Logger('Error initSocketConnection Logs', error)
    }
  }

  const startConnection = () => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('LogsReceived', (data: ILogs) => {
            setLogs(data)
          })
        })
        .catch(() => {
          Logger('CATCH State Logs', connection.state)
          if (connection.state === HubConnectionState.Disconnected) {
            Logger('Token expirado Logs', count)
            handleRefreshToken(count - 1)
            setCount((oldValue) => oldValue - 1)
          }
        })
    }
  }

  const stopConnectionSocket = () => {
    Logger('stopConnectionSocket Logs')
    try {
      if (connection) {
        connection.stop()
      }
      Logger('stopConnectionSocket Logs', PARAMS_SERVICE_URL_LOGS)
    } catch (error) {
      Logger('Error stopConnectionSocket Logs', error)
    }
  }

  const resetLogs = () => {
    setLogs(null)
  }

  return (
    <LogsContext.Provider
      value={{
        initSocketConnection,
        stopConnectionSocket,
        resetLogs,
        logs,
      }}
    >
      {children}
    </LogsContext.Provider>
  )
}

export function useLogs() {
  const context = useContext(LogsContext)
  return context
}
