import React from 'react'
import ToastNotification from '~/components/ToastNotification'
import { IErrorModel } from '~/services/RequestService/types'

interface HandleResponseProps {
  isSuccess?: boolean
  successMessage: string
  errorMessage: string
  updateList: () => Promise<void>;
  setOpenDeleteUserModal: (open: boolean) => void
  errorDetails?: string
  errorsT?: IErrorModel[]
}

const responseTableUpdate = ({
  isSuccess,
  successMessage,
  errorMessage,
  updateList,
  setOpenDeleteUserModal,
  errorDetails,
  errorsT,
}: HandleResponseProps) => {
  if (isSuccess) {
    ToastNotification({
      id: 'success',
      type: 'success',
      message: successMessage,
    })
    updateList()
    setOpenDeleteUserModal(false)
  } else {
    ToastNotification({
      id: 'error',
      type: 'error',
      message: errorMessage,
      errorMessage: errorDetails,
      errors: errorsT,
    })
  }
}

export default responseTableUpdate
