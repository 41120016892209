import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  align-items: center;

  button {
    border: none;
    background: none;

    width: auto;
    height: auto;
  }
`
