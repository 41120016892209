import { Box, TextField } from '@mui/material'
import styled, { css } from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const Container = styled.div`
  padding: 0 10%;
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`

export const MyMap = styled.div`
  /* height: 700px; */
  .string_modulos {
    background-color: #707070;
    color: white;
    font-weight: bold;
    border: none;
    box-shadow: none;
    top: 2px;
    font-size: 16px;
    padding: 0 10px;
    z-index: 999;
  }
`

export const Imagem = styled.img`
  display: block;
  border-radius: 10px;
  height: 90%;
`

export const Label = styled.label`
  color: #5a5c5e;
  display: flex;
  flex-direction: column;
`

interface EditIconProps {
  topPosition?: number;  // Permite que seja opcional
}

export const EditIcon = styled(Box)<EditIconProps>`
  position: absolute;
  width: 30px;
  height: 30px;
  top: ${props => props.topPosition ? `${props.topPosition}px` : '35px'};
  right: 10px;
  color: ${theme.colors.black};
  border-radius: 50%;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Input = styled.input`
  padding: 10px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #c5c5c5;
  width: 100%;
  background-color: #f7f7f7;
  height: 40px;

  &:focus {
    border: 1px solid #f89722;
  }

  &[type='file'] {
    border: none;
    display: flex;
    align-items: center;
  }
`

export const MaterialInput = styled(TextField)`
  padding: 10px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #c5c5c5;
  width: 100%;
  background-color: #f7f7f7;
  height: 40px;
  margin-top: 4px;

  &:focus {
    border: 1px solid #f89722;
  }

  &[type='file'] {
    border: none;
    display: flex;
    align-items: center;
  }

  .MuiFormHelperText-root {
    margin-top: 0px; 
  }
`

export const TextArea = styled(TextField)`
  padding: 10px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #c5c5c5;
  width: 100%;
  background-color: #f7f7f7;
  height: 155px;
  font-family: 'open sans', sans-serif;
  resize: none;
  margin-top: 4px;

  &:focus {
    border: 1px solid #f89722;
  }

  &[type='file'] {
    border: none;
    display: flex;
    align-items: center;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  font-family: 'open sans', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  color: #393939;
  width: 100%;
  margin-top: 5px;
`

export const ResetThumb = styled(Box)`
  position: relative;
  margin-top: 10px;

  img {
    border-radius: 8px;
    overflow: hidden;
    width: 450px;
    height: 300px;
  }

  span {
    position: absolute;
    top: 5px;
    right: 12px;
    cursor: pointer;
    z-index: 100;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    svg {
      color: #dd0;
    }
  }
`

export const WarningLabel = styled.div`
  color: red;
`
export const Text = styled.p`
  margin-top: 5px;
`

export const Flex = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 9px;
  gap: 5px;
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  gap: 16px;
`

export const Grid = styled(Box)`
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  width: 100%;
`

export const ContainerForm = styled.div`
  margin: 10px auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 1200px;
  border-radius: 10px;
  background-color: #fff;

  .col {
    flex-direction: column;
  }

  .full {
    width: 100%;
  }

  .border {
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #e9e9e9;
  }
`

// STYLE DRAG ZONE START

const dragActive = css`
  border-color: #0041006a;
  color: #004100;
  background-color: #00ff0011;
`

const dragReject = css`
  border-color: #e57878;
  color: #5e0000;
  background-color: #ff000021;
`

const dragAwaitCoordenadas = css`
  border-color: #8f8c00;
  background-color: #ffe60020;
  color: #8f8c00;
  cursor: no-drop;
  flex-direction: row;
  gap: 10px;
`

export const FileAndButtonWrapper = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  align-items: center;
  gap: 16px;
  margin-top: '10px';
`

export const ImageSelectorWrapper = styled.div`
  flex: 1;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  position: relative;
  width: 100%;
`

export const ImageNameWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  padding-top: 15px;

  gap: 20px;
`

export const ImageTitle = styled.p`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
`

export const ImageDate = styled.p`
  width: 100%;
  font-size: 14px;
`

export const BoxFile = styled.div.attrs({
  className: 'boxfile',
})<{ isDragActive: boolean; isDragReject: boolean; isDragAwaitCoordenadas: boolean }>`
  font-weight: bold;
  display: flex;
  border: 2px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: height 0.2s ease;
  padding: 15px 25px;
  min-height: 80px;
  height: 100%;
  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
  ${(props) => props.isDragAwaitCoordenadas && dragAwaitCoordenadas};

  & span {
    display: 'flex';
    font-size: 14px;
    font-weight: normal;
    color: #b6b6b6;
  }
`

export const UploadMessage = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: inherit;

  & div {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: normal;
    font-size: 13px;

    & p {
      font-style: italic;
    }
  }
`

const buttonWarning = css`
  border-color: #8b0000;
  color: #8b0000;
`

const buttonSucess = css`
  border-color: #008b0c;
  color: #008b0c;
`

const buttonWarningEffect = css`
  background: #8b0000;
`

export const Button = styled.button<{ warning?: boolean; sucess?: boolean }>`
  border: 2px solid;
  font-weight: bold;
  font-size: 15px;
  background-color: transparent;
  padding: 5px;
  border-radius: 90px;
  text-transform: uppercase;
  display: block;
  position: relative;
  transition: all 0.25s;
  ${(props) => props.warning && buttonWarning};
  ${(props) => props.sucess && buttonSucess};

  & span {
    color: inherit;
    position: relative;
    z-index: 2;
  }

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0px;
    width: 0;
    opacity: 0;
    height: 100%;
    background: #008b0c;
    ${(props) => props.warning && buttonWarningEffect};

    border-radius: 90px;
    transition: all 0.25s;
  }

  &:hover span {
    color: #fff;
  }

  &:hover:after {
    opacity: 1;
    width: 100%;
  }

  &:disabled {
    opacity: 0.2;
    cursor: 'not-drop';
    background: #008b0c;
    color: white;
    pointer-events: none;
  }
`

export const ButtonStyled = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 8px;

  &:hover {
    opacity: 0.9;
  }

  p {
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
`
