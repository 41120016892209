import { useEffect, useState } from 'react'
import { anomalyBarsSelection } from '../../utils'
import * as S from '../../styles'
import { Bar, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { MenuItem, Select } from '@mui/material'
import { IAnomalyTypeBarsProps } from '~/services/Dashboard/types'
import { theme } from '~/styles/theme'
import CustomTooltip from './CustomTooltip'
import { useTranslation } from 'react-i18next'
import { ScrollableContainer } from '../Benchmark'
import { AnomalyData } from './types'

type AllAnomaliesEffectComponentProps = {
  anomalyTypeBars: IAnomalyTypeBarsProps[]
}

const AllAnomaliesEffectComponent = ({ anomalyTypeBars = [] }: AllAnomaliesEffectComponentProps) => {
  const minHeight = 300;
  const graphHeight = Math.max(minHeight, anomalyTypeBars.length * 50);
  const [anomalyBarSelected, setAnomalyBarSelected] = useState(anomalyBarsSelection[0])
  const [anomalyData, setAnomalyData] = useState<AnomalyData[]>([]);

  const { t, i18n } = useTranslation()
  const [translatedAnomalyBars, setTranslatedAnomalyBars] = useState(anomalyBarsSelection)

  useEffect(() => {
    const translated = anomalyBarsSelection.map((item) => ({
      ...item,
      label: t(item.label),
    }))
    setTranslatedAnomalyBars(translated)
    setAnomalyBarSelected(translated[0])
  }, [i18n.language, t])

  useEffect(() => {
    const translated = anomalyTypeBars.map(anomaly => ({
      ...anomaly,
      name: t(anomaly.name) 
    }));
    setAnomalyData(translated);
  }, [anomalyTypeBars, t]);


  return (
    <S.Benchmark>
      <h4 className="title">{t('Efeito de todas as anomalias')}</h4>
      <p className="table-type">{anomalyBarSelected.label}</p>
      <ScrollableContainer>
        <ResponsiveContainer width="100%" height={graphHeight}>
          <S.CustomBarChart
            layout="vertical"
            data={anomalyData.slice().sort((a, b) => b[anomalyBarSelected.value] - a[anomalyBarSelected.value])}
          >
            <CartesianGrid />
            <XAxis type="number" />
            <YAxis type="category" dataKey="name" width={150} fontSize={15} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey={anomalyBarSelected.value} fill={theme.colors.orange} />
          </S.CustomBarChart>
        </ResponsiveContainer>
      </ScrollableContainer>

      <Select sx={{ marginTop: "10px" }} variant="outlined" size="small" value={anomalyBarSelected?.label} onChange={(e) => {
        const selectedAnomaly = translatedAnomalyBars.find(anomaly => anomaly.label === e.target.value)
        setAnomalyBarSelected(selectedAnomaly!)
      }}>
        {translatedAnomalyBars.map((anomaly) => (
          <MenuItem key={anomaly.value} value={anomaly.label}>
            {anomaly.label}
          </MenuItem>
        ))}
      </Select>
    </S.Benchmark >
  )
}

export default AllAnomaliesEffectComponent
