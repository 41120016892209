import { Box } from '@mui/material'
import styled from 'styled-components'

export const ModalWrapper = styled(Box)`
  display: flex;
  height: 80vh;

  gap: 20px;

  img {
    height: 100%;
  }
`

export const AlignCenter = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 10px;
`

export const ModalContent = styled(Box)`
  display: block;
  min-width: 300px;
`

export const ModalItemContent = styled(Box)`
  padding-top: 20px;

  display: flex;
  align-items: center;

  h4 {
    padding-right: 4px;
  }
`
