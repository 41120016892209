/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'

import { TextField } from '@mui/material'

import LoginImage from '~/assets/images/loginBackground.png'
import LogoSolar from '~/assets/images/logoSolarInspec.png'
import { useAuth } from '~/contexts/Auth'
import { Link } from 'react-router-dom'
import Button from '~/components/Button'
import { Registration, RegistrationError } from './types'
import { signInValidation } from './validations'
import ToastNotification from '~/components/ToastNotification'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

export default function Login() {
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const { t } = useTranslation();

  const handleLogin = async () => {
    setLoading(true);

    try {
      let countError = 0;

      const validation = await signInValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        setLoading(false);
        return;
      }
      await signIn(registration.email, registration.password);
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao realizar o login, tente novamente.')
      });
    }
    setLoading(false);
  };

  const onEnterDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <S.Container>
      <img src={LoginImage} alt="Imagem de login Solar" />

      <S.ContentLogin>
        <img src={LogoSolar} alt="Logo Solar" />
        <h1>{t('Faça login para acessar a plataforma')}</h1>
        <TextField
          label={t('E-mail')}
          type="email"
          value={registration.email}
          onChange={(e) => setRegistration({ ...registration, email: e.target.value })}
          onKeyDown={onEnterDown}
          size="small"
          fullWidth
          error={Boolean(registrationError.email)}
          helperText={registrationError.email}
          onClick={() => setRegistrationError({ ...registrationError, email: '' })}
        />

        <TextField
          type={'password'}
          label={t('Senha')}
          size="small"
          value={registration.password}
          onChange={(e) => setRegistration({ ...registration, password: e.target.value })}
          error={Boolean(registrationError.password)}
          helperText={registrationError.password}
          onClick={() => setRegistrationError({ ...registrationError, password: '' })}
          onKeyDown={onEnterDown}
          sx={{
            marginTop: 2,
          }}
          fullWidth
        />

        <S.Divlink className="viewlink">
          <Link to='/recuperarSenha' className="link">{t('Esqueci a senha')}</Link>
        </S.Divlink>

        <Button type="button" onClick={handleLogin} loading={loading} label={t('Entrar')} />
      </S.ContentLogin>
    </S.Container>
  )
}
