import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  height: 100vh;
  padding-top: 32px;

  button {
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    padding: 8px 16px;
    border-radius: 8px;
  }
`
