import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import {
  AnomalySimpleDTO,
  GetAnomaliesMapQueryResponse,
  IAnomaliePageInfoProps,
  IAnomalyLIstProps,
  IRequestAnomaly,
  IRequestManualLocal,
  IRequestPutAnomaly,
  IResponseAnomaly,
  IResponseAnomalyStatus,
  IResponseGetAllAnomalyIds,
  IResponsePageInfoAnomalies,
  RequestAnomaly,
} from './types'

const defaultRoot = 'anomaly'

export const getAnomaly = async (data: RequestAnomaly) => {
  let url = `${defaultRoot}?`

  if (data.SiteId) {
    const encodedLocal = encodeURIComponent(data.SiteId)
    url += `SiteId=${encodedLocal}`
  }

  if (data.InspectionId) {
    url += `&InspectionId=${data.InspectionId}`
  }

  url += `&PageNumber=${data.page}&PageSize=${data.pageSize}`

  return await getJson<IResponseAnomaly[]>(url)
}

export const deleteAnomalyService = async (id: string) => {
  let url = `${defaultRoot}`

  return await deleteJson<{ id: string }, boolean>(url, { id })
}

export const deleteAnomalyByTypeService = async (anomalyTypeIds: number[], inspectionId: string) => {
  let url = `${defaultRoot}/frominspectionbytype`
  const dataToSend = {
    anomalyTypeIds,
    inspectionId,
  }

  return await deleteJson<
    {
      inspectionId: string
      anomalyTypeIds: number[]
    },
    boolean
  >(url, dataToSend)
}

export const getAnomalySimple = async (data: IRequestAnomaly) => {
  const params = new URLSearchParams()

  if (data.InspectionId) {
    params.append('InspectionId', data.InspectionId)
  }
  if (data.UpperLeftLatitude) {
    params.append('UpperLeftLatitude', data.UpperLeftLatitude.toString())
  }
  if (data.UpperLeftLongitude) {
    params.append('UpperLeftLongitude', data.UpperLeftLongitude.toString())
  }
  if (data.LowerRightLatitude) {
    params.append('LowerRightLatitude', data.LowerRightLatitude.toString())
  }
  if (data.LowerRightLongitude) {
    params.append('LowerRightLongitude', data.LowerRightLongitude.toString())
  }
  if (data.Zoom) {
    params.append('Zoom', data.Zoom.toString())
  }
  if (data.AnomalyTypes) {
    data.AnomalyTypes.forEach((type) => params.append('AnomalyTypes', type.toString()))
  }
  if (data.Severities) {
    data.Severities.forEach((severity) => params.append('Severities', severity.toString()))
  }
  if (data.Statuses) {
    data.Statuses.forEach((status) => params.append('Statuses', status.toString()))
  }
  if (data.V2) {
    params.append('V2', 'true')
  }

  const url = `${defaultRoot}/map?${params.toString()}`
  return await getJson<GetAnomaliesMapQueryResponse>(url)
}

export const getAnomalyStatus = async () => {
  let url = `${defaultRoot}/statuses`
  return await getJson<IResponseAnomalyStatus[]>(url)
}

export const putAnomaly = async (data: IRequestPutAnomaly) => {
  let url = `${defaultRoot}`
  return await putJson<IRequestPutAnomaly, string>(url, data)
}

export const postComplementaryImage = async (data: FormData) => {
  let url = `${defaultRoot}/addcomplementaryimage`
  return await postJson<FormData, boolean>(url, data)
}

export const deleteComplementaryImage = async (AnomalyId: string, FileId: string) => {
  let url = `${defaultRoot}/removecomplementaryimage?AnomalyId=${AnomalyId}&FileId=${FileId}`
  return await deleteJson<boolean, boolean>(url)
}

export const getAnomalyListService = async (inspectionId: string, filter?: string) => {
  let url = `${defaultRoot}/list?InspectionId=${inspectionId}`

  if (filter) url += `&${filter}`

  return await getJson<IAnomalyLIstProps[]>(url)
}

export const getAnomalyPageInfoService = async (inspectionId: string) => {
  return await getJson<IAnomaliePageInfoProps>(
    `${defaultRoot}/anomaliespageinfo?InspectionId=${inspectionId}`,
  )
}

export const getAnomalyDetailsService = async (id: string) => {
  return await getJson<AnomalySimpleDTO>(`${defaultRoot}/${id}`)
}

export const getAnomalyFilters = async (inspectionId: string) => {
  return await getJson<IResponsePageInfoAnomalies>(
    `${defaultRoot}/anomaliespageinfo?InspectionId=${inspectionId}`,
  )
}

export const postManualLocal = async (data: IRequestManualLocal) => {
  let url = `${defaultRoot}/manuallocal`

  return await postJson<IRequestManualLocal, string>(url, data)
}

export const getInspectionsAnomalyIds = async (data: IRequestAnomaly) => {
  const params = new URLSearchParams()

  if (data.InspectionId) {
    params.append('InspectionId', data.InspectionId)
  }
  if (data.AnomalyTypes) {
    data.AnomalyTypes.forEach((type) => params.append('AnomalyTypes', type.toString()))
  }
  if (data.Severities) {
    data.Severities.forEach((severity) => params.append('Severities', severity.toString()))
  }
  if (data.Statuses) {
    data.Statuses.forEach((status) => params.append('Statuses', status.toString()))
  }
  const url = `${defaultRoot}/inspectionanomalyids?${params.toString()}`
  return await getJson<IResponseGetAllAnomalyIds>(url)
}
