export interface RequestLogs {
  search?: string
  accessLevel?: string
  statusUser?: string
  id?: string
  page?: number
  pageSize?: number
  user?: string
  pages?: number
  itemsTotal?: number
}

export enum ELogType {
  CompanyCreate = 1,
  CompanyUpdate = 2,
  CompanyDelete = 3,
  UserCreate = 4,
  UserUpdate = 5,
  UserDelete = 6,
  SiteCreate = 7,
  SiteUpdate = 8,
  SiteDelete = 9,
  InspectionCreate = 10,
  InspectionUpdate = 11,
  InspectionDelete = 12,
  AnomalyUpdate = 13,
  AnomalyComplementaryFileCreate = 14,
  AnomalyComplementaryFileDelete = 15,
  InspectionUploadImages = 16,
  AnomalyDelete = 17,
  SiteUserAdd = 18,
  SiteUserRemove = 19,
  CompanyLogoAdd = 20,
  CompanyLogoRemove = 21,
}

export const ELogTitle: { [key: number]: string } = {
  [ELogType.CompanyCreate]: 'Criação de Empresa',
  [ELogType.CompanyUpdate]: 'Atualização de Empresa',
  [ELogType.CompanyDelete]: 'Exclusão de Empresa',
  [ELogType.UserCreate]: 'Criação de Usuário',
  [ELogType.UserUpdate]: 'Atualização de Usuário',
  [ELogType.UserDelete]: 'Exclusão de Usuário',
  [ELogType.SiteCreate]: 'Criação de Site',
  [ELogType.SiteUpdate]: 'Atualização de Site',
  [ELogType.SiteDelete]: 'Exclusão de Site',
  [ELogType.InspectionCreate]: 'Criação de Inspeção',
  [ELogType.InspectionUpdate]: 'Atualização de Inspeção',
  [ELogType.InspectionDelete]: 'Exclusão de Inspeção',
  [ELogType.AnomalyUpdate]: 'Atualização de Anomalia',
  [ELogType.AnomalyComplementaryFileCreate]: 'Criação de Arquivo Complementar de Anomalia',
  [ELogType.AnomalyComplementaryFileDelete]: 'Exclusão de Arquivo Complementar de Anomalia',
  [ELogType.InspectionUploadImages]: 'Upload de imagens em inspeção',
  [ELogType.AnomalyDelete]: 'Deleção',
  [ELogType.SiteUserAdd]: 'Adição',
  [ELogType.SiteUserRemove]: 'Remoção',
  [ELogType.CompanyLogoAdd]: 'Adição de logo',
  [ELogType.CompanyLogoRemove]: 'Remoção de logo',
}

export const getLogTypeString = (logTypeValue: ELogType) => {
  return ELogTitle[logTypeValue] ?? 'Desconhecido'
}

export interface ILogRequest {
  startDateTime?: string | null
  endDateTime?: string | null
  logType?: ELogType
  userId?: string | null
  nextLogsToken?: string | null
  limit?: number
}
export interface ILogsList {
  id?: string
  userName?: string
  userId?: string
  timestamp?: string
  componentId?: string
  componentName?: string
  description?: string
  source?: string
  userIP?: string
}

export interface RequestLogsExport {
  UserId?: string
  TypeLog?: string
}

interface ModifiedProperty {
  propertyName: string
  originalValue: string
  updatedValue: string
}

export interface LogDto {
  logType: ELogType
  userName: string
  componentType: string
  componentId: string
  action: string
  dateTime: string
  changes: ModifiedProperty[]
}

export interface GetLogsResponse {
  logs: LogDto[]
  quantity: number
  nextLogsToken: string
}
