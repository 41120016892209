// styles.ts
import { CSSProperties } from 'react';

export const imageStyle: CSSProperties = {
  width: '100%',
  height: '500px',
  objectFit: 'cover',
  transition: 'opacity 1s ease-in-out',
  opacity: 1,
};

export const infoStyle: CSSProperties = {
  marginTop: '20px',
};

export const valueStyle: CSSProperties = {
  color: 'gray',
};

export const dialogTitleStyle: CSSProperties = {
  backgroundColor: '#f5f5f5',
  padding: '16px',
  borderBottom: '1px solid #ddd',
  textAlign: 'center',
  marginBottom: '1rem'
};

export const dialogTitleTextStyle: CSSProperties = {
  fontWeight: 'bold',
  fontSize: '1.5rem',
  color: '#333',
};

export const imagesContainerStyle: CSSProperties = {
  display: 'flex',
  overflowX: 'auto',
  gap: '16px',
};
