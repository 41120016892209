import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MaterialIcon } from '~/assets/icons/materialIcons'
import { useAuth } from '~/contexts/Auth'
import {
  IAnomaliePageInfoItemProps,
  IAnomaliePageInfoProps,
  IAnomalyLIstProps,
} from '~/services/Anomaly/types'
import { StyledTableRow } from '~/utils'
import { AccessLevel } from '~/utils/General'
import { dadosStatus, handleSeverityIcon } from '../../utils'
import * as S from './styles'

type TableDetailsProps = {
  anomalyList: IAnomalyLIstProps[]
  anomalyPageInfo: IAnomaliePageInfoProps | null
  page: number
  setImageDetails(value: IAnomalyLIstProps | null): void
  handleShowDetails(id: string): void
  deleteAnomaly(anomaly: IAnomalyLIstProps): void
}

const TableDetails = ({
  anomalyList,
  anomalyPageInfo,
  page,
  setImageDetails,
  handleShowDetails,
  deleteAnomaly,
}: TableDetailsProps) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const [thumbDetails, setThumbDetails] = useState<IAnomalyLIstProps | null>()

  const headCells = [
    {
      label: `N° ${t('Anomalia')}`,
      value: 'code',
    },
    {
      label: t('Imagens'),
      value: 'imageUrl',
    },
    {
      label: t('Anomalia'),
      value: 'name',
    },
    {
      label: t('Localização'),
      value: 'local',
    },
    {
      label: t('Delta T'),
      value: 'deltaTemperature',
    },
    {
      label: t('Severidade'),
      value: 'severity',
    },
    {
      label: t('Estado'),
      value: 'status',
    },
    {
      label: t('Ações'),
      value: 'icons',
    },
  ]

  return (
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            {headCells.map((headCell, i) => (
              <TableCell key={headCell.value} align={i === headCells.length - 1 ? 'right' : 'left'}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {anomalyList.map((row) => {
            const labelId = `enhanced-table-checkbox-${row?.id}`

            const statusData = dadosStatus(
              anomalyPageInfo?.anomalyStatuses.filter(
                (status) => status?.id === row.status,
              )[0] as IAnomaliePageInfoItemProps,
            )

            const selectedSeverity = anomalyPageInfo?.anomalySeverities.find(
              (severity) => severity?.id === row.severity,
            )

            return (
              <StyledTableRow hover key={labelId}>
                <TableCell scope="row" width={150}>
                  #{row.code}
                </TableCell>
                <S.ImagetableCell align="left">
                  <MaterialIcon
                    name="LinkedCamera"
                    onMouseEnter={() => setThumbDetails(row)}
                    onMouseLeave={() => setThumbDetails(null)}
                    onClick={() => setImageDetails(row)}
                  />
                  {thumbDetails?.id === row.id && (
                    <S.ImageThumb>
                      <img src={thumbDetails?.imageUrl} alt={thumbDetails?.name} />
                    </S.ImageThumb>
                  )}
                </S.ImagetableCell>
                <TableCell align="left" width={160}>
                  {t(row?.name)}
                </TableCell>
                <TableCell align="left">{row.local ?? '-'}</TableCell>
                <TableCell align="left">{row.deltaTemperature.toFixed(2)}</TableCell>
                <TableCell align="left">
                  <S.AlignCenter>
                    {handleSeverityIcon(row.severity)}
                    {t(selectedSeverity?.name ?? '')}
                  </S.AlignCenter>
                </TableCell>
                <TableCell align="left">
                  <S.AlignCenter>
                    {statusData.icon} {t(statusData.status)}
                  </S.AlignCenter>
                </TableCell>
                <TableCell align="right" sx={{ display: 'flex', gap: '5px' }}>
                  <S.AlignCenter>
                    <S.IconButton>
                      <MaterialIcon
                        name="Edit"
                        title={t('Editar')}
                        color="primary"
                        onClick={() => handleShowDetails(row.id)}
                      />
                    </S.IconButton>
                    {user?.accessLevel === AccessLevel.Master && (
                      <S.IconButton sx={{ marginLeft: '10px' }}>
                        <MaterialIcon
                          name="Delete"
                          title={t('Remover')}
                          color="error"
                          onClick={() => deleteAnomaly(row)}
                        />
                      </S.IconButton>
                    )}
                  </S.AlignCenter>
                </TableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableDetails
