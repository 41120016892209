import { getJson, postJson } from '../RequestService'
import { ICompanyAnomalyType, ICompanyAnomalyTypeGet } from './types'

const defaultRoot = 'companyanomalytype'

export const getAdvancedSettings = async (params: ICompanyAnomalyTypeGet) => {
  let url = `${defaultRoot}`
  if (params?.companyId) url += `?CompanyId=${params.companyId}`
  return await getJson<any>(url)
}

export const postAdvancedSettings = async (body: ICompanyAnomalyType) => {
  let url = `${defaultRoot}`
  return await postJson(url, body)
}
