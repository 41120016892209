import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IAnomalyTypesImpactListProps } from '~/services/Dashboard/types'
import { StyledTableRow as styledRow } from '~/utils'
import { globalUnitFormatter } from '~/utils/Mask'
import * as S from '../../styles'

type SiteReportProps = { anomalyTypesImpactList: IAnomalyTypesImpactListProps[]; currencySymbol: string }

const SiteReport = ({ anomalyTypesImpactList, currencySymbol }: SiteReportProps) => {
  const { i18n, t } = useTranslation()
  const [totalValues, setTotalValues] = useState({
    quantity: 0,
    powerLossPerHourInKwh: 0,
    powerLossPerYearInKwh: 0,
    powerLossPercentageOfInspectedPower: 0,
    costPerYear: 0,
  })

  useEffect(() => {
    const totals = {
      quantity: 0,
      powerLossPerHourInKwh: 0,
      powerLossPerYearInKwh: 0,
      powerLossPercentageOfInspectedPower: 0,
      costPerYear: 0,
    }

    anomalyTypesImpactList.forEach((row) => {
      totals.quantity += row.quantity
      totals.powerLossPerHourInKwh += row.powerLossPerHourInKwh
      totals.powerLossPerYearInKwh += row.powerLossPerYearInKwh
      totals.powerLossPercentageOfInspectedPower += row.powerLossPercentageOfInspectedPower
      totals.costPerYear += row.costPerYear
    })

    setTotalValues(totals)
  }, [anomalyTypesImpactList])

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(styledRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  return (
    <S.SiteReportWrapper>
      <h4>{t('Tabela de impacto por tipos de anomalia')}</h4>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('Anomalia')}</StyledTableCell>
              <StyledTableCell align="right">{t('Quantidade')}</StyledTableCell>
              <StyledTableCell align="right">{t('Est. perda por hora')} (KW)</StyledTableCell>
              <StyledTableCell align="right">{t('Est. perda por ano')} (KW)</StyledTableCell>
              <StyledTableCell align="right">{t('Est. perda em porcentagem')} (%)</StyledTableCell>
              <StyledTableCell align="right">
                {`${t('Est. perda por ano')} (${currencySymbol})`}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {anomalyTypesImpactList.map((row) => (
              <StyledTableRow key={row.anomalyType}>
                <StyledTableCell component="th" scope="row">
                  {row.anomalyType}
                </StyledTableCell>
                <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                <StyledTableCell align="right">
                  {globalUnitFormatter(row.powerLossPerHourInKwh, i18n.language)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {globalUnitFormatter(row.powerLossPerYearInKwh, i18n.language)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {globalUnitFormatter(row.powerLossPercentageOfInspectedPower, i18n.language)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {globalUnitFormatter(row.costPerYear, i18n.language)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                TOTAL
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}>
                {totalValues.quantity}
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}>
                {globalUnitFormatter(totalValues.powerLossPerHourInKwh, i18n.language)}
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}>
                {globalUnitFormatter(totalValues.powerLossPerYearInKwh, i18n.language)}
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}>
                {globalUnitFormatter(totalValues.powerLossPercentageOfInspectedPower, i18n.language)}
              </StyledTableCell>
              <StyledTableCell align="right" sx={{ fontWeight: 'bold' }}>
                {globalUnitFormatter(totalValues.costPerYear, i18n.language)}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </S.SiteReportWrapper>
  )
}

export default SiteReport
