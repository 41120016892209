import * as yup from 'yup';

import { verifyClassEmpty } from '~/utils/ClassEmpty';
import { genericValidation } from '~/utils/ValidationGenerics';

import {  RecoveryPassword, RecoveryPasswordError  } from './types';

export const emailSendingValidation = async (object: RecoveryPassword): Promise<true | RecoveryPasswordError> => {
  const schemaRegister = yup.object().shape({
    email: yup.string().email().required()
  });

  const mountError = await genericValidation<RecoveryPassword, RecoveryPasswordError>(schemaRegister, object, new RecoveryPasswordError());

  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};

export const resetPasswordValidation = async (object: RecoveryPassword): Promise<true | RecoveryPassword> => {
  const schemaRegister = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(8, 'A senha deve ter pelo menos 8 caracteres!').required(),
    confirmationPassword: yup.string().oneOf([yup.ref('password'), null], 'Senhas diferentes!').required()
  });

  const mountError = await genericValidation<RecoveryPassword, RecoveryPasswordError>(schemaRegister, object, new RecoveryPassword());
  const regExpSpecialChar = /[^a-zA-Z0-9]/;
  const regExpNumber = /\d/;
  const regExpCapitalLowerCase = () => {
    let i = 0;
    if (object.password.length === 0) {
      return false;
    }
    while (i < object.password.length) {
      const character = object.password.charAt(i);
      if (character === character.toUpperCase()) {
        // Character is upperCase, numeric, or a symbol
      } else if (character === character.toLowerCase()) {
        return true;
      }
      i++;
    }
    return false;
  };
  const regExpCapitalUpperCase = () => {
    let i = 0;
    if (object.password.length === 0) {
      return false;
    }
    while (i < object.password.length) {
      const character = object.password.charAt(i);
      if (character === character.toLowerCase()) {
        // Character is lowercase, numeric, or a symbol
      } else if (character === character.toUpperCase()) {
        return true;
      }
      i++;
    }
    return false;
  };

  if (!regExpSpecialChar.test(object.password) && mountError.password === '') {
    mountError.password = 'Deve conter pelo menos um caracter especial';
  }

  if (!regExpNumber.test(object.password) && mountError.password === '') {
    mountError.password = 'Deve conter pelo menos um dígito de 0-9';
  }

  if (!regExpCapitalUpperCase() && mountError.password === '') {
    mountError.password = 'Deve conter pelo menos um caracter maiúsculo';
  }

  if (!regExpCapitalLowerCase() && mountError.password === '') {
    mountError.password = 'Deve conter pelo menos um caracter minúsculo';
  }
  if (!verifyClassEmpty(mountError)) return true;
  return mountError;
};
