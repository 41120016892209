/* eslint-disable indent */
import React from 'react'

import { CircularProgress } from '@mui/material'

import Icon from '~/assets/icons'

import * as S from './styles'
import { DialogTitleProps, IMttModalProps } from './types'

const Modal: React.FC<IMttModalProps> = (props: IMttModalProps) => {
  const {
    children,
    open,
    onClose,
    title,
    confirmButtonAction,
    cancelButtonAction,
    confirmButtonDisabled,
    confirmButtonText,
    cancelButtonText,
    noButtons,
    closeOnClickAway,
    confirmButtonLoading,
    mobile
  } = props

  const DialogHeader = (props: DialogTitleProps) => {
    const {
      children, onClose, ...other
    } = props
    return (
      <S.DialogHeader>
        <S.MttDialogTitle {...other}>
          {children}
        </S.MttDialogTitle>
        <button aria-label="close" onClick={onClose}>
          <Icon name="Close" />
        </button>
      </S.DialogHeader>
    )
  }

  const renderChildrenDefault = () => {
    return <>
      <DialogHeader id="customized-dialog-title" onClose={onClose}>{title}</DialogHeader>
      <S.MttDialogContent role="content">{children}</S.MttDialogContent>
      {!noButtons && (
        <S.MttDialogActions>
          {cancelButtonAction && (
            <button onClick={cancelButtonAction} color="primary">
              {cancelButtonText ?? 'Cancelar'}
            </button>
          )}
          <button
            autoFocus
            onClick={confirmButtonAction}
            color="secondary"
            disabled={confirmButtonDisabled}
          >
            {confirmButtonLoading ? <CircularProgress size={24} /> : confirmButtonText ?? 'Salvar'}
          </button>
        </S.MttDialogActions>
      )}
    </>
  }

  const closeOnClick = () => {
    if (closeOnClickAway) onClose()
  }

  return mobile
    ? (
      <S.MttDrawerMobile onClose={closeOnClick} anchor='bottom' open={open} role="drawer-mobile">
        {renderChildrenDefault()}
      </S.MttDrawerMobile>
    )
    : (
      <S.MttDialogWeb onClose={closeOnClick} open={open} role="modalOpen">
        {renderChildrenDefault()}
      </S.MttDialogWeb>
    )
}

export default Modal
