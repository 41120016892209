import { MaterialIcon } from '~/assets/icons/materialIcons'

interface ButtonRemoverProps<T> {
  item: T
  setItemSelected: (item: T) => void
  setOpenDeleteUserModal: (open: boolean) => void
  t: (key: string) => string
}

const ButtonRemover = <T extends {}>({
  item,
  setItemSelected,
  setOpenDeleteUserModal,
  t,
}: ButtonRemoverProps<T>) => {
  return (
    <button
      onClick={() => {
        setItemSelected(item)
        setOpenDeleteUserModal(true)
      }}
    >
      <MaterialIcon name="Delete" style={{ marginLeft: '24px' }} title={t('Remover')} />
    </button>
  )
}

export default ButtonRemover
