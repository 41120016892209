import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ToastNotification from '~/components/ToastNotification'
import { getAnomalyFilters } from '~/services/Anomaly'
import { IResponsePageInfoAnomalies } from '~/services/Anomaly/types'
import ErrorToast from '~/utils/toastErrorCatch'

interface CustomModalProps {
  open: boolean
  onClose: () => void
  onSave: (values: { types: number[]; statuses: number[]; severities: number[] }) => void
  inspectionId: string
}

const FiltersModal: React.FC<CustomModalProps> = ({ open, onClose, onSave, inspectionId }) => {
  const [selectedTypes, setSelectedTypes] = useState<number[]>([])
  const [selectedStatuses, setSelectedStatuses] = useState<number[]>([])
  const [selectedSeverities, setSelectedSeverities] = useState<number[]>([])
  const [data, setData] = useState<IResponsePageInfoAnomalies | null>(null)

  const { t } = useTranslation()

  const handleSave = () => {
    onSave({
      types: selectedTypes,
      statuses: selectedStatuses,
      severities: selectedSeverities,
    })
    onClose()
  }

  const handleClose = (event: {}, reason: string) => {
    if (reason !== 'escapeKeyDown') {
      onClose()
    }
  }

  const handleDelete =
    (setter: React.Dispatch<React.SetStateAction<number[]>>) =>
      (id: number, event: React.MouseEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setter((prev) => prev.filter((value) => value !== id))
      }

  const loadFilters = async () => {
    try {
      const responseFilters = await getAnomalyFilters(inspectionId)
      if (responseFilters.success) {
        setData(responseFilters.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os valores dos filtros, tente novamente mais tarde.'),
          errorMessage: responseFilters.errorDetails,
          errors: responseFilters.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast
          message={t('Não foi possível carregar os valores dos filtros, tente novamente mais tarde.')}
        />
      )
    }
  }

  useEffect(() => {
    if (open) {
      loadFilters()
    }
  }, [open])
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          {t('Selecione os filtros')}
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('Tipo de Anomalia')}</InputLabel>
          <Select
            multiple
            endAdornment={
              selectedTypes.length > 0 && (
                <CloseIcon
                  style={{ cursor: 'pointer', marginRight: '12px' }}
                  onClick={() => setSelectedTypes([])}
                />
              )
            }
            value={selectedTypes}
            onChange={(e) => setSelectedTypes(e.target.value as number[])}
            input={<OutlinedInput id="select-multiple-chip" label="Tipo de Anomalia" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  const typeName = data?.anomalyTypes.find((type) => type.id === value)?.name ?? 'Unknown';
                  return (
                    <Chip
                      key={value}
                      label={t(typeName)}
                      onDelete={(e) => handleDelete(setSelectedTypes)(value, e)}
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                  );
                })}
              </Box>
            )}
          >
            {data?.anomalyTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {t(type.customName ?? type.name ?? 'No Name')} 
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('Status')}</InputLabel>
          <Select
            multiple
            endAdornment={
              selectedStatuses.length > 0 && (
                <CloseIcon
                  style={{ cursor: 'pointer', marginRight: '12px' }}
                  onClick={() => setSelectedStatuses([])}
                />
              )
            }
            value={selectedStatuses}
            onChange={(e) => setSelectedStatuses(e.target.value as number[])}
            input={<OutlinedInput label="Status" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {

                  const statusName = data?.anomalyStatuses.find((status) => status.id === value)?.name ?? 'Unknown';
                  return (
                    <Chip
                      key={value}
                      label={t(statusName)}  // Traduzir o nome do status
                      onDelete={(e) => handleDelete(setSelectedStatuses)(value, e)}
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                  );
                })}
              </Box>
            )}
          >
            {data?.anomalyStatuses.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {t(status.name ?? 'defaultStatus')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('Severidade')}</InputLabel>
          <Select
            multiple
            endAdornment={
              selectedSeverities.length > 0 && (
                <CloseIcon
                  style={{ cursor: 'pointer', marginRight: '12px' }}
                  onClick={() => setSelectedSeverities([])}
                />
              )
            }
            value={selectedSeverities}
            onChange={(e) => setSelectedSeverities(e.target.value as number[])}
            input={<OutlinedInput label="Severidade" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  const severityName = data?.anomalySeverities.find((severity) => severity.id === value)?.name ?? 'Unknown';
                  return (
                    <Chip
                      key={value}
                      label={t(severityName)}
                      onDelete={(e) => handleDelete(setSelectedSeverities)(value, e)}
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                  );
                })}
              </Box>
            )}
          >
            {data?.anomalySeverities.map((severity) => (
              <MenuItem key={severity.id} value={severity.id}>
                {t(severity.name ?? 'No Name')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
          <Button sx={{ mt: 2 }} variant="contained" onClick={handleSave}>
            {t('Buscar')}
          </Button>
          <Button sx={{ mt: 2 }} variant="outlined" onClick={onClose}>
            {t('Cancelar')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
export default FiltersModal
