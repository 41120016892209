import React, { useEffect } from 'react'
import ToastNotification from '~/components/ToastNotification'
import { IToastProps } from '~/components/ToastNotification/Interface/IToastProps'

interface ErrorToastProps {
  message: string
}

const ErrorToast: React.FC<ErrorToastProps> = ({ message }) => {
  useEffect(() => {
    const params: IToastProps = {
      id: 'error',
      type: 'error', 
      message,
      toastOptions: {}, 
    }

    ToastNotification(params)
  }, [message])

  return null
}

export default ErrorToast
