export interface IUsersList {
  id?: string
  name?: string
  status?: number
  password?: string
  accessLevel?: number
  branch?: string
  company?: string
  email?: string
}

export class IFilter {
  search? = ''
  accessLevel? = ''
  statusUser? = ''
  id? = ''
}

export interface IUserGet {
  id: string
  name: string
  status: number
  accessLevel: number
  branch: string
  company: string
  email: string
  companyId: string
  branchId: string
}

