export const theme = {
  colors: {
    primary: '#0C4A7D',
    secondary: '#F89722',
    orange: '#FFA73E',
    white: '#fff',

    background: '#EFF3F9',
    colorBtn: '#155f99',
    colorLine: '#EFEFEF',

    success: '#4cae4c',
    red: '#EE4B23',
    blue: '#0084FF',
    gray: '#8C9AA4',
    gray2: 'rgba(51,51,51, 0.1)',
    colorText: '#333333',
    link: '#2092FF',

    icon: '#00000099',
    black: '#000000',
    lightBlack: '#000000150',

    blueRGBA: 'rgba(012, 074, 125, 0.1)',
    blueRGBA2: 'rgba(012, 074, 125, 0.2)',
    blueRGBA3: 'rgba(012, 074, 125, 0.3)',
    blackRGBA: 'rgba(0, 0, 0, 0.1)',
  },
}
