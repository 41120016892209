import InputMaterial from '~/components/Material/Input';
import ToastNotification from '~/components/ToastNotification';
import { Button, Paper, Tooltip, Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getOthersSettings, postSettingsFlag } from '~/services/Settings';
import { InputOthersArray, UpdateValue } from '~/services/Settings/types';
import InfoIcon from '@mui/icons-material/Info';
import * as S from '../styles';

type GenericData = { [x: string]: string | boolean };

const Outros = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [inputSettings, setInputSettings] = useState<InputOthersArray>([]);

  const { control, handleSubmit, formState: { isDirty }, reset } = useForm<GenericData>();

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        const response = await getOthersSettings();
        if (response.success) {
          setInputSettings(response.data);
          const defaultValues = response.data.reduce((acc, item) => {
            acc[item.name] = item.value;
            return acc;
          }, {});
          reset(defaultValues);
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
            errorMessage: response.errorDetails,
            errors: response.errors,
          });
        }
      } catch (error) {
        console.error('error fetching settings', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [reset, t]);
  const onSubmit = async (values: GenericData) => {
    try {
      setLoading(true);

      for (const setting of inputSettings) {
        if (setting.name && values[setting.name] !== setting.value) {
          const updateValue: UpdateValue = {
            name: setting.name,
            newValue: Boolean(values[setting.name])
          };

          const resp = await postSettingsFlag(updateValue);

          if (!resp.success) {
            ToastNotification({
              id: 'error',
              type: 'error',
              message: t('Não foi possível enviar os dados, tente novamente mais tarde.'),
              errorMessage: resp.errorDetails,
              errors: resp.errors,
            });
            break;
          }
        }
      }
    } catch (e) {
      console.error('error during settings update', e);
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Erro ao atualizar configurações.'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ padding: 3, margin: '2px calc(10%)', backgroundColor: 'white', boxShadow: 1 }}>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        {inputSettings.map((setting, index) => (
          <Controller
            key={index}
            name={setting.name}
            control={control}
            render={({ field: { onChange, onBlur, value, ref, name } }) => (
              <S.InputWrapper>
                {typeof setting.value === 'boolean' ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => onChange(e.target.checked)}
                        onBlur={onBlur}
                        checked={!!value}
                        inputRef={ref}
                      />
                    }
                    label={t(setting.name)}
                  />
                ) : (
                  <InputMaterial
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value ?? setting.value.toString()}
                    size="small"
                    id={name}
                    label={t(setting.name)}
                    sx={{ width: 300 }}
                    inputRef={ref}
                  />
                )}
                <Tooltip title={t(setting.description ?? '')}>
                  <InfoIcon color="primary" />
                </Tooltip>
              </S.InputWrapper>
            )}
          />

        ))}
        <Button onClick={() => handleSubmit(onSubmit)()} variant="contained" disabled={!isDirty || inputSettings.length === 0}>
          {t('Enviar')}
        </Button>
      </S.Form>
    </Paper>
  );
};

export default Outros;
