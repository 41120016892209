import { CSSObjectWithLabel, ControlProps, GroupBase } from 'react-select'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import { theme } from '~/styles/theme'

export const defaultStylesProps = {
  control: (
    provided: CSSObjectWithLabel,
    state: ControlProps<SelectOption, false, GroupBase<SelectOption>>,
  ) => ({
    ...provided,
    flex: 1,
    flexDirection: 'row',
    minWidth: 300,
    height: 40,
    borderColor: state.isFocused ? theme.colors.primary : theme.colors.blueRGBA3,
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(32, 168, 216, 0.25)' : '',
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: '8px',
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
}

export const defaultSelectProps = {
  className: 'w-full',
  menuPosition: 'fixed',
  isClearable: true,
  styles: { defaultStylesProps },
}
