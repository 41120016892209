/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ISendRecoveryCode, IChangePassword } from './types'
import { postJson } from '../RequestService'

const defaultRoot = 'auth'

export const postSendCode = async (data: ISendRecoveryCode) => {
  const url = `${defaultRoot}/forgotpassword`
  return await postJson<unknown, unknown>(url, data)
}

export const postChangePassword = async (data: IChangePassword) => {
  const url = `${defaultRoot}/resetpassword`
  return await postJson<unknown, unknown>(url, data)
}
