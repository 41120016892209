import { TableCell as MUITableCell } from '@mui/material'
import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const Container = styled.div`
  margin: 0 10%;
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`
export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 40px 10%;
  padding-bottom: 0;

  .MuiFormControl-root {
    background-color: ${theme.colors.white};
  }
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    white-space: nowrap;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.primary};
  }
`
export const ContainerSearch = styled.div`
  width: 100%;
`

export const SearchBtn = styled.div`
  width: 180px;

  .btn_icon {
    color: white;
    font-size: 20px;
  }

  @media (max-width: 1024px) {
    width: 190px;
  }
`

export const Main = styled.div`
  width: 100%;
  height: 61%;
  padding: 0 10%;
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    border: none;
    background: none;

    width: auto;
    height: auto;
  }

  .i {
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;

    &:hover {
      color: ${theme.colors.black};
    }
  }

  .iconBlocked {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }

  .iconDisable {
    opacity: 0.5;
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
  }

  .iconDisableBlocked {
    opacity: 0.5;
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }
`

export const Message = styled.p`
  font-size: 18px;
  padding: 12px 24px;
`

export const TableCell = styled(MUITableCell)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerButton = styled.div`
  justify-content: center;
  align-items: center;
  width: 300px;
`

export const TextBranch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;

  label {
    font-size: 22px;
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.colors.black};
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`
