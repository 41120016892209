import { AnomalyTypeProps } from '~/components/Map/types'

export const getAnomalyColor = (item: AnomalyTypeProps) => AnomalyColors[item]

export const AnomalyType = {
  ['String Aberta']: 1,
  ['Tracker']: 2,
  ['Delaminação']: 3,
  ['Sujidade']: 4,
  ['Módulo Trincado']: 5,
  ['Sombreamento']: 6,
  ['Vegetação']: 7,
  ['Diodo de Bypass']: 8,
  ['Múltiplos Diodos de Bypass']: 9,
  ['Ponto Quente']: 10,
  ['Ponto Quente Baixo']: 11,
  ['Ponto Quente Médio']: 12,
  ['Ponto Quente Alto']: 13,
  ['Múltiplos Pontos Quente']: 14,
  ['Múltiplos Pontos Quente Baixo']: 15,
  ['Múltiplos Pontos Quente Médio']: 16,
  ['Múltiplos Pontos Quente Alto']: 17,
  ['Módulo Desativado']: 18,
  ['Módulo Ausente']: 19,
  ['Substring em Curto']: 20,
  ['Módulo em Curto']: 21,
  ['String Box']: 22,
  ['Inversor']: 23,
  ['Transformador']: 24,
  ['Polaridade Invertida']: 25,
  ['Água sobre Módulo']: 26,
  ['Módulo Danificado']: 27,
  ['Caixa de Junção']: 28,
}

export const AnomalyColors = {
  ['String Aberta']: '#6A5ACD',
  ['Tracker']: '#191970',
  ['Delaminação']: '#483D8B',
  ['Sujidade']: '#0000FF',
  ['DelaMódulo Trincadominação']: '#00BFFF',
  ['Sombreamento']: ' #4682B4',
  ['Vegetação']: '#778899',
  ['Diodo de Bypass']: '#00FFFF',
  ['Múltiplos Diodos de Bypass']: '#2F4F4F',
  ['Ponto Quente']: '#7FFFD4',
  ['Ponto Quente Baixo']: '#5F9EA0',
  ['Ponto Quente Médio']: '#00FF7F',
  ['Ponto Quente Alto']: '#3CB371',
  ['Múltiplos Pontos Quente']: '#006400',
  ['Múltiplos Pontos Quente Baixo']: '#7FFF00',
  ['Múltiplos Pontos Quente Médio']: '#00FF00',
  ['Múltiplos Pontos Quente Alto']: '#808000',
  ['Módulo Desativado']: '#DAA520',
  ['Módulo Ausente']: '#8B4513',
  ['Substring em Curto']: '#BC8F8F',
  ['Módulo em Curto']: '#FFDEAD',
  ['String Box']: '#4B0082',
  ['Inversor']: '#8B008B',
  ['Transformador']: '#FF00FF',
  ['Polaridade Invertida']: '#DA70D6',
  ['Água sobre Módulo']: '#FF1493',
  ['Módulo Danificado']: '#DC143C',
  ['Caixa de Junção']: '#8B0000',
}
