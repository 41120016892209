import { useEffect, useState } from 'react'
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  Checkbox,
  Typography,
  Tooltip,
} from '@mui/material'
import { deleteUserSite, getSiteUser, postUserSite } from '~/services/SiteUser'
import { IRequestSiteUser, ISiteUserDto } from '~/services/SiteUser/types'
import { Params } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import ToastNotification from '~/components/ToastNotification'
import InfoIcon from '@mui/icons-material/Info'
import { useTranslation } from 'react-i18next'

interface MyModalProps {
  params: Readonly<Params<string>>
}
function MyModal({ params }: Readonly<MyModalProps>) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const [eligibleUsers, setEligibleUsers] = useState<ISiteUserDto[] | null>([])
  const [existingUsers, setExistingUsers] = useState<ISiteUserDto[] | null>([])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    const fetchSiteData = async () => {
      const siteId = params.siteId
      const siteData = await getSiteUser(siteId)
      setEligibleUsers(siteData.data.eligibleUsers ?? [])
      setExistingUsers(siteData.data.existingUsers ?? [])
    }

    fetchSiteData()
  }, [params])
  const [selectedUserIdEligible, setSelectedUserIdEligible] = useState<string | null>(null)
  const [selectedUserIdExisting, setSelectedUserIdExisting] = useState<string | null>(null)

  const handleSelectUserEligibleUsers = (userId: string): void => {
    setSelectedUserIdEligible((prev) => (prev === userId ? null : userId))
  }

  const handleSelectUserExistingUsers = (userId: string): void => {
    setSelectedUserIdExisting((prev) => (prev === userId ? null : userId))
  }

  const handleAddUser = async () => {
    const siteId = params.siteId
    if (!siteId) {
      return
    }

    if (selectedUserIdEligible) {
      const data: IRequestSiteUser = {
        SiteId: siteId,
        UserId: selectedUserIdEligible,
        ReturnUpdatedLists: true,
      }
      try {
        const response = await postUserSite(data)
        setEligibleUsers(response.data.eligibleUsers ?? [])
        setExistingUsers(response.data.existingUsers ?? [])
        setSelectedUserIdEligible(null)
      } catch (error) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possivel vincular o usuário.'),
        })
      }
    }
  }

  const handleRemoveUser = async () => {
    const siteId = params.siteId
    if (!siteId) {
      return
    }

    if (selectedUserIdExisting) {
      const data: IRequestSiteUser = {
        SiteId: siteId,
        UserId: selectedUserIdExisting,
        ReturnUpdatedLists: true,
      }
      try {
        const response = await deleteUserSite(data)
        setEligibleUsers(response.data.eligibleUsers ?? [])
        setExistingUsers(response.data.existingUsers ?? [])
        setSelectedUserIdExisting(null)
      } catch (error) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possivel desvincular o usuário.'),
        })
      }
    }
  }

  return (
    <>
      <Tooltip title={t('Ver Usuários com Acesso')}>
        <Button
          endIcon={<PersonIcon />}
          onClick={handleOpen}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          variant="outlined"
        >
          {t('Usuários')}
        </Button>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          style={{
            display: 'flex',
            backgroundColor: 'white',
            padding: '20px',
            width: '80%',
            overflowY: 'auto',
            flexDirection: 'column',
            maxHeight: '80%',
          }}
        >
          <Box style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Box style={{ width: '100%', minHeight: '300px' }}>
              <Typography variant="h6" style={{ marginBottom: '10px' }}>
                {t('Usuários Elegíveis')}
              </Typography>

              <Table style={{ border: '1px solid #e0e0e0', marginLeft: 0 }}>
                <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                  <TableRow>
                    <TableCell style={{ width: '5%' }}>#</TableCell>
                    <TableCell style={{ width: '65%' }}>{t('NOME')}</TableCell>
                    <TableCell style={{ width: '30%' }}>{t('NÍVEL DE ACESSO')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eligibleUsers && eligibleUsers.length > 0 ? (
                    eligibleUsers.map((user) => (
                      <TableRow
                        key={user.userId}
                        onClick={() => handleSelectUserEligibleUsers(user.userId)}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUserIdEligible === user.userId}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleSelectUserEligibleUsers(user.userId)
                            }}
                          />
                        </TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.accessLevel}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        {t('Nenhum usuário disponível')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',

                padding: '5vh 20px 0 20px',
              }}
            >
              <Button onClick={handleAddUser} disabled={!selectedUserIdEligible}>
                {t('Adicionar')} →
              </Button>
              <Button onClick={handleRemoveUser} disabled={!selectedUserIdExisting}>
                ← {t('Remover')}
              </Button>
            </Box>
            <Box style={{ width: '100%', minHeight: '300px' }}>
              <Typography variant="h6" style={{ marginBottom: '10px' }}>
                {t('Usuários Vinculados')}
              </Typography>
              <Table style={{ border: '1px solid #e0e0e0', marginLeft: 0 }}>
                <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                  <TableRow>
                    <TableCell style={{ width: '5%' }}>#</TableCell>
                    <TableCell style={{ width: '65%' }}>{t('NOME')}</TableCell>
                    <TableCell style={{ width: '30%' }}>{t('NÍVEL DE ACESSO')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {existingUsers && existingUsers.length > 0 ? (
                    existingUsers?.map((user) => (
                      <TableRow
                        key={user.userId}
                        onClick={() => handleSelectUserExistingUsers(user.userId)}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUserIdExisting === user.userId}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleSelectUserExistingUsers(user.userId)
                            }}
                          />
                        </TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.accessLevel}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        {t('Nenhum usuário disponível')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <InfoIcon style={{ color: '#1976d2', marginRight: '5px' }} />

            <Typography variant="body2" style={{ color: '#757575' }}>
              {t(
                'Usuários com perfil ADMIN não são exibidos nesta lista, pois já tem acesso aos sítios de suas respectivas empresas.',
              )}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Voltar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default MyModal
