export const anomalyBarsSelection = [
  {
    value: 'estimatedHourlyPowerLossInMegawatt',
    label: 'Perda de Geração /h (MW)',
  },
  {
    value: 'countSubtotal',
    label: 'Total',
  },
  {
    value: 'countPercentage',
    label: 'Porcentagem',
  },
]

export const defaultDashboardData = {
  severityGraph: {
    totalAnomalies: 0,
    anomalySeverities: [],
  },
  inspections: [],
  anomalyTypeBars: [],
  sitesMap: [],
  globalBenchmark: [],
}

export const defaultInspectionData = {
  infos: {
    siteId: 'teste',
    siteName: 'teste',
    inspectionDate: new Date('2000-01-01T00:00:00'),
    inspectionDescription: 'description',
    currencySymbol: '€',
  },
  severityGraph: {
    totalAnomalies: 0,
    anomalySeverities: [],
  },
  inspections: [],
  anomalyTypeBars: [],
  sitesMap: [],
  comparativeBenchmark: [],
  anomalyTypesImpactList: [],
}

export const COLORS = [
  '#06C',
  '#4CB140',
  '#009596',
  '#5752D1',
  '#F4C145',
  '#EC7A08',
  '#C9190B',
  '#B8BBBE',
  '#004B95',
  '#38812F',
  '#005F60',
  '#3C3D99',
  '#F0AB00',
  '#C46100',
  '#A30000',
  '#8A8D90',
  '#002F5D',
  '#23511E',
  '#003737',
  '#2A265F',
  '#C58C00',
  '#8F4700',
  '#7D1007',
  '#6A6E73',
  '#F7CE3D',
]

export const getColor = (value: string) => {
  switch (value) {
    case 'Alta':
      return '#FF3C3C'
    case 'Média':
      return '#F7CE3D'
    case 'Baixa':
      return '#3F8A05'
    default:
      break
  }
}
