import { Box, Paper } from '@mui/material'
import styled from 'styled-components'
import { theme } from '~/styles/theme'

export const MainWrapper = styled(Box)`
  padding: 0 10%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const Container = styled.div`
  padding: 0 10%;
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`

export const TitleReports = styled.h2`
  margin-top: 40px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
  color: var(--color-text);
`

export const SectionData = styled.div`
  margin: 0px;
`

export const ContainerGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const GridReports = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  justify-content: flex-start;
  margin: 0px 32px;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 730px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .container {
    width: 320px;
    height: 120px;
    background: var(--bg-login);
    border-radius: 8px;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: solid 1px rgba(0, 0, 0, 0.2);

    .anomalyModalButton {
      all: unset;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: white;
      border: none;
      padding: 24px;
      margin: 0;
      width: calc(320px - 48px); /* Ajusta a largura para compensar o padding */
      height: calc(120px - 48px);
      border-radius: 8px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }

    &:hover {
      opacity: 0.8;
    }

    div {
      margin-left: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      text-align: left;

      h3 {
        line-height: 24px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        color: ${(props) => props.theme.colors.lightBlack};
      }

      p {
        margin-top: 4px;
        line-height: 14px;
        font-size: 12px;
        font-weight: 300;
        display: flex;
        color: ${(props) => props.theme.colors.lightBlack};
      }
    }

    img {
      width: 40px;
      height: 40px;
    }
  }
`

export const SectionReports = styled.div`
  margin: 10x 0;
`

export const ContentSearch = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background: var(--bg-login);

  .flex-btns {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    input {
      width: 258px;
      height: 40px;

      border: 1px solid #dde5ec;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 0 7px;

      @media (max-width: 800px) {
        width: 100%;
        padding: 0 4%;
      }
    }

    button {
      width: 258px;
      height: 40px;
      background: ${(props) => props.theme.colors.primary};
      border-radius: 5px;
      color: white;
      border: 0;

      @media (max-width: 800px) {
        margin-top: 10px;
        width: 100%;
        padding: 0 4%;
      }
    }
  }

  table tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 60px;
    text-align: left;

    @media (max-width: 768px) {
      gap: 10px;
    }

    @media (max-width: 400px) {
      gap: 0;
    }

    th {
      font-family: 'Inter';
      font-style: bold;
      font-weight: 500;
      font-size: 16px;
      line-height: 15px;
      color: #333333;
    }

    td {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 15px;
      margin-top: 10px;
    }
  }

  .border {
    color: #dde5ec !important;
    border: 0.1px solid #dde5ec;
    margin: 30px 0;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const StyleBox = styled.div`
  position: absolute;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 16px;
  background-color: ${theme.colors.white};
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 16px;
`

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 5px;
  margin-bottom: 24px;

  p {
    font-size: 24px;
  }

  span {
    cursor: pointer;
  }
`
export const MainModal = styled.div`
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 40px 24px 40px;
`

export const ContainerSearch = styled.div`
  display: flex;
  gap: 20px;

  width: 100%;
`

export const SelectsSearch = styled(Box)`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
`

export const SelectSearchInputs = styled(Box)`
  display: flex;
  gap: 20px;
`

export const ContainerFilter = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  button {
    border: none;
    background: none;

    width: auto;
    height: auto;
  }

  .i {
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;

    &:hover {
      color: ${theme.colors.black};
    }
  }

  .iconBlocked {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }

  .iconDisable {
    opacity: 0.5;
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
  }

  .iconDisableBlocked {
    opacity: 0.5;
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }
`
