import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { IInspectionsProps } from '~/services/Dashboard/types'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment'
import { globalUnitFormatter } from '~/utils/Mask'
import { useTranslation } from 'react-i18next'
import * as S from '../../styles'
import { Link } from 'react-router-dom'
import { Order, StyledTableRow, getComparator, stableSort } from '~/utils'

type AllInspectionsComponentProps = {
  inspections: IInspectionsProps[]
}

const AllInspectionsComponent = ({ inspections = [] }: AllInspectionsComponentProps) => {
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState<Order>('asc')
  const [page, setPage] = useState(0)
  const { i18n, t } = useTranslation()

  const headCells = [
    {
      label: t('Inspeção'),
      value: 'siteName',
    },
    {
      label: t('Potência Inspecionado MWp'),
      value: 'inspectedPower',
    },
    {
      label: t('Qtd Perda por Ano MWp'),
      value: 'estimatedYearlyPowerLossInMegawatt',
    },
    {
      label: t('Anomalias'),
      value: 'anomalyQuantity',
    },
    {
      label: t('Data de Inspeção'),
      value: 'inspectionDate',
    },
  ]

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof IInspectionsProps) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler =
    (property: keyof IInspectionsProps) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property)
    }

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage)
  }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * 10 - (inspections.length ?? 0)) : 0

  const visibleRows = useMemo(() => {
    if (inspections)
      return stableSort(inspections, getComparator(order, orderBy)).slice(page * 10, page * 10 + 10)
    return []
  }, [order, orderBy, page, inspections])

  return (
    <Paper sx={{ width: '100%', padding: '0 20px', paddingTop: '20px' }}>
      <Box sx={{ marginBottom: '20px' }}>
        <h4>{t('Todas as inspeções')}</h4>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
          <TableHead>
            <TableRow>
              {headCells.map((headCell, i) => (
                <TableCell
                  key={headCell.value}
                  align={i === 0 ? 'left' : 'right'}
                  padding="normal"
                  sortDirection={orderBy === headCell.value ? order : false}
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: '#dbe1ee',
                    borderStartEndRadius: i === headCells.length - 1 ? '4px' : '0',
                    borderStartStartRadius: i === 0 ? '4px' : '0',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.value}
                    direction={orderBy === headCell.value ? order : 'asc'}
                    onClick={createSortHandler(headCell.value as keyof IInspectionsProps)}
                  >
                    {headCell.label}
                    {orderBy === headCell.value ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <StyledTableRow hover tabIndex={-1} key={row.inspectionId} sx={{ cursor: 'pointer' }}>
                  <S.TableCell component="th" id={labelId} scope="row">
                    <Link to={`/inspection/${row.inspectionId}`}>{row.siteName}</Link>
                  </S.TableCell>
                  <TableCell align="right">
                    {globalUnitFormatter(Number(row.inspectedPower.toFixed(2)), i18n.language)}
                  </TableCell>
                  <TableCell align="right">
                    {globalUnitFormatter(row.estimatedYearlyPowerLossInMegawatt, i18n.language)} MWp
                  </TableCell>
                  <TableCell align="right">
                    <h4>{row.anomalyQuantity}</h4>
                  </TableCell>
                  <TableCell align="right">
                    {moment(row.inspectionDate).format(
                      `DD MMM${i18n.language === 'ES' ? '' : ','} YYYY`,
                    )}
                  </TableCell>
                </StyledTableRow>
              )
            })}
            {emptyRows > 0 && (
              <StyledTableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={5} />
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={inspections.length ?? 0}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  )
}

export default AllInspectionsComponent
