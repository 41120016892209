import React from 'react'

import { Search } from '@mui/icons-material'
import { TextField, InputAdornment, OutlinedTextFieldProps } from '@mui/material'

import * as S from './styles'

interface InputSearchProps extends OutlinedTextFieldProps {}

const InputSearch: React.FC<InputSearchProps> = (props: InputSearchProps) => {
  return (
    <S.Container>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {' '}
              <Search className="icon" />{' '}
            </InputAdornment>
          ),
        }}
        size="small"
        {...props}
      />
    </S.Container>
  )
}

export default InputSearch
