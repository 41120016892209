import styled from 'styled-components/macro'
import { InputWrapperProps, MainModalProps } from './types'
import { Box } from '@mui/material'
import { theme } from '~/styles/theme'

export const Container = styled.div`
  margin: 0 10%;
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`

export const FlexWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 50px;
  display: flex;
  flex: 1;
  flex-direction: row;
`

export const StyleBox = styled.div`
  padding: 24px 0px;

  flex-grow: 1;
  display: flex;
  flex-direction: row;
  background-color: red;
`

export const MainModal = styled.div<MainModalProps>`
  width: 100%;
  padding: 20px 16px;
  background-color: white;
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 16px;
  height: ${(props) => (props.edit ? '1100px' : '930px')};

  p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }
`

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 5px;
  margin-bottom: 24px;

  p {
    font-size: 24px;
  }

  span {
    cursor: pointer;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 40px 0px 40px;
`

export const Form = styled.form`
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`

export const Label = styled.label`
  color: #5a5c5e;
  display: flex;
  flex-direction: column;
`

export const Input = styled.input`
  /* padding-left: 15px; */
  padding: 10px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #c5c5c5;
  width: 100%;
  background-color: #f7f7f7;
  height: 40px;

  &:focus {
    border: 1px solid #f89722;
  }

  &[type='file'] {
    border: none;
    display: flex;
    align-items: center;
  }
`

export const TextArea = styled.textarea`
  padding: 10px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #c5c5c5;
  gap: 15;
  width: 100%;
  background-color: #f7f7f7;
  height: 135px;
  font-family: 'open sans', sans-serif;
  resize: none;

  &:focus {
    border: 1px solid #f89722;
  }

  &[type='file'] {
    border: none;
    display: flex;
    align-items: center;
  }
`

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  font-family: 'open sans', sans-serif;
  font-size: 14px;
  color: #393939;
  width: ${(props) => props.width ?? '100%'};

  .MuiTextField-root,
  &.MuiAutocomplete-root {
    background-color: ${theme.colors.white};
  }
`

export const AutocompleteWrapper = styled(Box)`
  width: 20%;
  .MuiAutocomplete-root {
    background-color: ${theme.colors.white};
  }
`

export const FloatingLabel = styled.label`
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 0 4px;
  background-color: #f7f7f7;
  transition: all 0.3s;
  pointer-events: none;
  color: #8c9aa4;
  font-family: 'open sans', sans-serif;

  ${TextArea}:focus ~ & {
    top: -10px;
    left: 5px;
    font-size: 12px;
    color: #f89722;
  }

  ${TextArea}:not(:placeholder-shown) ~ & {
    top: -10px;
    left: 5px;
    font-size: 12px;
  }

  ${Input}:focus ~ & {
    top: -10px;
    left: 5px;
    font-size: 12px;
    color: #f89722;
  }

  ${Input}:not(:placeholder-shown) ~ & {
    top: -10px;
    left: 5px;
    font-size: 12px;
  }
`

export const WarningLabel = styled.div`
  color: red;
`

export const Text = styled.p``

export const Flex = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  height: 40px;
`
