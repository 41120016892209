import { Box, Paper } from '@mui/material'
import styled from 'styled-components'

export const RemoveAnomalyWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`

export const InputWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 20px;
`
