import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 16px;

  svg {
    color: ${props => props.theme.colors.primary}
  }
`

export const Message = styled.h1`
  flex: 1;
  margin-bottom: 56px;
  margin-top: 28px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
`
