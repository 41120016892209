import { Autocomplete, Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISelectOptionsProps } from '~/components/Map/types'
import { IAnomaliePageInfoProps } from '~/services/Anomaly/types'
import * as S from './styles'

type SearchProps = {
  stringsData: any
  anomalyPageInfo: IAnomaliePageInfoProps | null
  setFilterSearch(value: string): void
  getAnomalyList: (inspedtionId: string, filter?: string, preservData?: boolean) => Promise<void>
  currentInspectionId: string
}

const Search = ({
  stringsData,
  anomalyPageInfo,
  setFilterSearch,
  getAnomalyList,
  currentInspectionId,
}: SearchProps) => {
  const { t } = useTranslation()

  const [localizacaoOptions, setLocalizacaoOptions] = useState<ISelectOptionsProps[]>([])
  const [localizacaoValue, setLocalizacaoValue] = useState<ISelectOptionsProps | null>(null)
  const [anomaliaValues, setAnomaliaValues] = useState<ISelectOptionsProps[]>([])
  const [severidadeValues, setSeveridadeValues] = useState<ISelectOptionsProps[]>([])
  const [estadoValues, setEstadoValues] = useState<ISelectOptionsProps[]>([])

  const handleFilter = (value: ISelectOptionsProps[], alias: string) => {
    return value
      .map((item) => `&${alias}=${item.value}`)
      .toString()
      .replaceAll(',', '')
  }

  const resetFilters = () => {
    setLocalizacaoValue(null)
    setAnomaliaValues([])
    setSeveridadeValues([])
    setEstadoValues([])
  }

  const handleSearchAnomaly = () => {
    let filter = ''

    filter += localizacaoValue && '&Local=' + localizacaoValue.label
    filter += anomaliaValues && handleFilter(anomaliaValues, 'AnomalyTypes')
    filter += severidadeValues && handleFilter(severidadeValues, 'Severities')
    filter += estadoValues && handleFilter(estadoValues, 'Statuses')

    setFilterSearch(filter)
    getAnomalyList(currentInspectionId, filter)
  }

  const getSelectOptions = (
    value: IAnomaliePageInfoProps,
    property: 'anomalyTypes' | 'anomalyStatuses' | 'anomalySeverities',
  ): ISelectOptionsProps[] => {
    return value[property].map((anomaly) => ({
      label: t(`${anomaly.name}`),
      value: anomaly.id,
    }))
  }

  useEffect(() => {
    if (Object.keys(stringsData).length > 0) {
      let tempOptionsData: any[] = []

      stringsData.features.map((feature: any) => {
        tempOptionsData.push({
          label: feature.properties.stringName,
          value: feature.properties.stringId,
        })
      })

      setLocalizacaoOptions(tempOptionsData)
    }
  }, [stringsData])

  return (
    <S.SearchWrapper>
      <S.Inputs>
        <Autocomplete
          id="localizacao"
          size="small"
          disablePortal
          fullWidth
          options={localizacaoOptions}
          value={localizacaoValue}
          onChange={(_, newValue) => setLocalizacaoValue(newValue)}
          renderInput={(params) => <TextField {...params} label={t('Localização')} />}
        />

        <Autocomplete
          size="small"
          multiple
          fullWidth
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={anomaliaValues}
          id="anomalia"
          options={anomalyPageInfo ? getSelectOptions(anomalyPageInfo, 'anomalyTypes') : []}
          onChange={(_, newValue) => setAnomaliaValues(newValue)}
          renderInput={(params) => <TextField {...params} label={t('Anomalia')} />}
        />

        <Autocomplete
          size="small"
          multiple
          fullWidth
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={severidadeValues}
          id="severidade"
          options={anomalyPageInfo ? getSelectOptions(anomalyPageInfo, 'anomalySeverities') : []}
          onChange={(_, newValue) => setSeveridadeValues(newValue)}
          renderInput={(params) => <TextField {...params} label={t('Severidade')} />}
          getOptionLabel={(option) => t(option.label)}
          renderOption={(props, option) => <li {...props}>{t(option.label)}</li>}
        />

        <Autocomplete
          size="small"
          multiple
          fullWidth
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={estadoValues}
          id="estado"
          options={anomalyPageInfo ? getSelectOptions(anomalyPageInfo, 'anomalyStatuses') : []}
          onChange={(_, newValue) => setEstadoValues(newValue)}
          renderInput={(params) => <TextField {...params} label={t('Estado')} />}
          getOptionLabel={(option) => t(option.label)}
          renderOption={(props, option) => <li {...props}>{t(option.label)}</li>}
        />
      </S.Inputs>
      <S.Buttons>
        <Button color="error" onClick={resetFilters}>
          {t('Limpar Filtros')}
        </Button>
        <Button variant="contained" onClick={() => handleSearchAnomaly()}>
          {t('Buscar')}
        </Button>
      </S.Buttons>
    </S.SearchWrapper>
  )
}

export default Search
