import { getJson, postJson, putJson } from '../RequestService'
import { IInspection, InputOthersArray, UpdateValue, type ValueToSend } from './types'

const defaultRoot = 'configuration'

export const getSettings = async () => {
  let url = `${defaultRoot}/systemdefaultvalues`
  return await getJson<IInspection>(url)
}

export const patchSettings = async (data: ValueToSend[]) => {
  let url = `${defaultRoot}/systemdefaultvalues`
  return await putJson(url, data)
}

export const getOthersSettings = async () => {
  let url = `${defaultRoot}/flag`
  return await getJson<InputOthersArray>(url)
}

export const postSettingsFlag = async (data: UpdateValue) => {
  let url = `${defaultRoot}/flag`
  return await postJson(url, data)
}
