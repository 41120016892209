import * as S from '../../styles'
import { Cell, Pie, PieChart } from 'recharts'
import { COLORS } from '../../utils'
import { Box } from '@mui/material'
import { IAnomalyTypeBarsProps } from '~/services/Dashboard/types'
import { useTranslation } from 'react-i18next'

type PieChartComponentProps = {
  anomalyTypeBars: IAnomalyTypeBarsProps[]
}

const PieChartComponent = ({ anomalyTypeBars = [] }: PieChartComponentProps) => {
  const { t } = useTranslation()

  return (
    <S.PieChartWrapper>
      <h4 className="title">{t('Anomalias')}</h4>
      <S.PieChartContent>
        <S.Pie>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              width: '100%',
              height: 300, 
              marginBottom: 2,
            }}
          >
            <PieChart width={300} height={300}>
              <Pie
                data={anomalyTypeBars}
                cx="50%"
                cy="50%"
                fill="#8884d8"
                outerRadius={120}
                innerRadius={80}
                paddingAngle={5}
                dataKey="countPercentage"
                legendType="line"
              >
                {anomalyTypeBars.map((anomaly, index) => (
                  <Cell
                    key={`cell-${anomaly.name}`}
                    fill={index < COLORS.length ? COLORS[index] : COLORS[index - COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart> 

            <S.CenterLegend>
              <h2>{anomalyTypeBars.reduce((acc, value) => acc + value.countSubtotal, 0)}</h2>
              <p>{t('Anomalias')}</p>
            </S.CenterLegend>
          </Box>
        </S.Pie>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: 1,
            height: 300,
            overflowY: 'auto',
            marginTop: 2,
            marginRight: "20px" 
          }}
        >
          {anomalyTypeBars.map((anomaly, index) => (
            <S.SeverityValues key={anomaly.name}>
              <Box
                sx={{
                  backgroundColor: index < COLORS.length ? COLORS[index] : COLORS[index - COLORS.length],
                  width: 20,
                  height: 20,
                  display: 'inline-block',
                  marginRight: 1,
                }}
                className="square"
              />
              <S.PieChartInfoWrapper>
                <p>{t(anomaly.name)}</p>
                <h4>{anomaly.countSubtotal}</h4>
                <>{anomaly.countPercentage.toFixed(2)}%</>
              </S.PieChartInfoWrapper>
            </S.SeverityValues>
          ))}
        </Box>
      </S.PieChartContent>
    </S.PieChartWrapper>
  )
}

export default PieChartComponent
