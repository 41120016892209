/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'

import { InputAdornment, TextField } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { useTranslation } from 'react-i18next'
import Icon from '~/assets/icons'
import LoginImage from '~/assets/images/loginBackground.png'
import LogoSolar from '~/assets/images/logoSolarInspec.png'
import Button from '~/components/Button'
import ToastNotification from '~/components/ToastNotification'
import { postChangePassword, postSendCode } from '~/services/RecoverPassword'
import { IChangePassword } from '~/services/RecoverPassword/types'
import * as S from './styles'
import { RecoveryPassword, RecoveryPasswordError } from './types'
import { emailSendingValidation, resetPasswordValidation } from './validations'

export default function RecoverPassword() {
  const [registration, setRegistration] = useState<RecoveryPassword>(new RecoveryPassword());
  const [registrationError, setRegistrationError] = useState<RecoveryPasswordError>(new RecoveryPasswordError());
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { token } = useParams();
  const { t } = useTranslation();

  const handleEmailSending = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await emailSendingValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RecoveryPasswordError);
        setLoading(false);
        return;
      }
      const response = await postSendCode({
        email: registration.email
      });
      if (response.success) {
        setEmailSent(true);
        setRegistration({ ...registration, email: '' });
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao realizar o envio do e-mail, tente novamente.'),
          errorMessage: response.errorDetails,
          errors: response.errors
        });
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao realizar o envio do e-mail, tente novamente.')
      });
    }
    setLoading(false);
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await resetPasswordValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RecoveryPasswordError);
        setLoading(false);
        return;
      }
      const body: IChangePassword = {
        email: registration.email,
        password: registration.password,
        code: token
      };
      const response = await postChangePassword(body);
      if (response.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Senha redefinida com sucesso!')
        });
        navigate('/login');
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao redefinir a senha, tente novamente.'),
          errorMessage: response.errorDetails,
          errors: response.errors
        });
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao redefinir a senha, tente novamente.')
      });
    }
    setLoading(false);
  };

  const onEnterDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (token) {
        handleResetPassword();
      } else {
        handleEmailSending();
      }
    }
  };

  const handleSendToLogin = () => {
    navigate('/login');
  };

  const renderEmail = () => {
    return (
      <>
        <span>{t('Para prosseguir com a redefinição da senha, insira abaixo o e-mail cadastrado na sua conta:')}</span>
        <TextField
          label={t('E-mail')}
          type="email"
          value={registration.email}
          onChange={(e) => setRegistration({ ...registration, email: e.target.value })}
          onKeyDown={onEnterDown}
          size="small"
          fullWidth
          error={registrationError.email ? true : false}
          helperText={registrationError.email}
          onClick={() => setRegistrationError({ ...registrationError, email: '' })}
          style={{ marginTop: '11%' }}
        />
        <Button type="button" onClick={handleEmailSending} loading={loading} label={t('Enviar')} />
      </>
    );
  };

  const renderConfirmationEmail = () => {
    return (
      <>
        <span>{t('O e-email de recuperação de senha foi enviado para o endereço de e-mail informado. Caso você não encontre o e-mail de recuperação, verifique sua caixa de spam ou lixo eletrônico.')}</span>
        <div style={{ marginTop: '5%', marginBottom: '5%' }}>
          <Icon name="Email" width={60} height={60} fill={theme.colors.primary} />
        </div>
        <Button type="button" onClick={handleSendToLogin} loading={loading} label={t('Voltar para Login')} />
      </>
    );
  };

  const renderChangePassword = () => {
    return (
      <>
        <span>{t('Crie sua nova senha, para uma maior segurança, a senha deve ter ao menos 8 caracteres, letra maiúscula, números e símbolos')}</span>
        <TextField
          label={t('E-mail')}
          type="email"
          value={registration.email}
          onChange={(e) => setRegistration({ ...registration, email: e.target.value })}
          onKeyDown={onEnterDown}
          size="small"
          fullWidth
          error={registrationError.email ? true : false}
          helperText={registrationError.email}
          onClick={() => setRegistrationError({ ...registrationError, email: '' })}
          style={{ marginTop: '11%' }}
        />
        <TextField
          type={showPassword ? 'text' : 'password'}
          label={t('Senha')}
          size="small"
          value={registration.password}
          onChange={(e) => setRegistration({ ...registration, password: e.target.value })}
          error={registrationError.password ? true : false}
          helperText={t(registrationError.password)}
          onClick={() => setRegistrationError({ ...registrationError, password: '' })}
          onKeyDown={onEnterDown}
          sx={{
            marginTop: 2,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => setShowPassword((showPassword) => !showPassword)}
              >
                {showPassword ? (
                  <Icon name="Visibility" fill={theme.colors.icon} />
                ) : (
                  <Icon name="VisibilityOff" fill={theme.colors.icon} />
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <TextField
          type={showPasswordConfirmation ? 'text' : 'password'}
          label={t('Confirmação de senha')}
          size="small"
          value={registration.confirmationPassword}
          onChange={(e) => setRegistration({ ...registration, confirmationPassword: e.target.value })}
          error={registrationError.confirmationPassword ? true : false}
          helperText={t(registrationError.confirmationPassword)}
          onClick={() => setRegistrationError({ ...registrationError, confirmationPassword: '' })}
          onKeyDown={onEnterDown}
          sx={{
            marginTop: 2,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => setShowPasswordConfirmation((showPasswordConfirmation) => !showPasswordConfirmation)}
              >
                {showPasswordConfirmation ? (
                  <Icon name="Visibility" fill={theme.colors.icon} />
                ) : (
                  <Icon name="VisibilityOff" fill={theme.colors.icon} />
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <Button type="button" onClick={handleResetPassword} loading={loading} label={t('Redefinir Senha')} />
      </>
    );
  };

  const renderBody = () => {
    if (token) {
      return renderChangePassword();
    } else {
      if (emailSent) return renderConfirmationEmail();
      else return renderEmail();
    }
  };

  return (
    <S.Container>
      <img src={LoginImage} alt="Imagem de login Solar" />

      <S.ContentRecoverPassword>
        <img src={LogoSolar} alt="Logo Solar" />
        <h1>{t('Recuperação de Senha')}</h1>
        {renderBody()}
      </S.ContentRecoverPassword>
    </S.Container>
  )
}
