import { ObjectSchema, setLocale } from 'yup';
import { Assign, ObjectShape } from 'yup/lib/object';

import { LOCALE_YUP_BR } from '../assets/Constants';

setLocale(LOCALE_YUP_BR);

interface ICatchValidate {
  path: string
  message: string
}

export const genericValidation = async <T, L>(schema: ObjectSchema<Assign<ObjectShape, any>>, object: T, ErrorClass: any): Promise<L> => {
  const validate: boolean | ICatchValidate[] = await schema.validate(object, { abortEarly: false })
    .then(() => true)
    .catch((err) => err.inner);

  if (typeof validate !== 'boolean') validate.forEach((item) => { ErrorClass[item.path] = item.message; });

  return ErrorClass;
};
