import '~/components/translate/i18n'
import * as S from './styles'
import BaseLayout from '~/Layouts/BaseLayout'
import ToastNotification from '~/components/ToastNotification'
import SummaryComponent from './components/Summary'
import AnomalieSeverityComponent from './components/AnomalieSeverity'
import AllInspectionsComponent from './components/AllInspections'
import PieChartComponent from './components/AnomaliesPieChart'
import AllAnomaliesEffectComponent from './components/AllAnomaliesEffect'
import OlderInspectionsComponent from './components/OlderInspections'
import BenchmarkComponent from './components/Benchmark'
import { getDashboardDataService } from '~/services/Dashboard'
import { defaultDashboardData } from './utils'
import { useEffect, useState } from 'react'
import { IDashboardProps } from '~/services/Dashboard/types'
import { useTranslation } from 'react-i18next'
import DashboardMap from './components/Map'
import { Skeleton } from '@mui/material'


export default function Home() {
  const { t } = useTranslation()
  const [dashboardData, setDashboardData] = useState<IDashboardProps>(defaultDashboardData)
  const [loading, setLoading] = useState(true)

  const getDashboardData = async () => {
    try {
      const resp = await getDashboardDataService()
      if (resp.success) {
        setDashboardData(resp.data)
        setLoading(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: resp.errorDetails,
          errors: resp.errors,
        })
        setLoading(false)
      }
    } catch (e) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
      setLoading(false)
      throw e
    }
  }

  useEffect(() => {
    getDashboardData()
  }, [])


  if (loading) {
    return (
      <BaseLayout title={t('Dashboard')}>
        <S.ContentWrapper>
          <S.HeaderContent>
            <Skeleton variant="rectangular" height={150} width="100%" />
            <Skeleton variant="rectangular" height={150} width="100%" />
          </S.HeaderContent>
          <S.AllInspectionsTable>
            <Skeleton variant="rectangular" height={700} width="100%" />
          </S.AllInspectionsTable>
          <S.EqualSizes>
            <Skeleton variant="rectangular" height={480} width="100%" />
            <Skeleton variant="rectangular" height={480} width="100%" />
          </S.EqualSizes>
          <S.EqualSizes>
            <Skeleton variant="rectangular" height={380} width="100%" />
            <Skeleton variant="rectangular" height={380} width="100%" />
          </S.EqualSizes>
          <Skeleton variant="rectangular" height={400} width="100%" />
        </S.ContentWrapper>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout title={t('Dashboard')}>
      <S.ContentWrapper>
        <S.HeaderContent>
          <SummaryComponent summary={dashboardData?.summary} dataType="dashboard" />
          <AnomalieSeverityComponent severityGraph={dashboardData.severityGraph} />
        </S.HeaderContent>
        <S.AllInspectionsTable>
          <AllInspectionsComponent inspections={dashboardData.inspections} />
        </S.AllInspectionsTable>
        <S.EqualSizes>
          <DashboardMap
            sitesMap={dashboardData.sitesMap.filter((site) => site.latitude && site.longitude)}
          />
          <OlderInspectionsComponent sitesMap={dashboardData.sitesMap} />
        </S.EqualSizes>
        <S.OneSize>
          <PieChartComponent anomalyTypeBars={dashboardData.anomalyTypeBars} />
        </S.OneSize>
        <AllAnomaliesEffectComponent anomalyTypeBars={dashboardData.anomalyTypeBars} />
        <BenchmarkComponent globalBenchmark={dashboardData.globalBenchmark} />
      </S.ContentWrapper>
    </BaseLayout>
  )

}
