import MaterialModal from '~/components/Material/Modal'
import {
  IAnomaliePageInfoItemProps,
  IAnomaliePageInfoProps,
  IAnomalyLIstProps,
} from '~/services/Anomaly/types'
import * as S from './styles'
import { Box, Typography } from '@mui/material'
import { dadosStatus, handleSeverityIcon } from '../../utils'
import { useTranslation } from 'react-i18next'

type ImageModalProps = {
  handleCloseModal(): void
  isOpen: boolean
  imageDetails: IAnomalyLIstProps | null | undefined
  anomalyPageInfo: IAnomaliePageInfoProps | null
}

const ImageModal = ({ handleCloseModal, isOpen, imageDetails, anomalyPageInfo }: ImageModalProps) => {
  const { t } = useTranslation()
  return (
    <MaterialModal isOpen={isOpen} handleClose={handleCloseModal}>
      {imageDetails && (
        <S.ModalWrapper>
          <img src={imageDetails.imageUrl} alt={imageDetails.name} />
          <S.ModalContent>
            <Typography variant="h6" component="h3" fontWeight="bold" >
              {`${t('Detalhes da imagem')} #${imageDetails.code}`}
            </Typography>
            <S.ModalItemContent>
              <Typography variant="subtitle1" component="h5" fontWeight="bold">
                {t('Nome')}:
              </Typography>

              <Box sx={{ ml: 1 }}>{t(imageDetails.name)}</Box>
            </S.ModalItemContent>
            <S.ModalItemContent>
              <Typography variant="subtitle1" component="h5" fontWeight="bold">
                {t('Localização')}:
              </Typography>
              <Box sx={{ ml: 1 }}>{imageDetails.local}</Box>
            </S.ModalItemContent>
            <S.ModalItemContent>
              <Typography variant="subtitle1" component="h5" fontWeight="bold">
                Delta T:
              </Typography>
              <Box sx={{ ml: 1 }}>{imageDetails.deltaTemperature.toFixed(2)}</Box>
            </S.ModalItemContent>
            <S.ModalItemContent>
              <Typography variant="subtitle1" component="h5" fontWeight="bold">
                {t('Severidade')}:
              </Typography>
              <S.AlignCenter>
                {handleSeverityIcon(imageDetails.severity)}
                {t(
                  (`Severidade_.` +
                    anomalyPageInfo?.anomalySeverities.find(
                      (severity) => severity?.id === imageDetails.severity,
                    )?.name),
                )}
              </S.AlignCenter>
            </S.ModalItemContent>
            <S.ModalItemContent>
              <Typography variant="subtitle1" component="h5" fontWeight="bold">
                {t('Estado')}:
              </Typography>
              <S.AlignCenter>
                {
                  dadosStatus(
                    anomalyPageInfo?.anomalyStatuses.filter(
                      (status) => status?.id === imageDetails.status,
                    )[0] as IAnomaliePageInfoItemProps,
                  ).icon
                }
                {t(
                  dadosStatus(
                    anomalyPageInfo?.anomalyStatuses.filter(
                      (status) => status?.id === imageDetails.status,
                    )[0] as IAnomaliePageInfoItemProps,
                  ).status
                )}
              </S.AlignCenter>
            </S.ModalItemContent>
          </S.ModalContent>
        </S.ModalWrapper>
      )}
    </MaterialModal>
  )
}

export default ImageModal
