import { Pending, Check, QueryBuilder, Block, Error } from '@mui/icons-material'
import { IAnomaliePageInfoItemProps } from '~/services/Anomaly/types'

export const handleSeverityIcon = (status: number) => {
  switch (status) {
    case 1:
      return <Error color="success" />
    case 2:
      return <Error color="warning" />
    case 3:
      return <Error color="error" />
    default:
      return <Error color="disabled" />
  }
}

export const dadosStatus = (statusAnomalia: IAnomaliePageInfoItemProps) => {
  return {
    value: statusAnomalia?.id,
    status: statusAnomalia?.name ?? 'defaultStatus',
    icon: getIcon(statusAnomalia?.name),
  }
}

export const getIcon = (anomalyname: string) => {
  switch (anomalyname) {
    case 'Pendente':
      return <Pending color="action" />
    case 'Resolvido':
      return <Check color="action" />
    case 'Em Progresso':
      return <QueryBuilder color="action" />
    case 'Não Encontrado':
      return <Block color="action" />
    case 'Falso Positivo':
      return <Error color="action" />
    default:
      return null
  }
}
