import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from './customTypography';

export const CustomTooltipContent = () => {
  const { t } = useTranslation();

  const textSections = [
    { key: "Aguardando Imagens", bold: true, marginTop: "0px" },
    { key: "O sistema está à espera do envio de imagens da inspeção.", bold: false, marginTop: "0px" },
    { key: "Aguardando Análise", bold: true, marginTop: "8px" },
    { key: "Estamos aguardando a equipe analisar e validar o padrão de uma pequena quantidade de imagens.", bold: false, marginTop: "0px" },
    { key: "Imagens em Análise", bold: true, marginTop: "8px" },
    { key: "Estamos analisando minuciosamente suas imagens, a fim de encontrar possíveis anomalias.", bold: false, marginTop: "0px" },
    { key: "Aguardando Finalização", bold: true, marginTop: "8px" },
    { key: "Vamos finalizar todo o processo assim que estiver tudo pronto.", bold: false, marginTop: "0px" },
    { key: "Completo.", bold: true, marginTop: "8px" },
    { key: "Todo o processo realizado na inspeção foi concluído.", bold: false, marginTop: "0px" }
  ];

  return (
    <Box style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      wordWrap: 'break-word',
      whiteSpace: 'normal'
    }}>
      {textSections.map(section => (
        <CustomTypography key={section.key} bold={section.bold} marginTop={section.marginTop}>
          {t(section.key)}
        </CustomTypography>
      ))}
    </Box >
  );
};
