import React from 'react'
import Button from '../Button'
import Icon from '~/assets/icons'

interface CustomButtonProps {
  label: string
  onClick: () => void
}

const CustomButton: React.FC<CustomButtonProps> = ({ label, onClick }) => {
  return (
    <Button
      iconStart={<Icon name="Add" fill="white" />}
      type="button"
      onClick={onClick}
      label={label}
      style={{ marginTop: 0, marginLeft: '24px', width: '230px' }}
    />
  )
}

export default CustomButton
