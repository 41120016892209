// ListaToggle.tsx
import InfoIcon from '@mui/icons-material/Info'
import React from 'react'
import { AnomaliesCountDTO } from '~/services/Anomaly/types'
import { theme } from '~/styles/theme'
import { getAnomalyColor } from '~/utils/Anomalies'

interface ListaToggleProps {
  isListVisible: boolean
  toggleList: () => void
  listItems: AnomaliesCountDTO[] | undefined
}

const ListaToggle: React.FC<ListaToggleProps> = ({ isListVisible, toggleList, listItems }) => {
  const buttonStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    border: 'none',
    cursor: 'pointer',
  }

  return (
    <div style={{ position: 'absolute', left: '10px', top: '10px', zIndex: 1 }}>
      <button style={buttonStyle} onClick={toggleList} aria-pressed={isListVisible}>
        <InfoIcon style={{ color: theme.colors.link }} />
      </button>
      {isListVisible && listItems && (
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '5px',
            marginTop: '12px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            position: 'absolute',
            left: '0',
            top: '100%',
            width: '25vh',
          }}
        >
          <ul style={{ paddingLeft: '20px' }}></ul>
          {listItems.map((item: any) => (
            <li key={item.name} style={{ color: getAnomalyColor(item.name) }}>
              {item.count} - {item.name}
            </li>
          ))}
        </div>
      )}
    </div>
  )
}

export default ListaToggle
