import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Pagination, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { theme } from '~/styles/theme'
import { deleteSite, getSites } from '~/services/Site'
import { NoPhotography } from '@mui/icons-material'
import { useAuth } from '~/contexts/Auth'
import { ImageProps } from '~/services/Site/types'
import Button from '~/components/Button'
import moment from 'moment'
import Icon from '~/assets/icons'
import ViewError from '~/components/ViewError'
import InputSearch from '~/components/InputSearch'
import Select from 'react-select'
import ModalCustom from '~/components/ModalCustom'
import ToastNotification from '~/components/ToastNotification'
import * as S from './styles'
import ViewLoading from '~/components/ViewLoading'
import BaseLayout from '~/Layouts/BaseLayout'
import { MaterialIcon } from '~/assets/icons/materialIcons'
interface ISite {
  id: string
  coverImageUrl?: string
  coverImage: ImageProps
  name: string
  lastInspectionDate: string
  local: string
}

type FilterOption = { label: string; value: string } | null

export enum SiteSortingOrder {
  Alphabetic = 0,
  LastInspectionNewestFirst = 1,
  LastInspectionOldestFirst = 2,
  FirstInspectionNewestFirst = 3,
  FirstInspectionOldestFirst = 4,
}

export default function Sites() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { t } = useTranslation()

  const [siteId, setSiteId] = useState('')
  const [sites, setSites] = useState<ISite[]>([])
  const [nameOrLocal, setNameOrLocal] = useState('')
  const [filterSelected, setFilterSelected] = useState<FilterOption>(null)
  const [totalPages, setTotalPages] = useState<number | undefined>(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const loadSites = async (page: number = 1, clear: boolean = false, filter?: string) => {
    setLoading(true)
    try {
      if (clear) {
        setSites([])
      }

      const paginationAddresses = {
        page,
        pageSize: 6,
        NameOrLocal: nameOrLocal,
        filter: filter ?? filterSelected?.value,
      }

      const response = await getSites(paginationAddresses)
      if (response.success) {
        setCurrentPage(page)
        if (page === 1 && response.pages) {
          setTotalPages(response.pages)
        }
        setSites(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  const handleDeletarSite = (siteIdId: string) => {
    setSiteId(siteIdId)
    setOpenDeleteModal(true)
  }

  const handleDeleteSite = async () => {
    setLoading(true)
    try {
      const response = await deleteSite(siteId)
      if (response.success) {
        loadSites(1, true)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Sítio excluído com sucesso!'),
        })
        setOpenDeleteModal(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível excluir o site, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível excluir o site, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  type SelectOption = { label: string; value: string }

  const sortingOptions: SelectOption[] = [
    { label: t('Alfabético'), value: SiteSortingOrder.Alphabetic.toString() },
    {
      label: t('Última inspeção mais recente'),
      value: SiteSortingOrder.LastInspectionNewestFirst.toString(),
    },
    {
      label: t('Última inspeção mais antiga'),
      value: SiteSortingOrder.LastInspectionOldestFirst.toString(),
    },
    {
      label: t('Primeira inspeção mais recente'),
      value: SiteSortingOrder.FirstInspectionNewestFirst.toString(),
    },
    {
      label: t('Primeira inspeção mais antiga'),
      value: SiteSortingOrder.FirstInspectionOldestFirst.toString(),
    },
  ]

  useEffect(() => {
    loadSites(1, true)
  }, [])

  return (
    <BaseLayout
      title={t('Sítios')}
      extraContent={
        (user?.accessLevel === 1 || user?.accessLevel === 2) && (
          <Tooltip title={t('Cadastrar Sítio')}>
            <Box>
              <Button
                iconStart={<Icon name="Add" fill="white" />}
                type="button"
                onClick={() => navigate(`/sites/criar`)}
                label={t('Cadastrar Sítio')}
                style={{ marginTop: 0, marginLeft: '24px', width: '230px' }}
              />
            </Box>
          </Tooltip>
        )
      }
    >
      <S.FlexWrapper>
        <S.ContainerFilter style={{ margin: "40px 10% 40px 10%", width: "80%" }}>
          <S.ContainerButton>
            <Select
              className="w-full"
              menuPosition="fixed"
              name={t('Selecione o Sítio') ?? 'Selecione o Sítio'}
              placeholder={t('Selecione o Sítio') ?? 'Selecione o Sítio'}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  flex: 1,
                  minWidth: 300,
                  height: 40,
                  borderColor: state.isFocused ? theme.colors.primary : theme.colors.blueRGBA3,
                  boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(32, 168, 216, 0.25)' : '',
                  cursor: 'pointer',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  padding: '8px',
                }),
              }}
              noOptionsMessage={() => t('Nenhuma opção disponível')}
              value={filterSelected}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setFilterSelected(selectedOption)
                  loadSites(1, true, selectedOption.value)
                } else {
                  setFilterSelected(null)
                  loadSites(1, true)
                }
              }}
              options={sortingOptions}
              isClearable={true}
            />
          </S.ContainerButton>

          <S.ContainerSearch>
            <InputSearch
              placeholder={t('Pesquise por nome ou localização') ?? 'Pesquise por nome ou localização.'}
              value={nameOrLocal}
              onChange={(e) => setNameOrLocal(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </S.ContainerSearch>
          <Tooltip title={t('Pesquisar')}>
            <S.ContainerButton>
              <Button
                type="button"
                onClick={() => loadSites(1, true)}
                label={t('Pesquisar')}
                style={{ marginTop: 0, width: '150px' }}
              />
            </S.ContainerButton>
          </Tooltip>
        </S.ContainerFilter>
        {loading ? (
          <ViewLoading />
        ) : (
          <S.ContainerSites>
            {sites.length > 0 ? (
              <S.Container>
                <S.SitesGrid>
                  {sites.map((site) => (
                    <S.ContentBoxSolar key={site.id}>
                      <Link to={`/sites/inspections/${site.id}`}>
                        {site?.coverImageUrl ? (
                          <img src={site.coverImageUrl} alt="Painel solar" />
                        ) : (
                          <NoPhotography className="noPhotography" />
                        )}
                      </Link>
                      {(user?.accessLevel === 1 || user?.accessLevel === 2) && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent:
                              user?.accessLevel !== 1 && user?.accessLevel !== 2
                                ? 'flex-end'
                                : 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <MaterialIcon
                            name="Delete"
                            title={t("Deletar Sítio")}
                            onClick={() => handleDeletarSite(site.id)}
                            aria-label="Deletar Site"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: 15,
                              cursor: 'pointer',
                              background: 'none',
                              border: 'none',
                              padding: 0,
                            }}
                          />

                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '0 15px',
                            }}
                          >
                            <Link to={`/sites/editar/${site.id}`}>
                              <S.LinkEdit>
                                <MaterialIcon name="Edit" title={t("Editar Sítio")} />
                              </S.LinkEdit>
                            </Link>
                          </div>
                        </div>
                      )}

                      <div
                        className="contentData"
                        style={{
                          marginTop: user?.accessLevel !== 1 && user?.accessLevel !== 2 ? '8px' : '0',
                        }}
                      >
                        <h6>{site.name}</h6>
                        <span>{site.local}</span>

                        <div
                          className="flex-content"
                          style={{
                            display: 'flex',
                            paddingTop: 10,
                            justifyContent: 'space-between',
                          }}
                        >
                          {(user?.accessLevel === 1 || user?.accessLevel === 2) && (
                            <Tooltip title={t('Criar Inspeção')}>
                              <div
                                className="flex-btns"
                                style={{
                                  width: '50%',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  type="button"
                                  onClick={() => {
                                    setSiteId(site.id)
                                    navigate(`/upload-images/${site.id}`)
                                  }}
                                  label={t('Criar Inspeção')}
                                  style={{ marginTop: 16 }}
                                />
                              </div>
                            </Tooltip>
                          )}

                          <div
                            style={{
                              width: user?.accessLevel !== 1 && user?.accessLevel !== 2 ? '100%' : '50%',
                              display:
                                user?.accessLevel !== 1 && user?.accessLevel !== 2 ? 'flow' : 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginBottom: '10px',
                            }}
                          >
                            <h5 style={{ paddingBottom: 3, margin: 0 }}>{t('Última inspeção:')}</h5>
                            <p style={{ paddingTop: 0, margin: 0 }}>
                              {site.lastInspectionDate
                                ? moment(site.lastInspectionDate).format('DD/MM/YYYY')
                                : t('Não Realizada')}
                            </p>
                          </div>
                          {user?.accessLevel !== 1 && user?.accessLevel !== 2 && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                marginBottom: '10px',
                              }}
                            >
                              <Link to={`/sites/editar/${site.id}`}>
                                <Tooltip title="Visualizar Sítio">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Icon name="Visibility" fill="rgb(12, 74, 125)" height={20} />
                                    <Typography sx={{ margin: 0, padding: 0 }}>Visualizar</Typography>
                                  </div>
                                </Tooltip>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </S.ContentBoxSolar>
                  ))}
                </S.SitesGrid>
                <S.Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault()
                      e.stopPropagation()
                      loadSites(p)
                    }}
                  />
                </S.Pages>
              </S.Container>
            ) : (
              <ViewError message={t('Nenhum sítio cadastrado!')} />
            )}
          </S.ContainerSites>
        )}

        <ModalCustom
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onClick={() => {
            handleDeleteSite()
          }}
          loading={loading}
          title={t('Excluir Sítio')}
          description={t(
            'Tem certeza de que deseja excluir o Sítio? Após a confirmação, não há como voltar atrás.',
          )}
          confirmationButtonText={t('EXCLUIR')}
          textDeclineButton={t('CANCELAR')}
        />
      </S.FlexWrapper>
    </BaseLayout>
  )
}
