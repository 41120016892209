import { deleteJson, getJson, postJson } from '../RequestService'
import { IRequestSiteActivity, IRequestSiteActivityBody, IResponseSiteActivity } from './types'

const defaultRoot = 'siteactivity'

export const getSiteActivity = async (data: IRequestSiteActivity) => {
  let url = `${defaultRoot}`

  if (data.siteId) {
    const encodedLocal = encodeURIComponent(data.siteId)
    url += `?siteId=${encodedLocal}`
  }

  return await getJson<IResponseSiteActivity[]>(url)
}

export const getSiteActivityTimelineCSV = async (data: IRequestSiteActivity) => {
  let url = `${defaultRoot}/timelinecsv`

  if (data.siteId) {
    const encodedLocal = encodeURIComponent(data.siteId)
    url += `?siteId=${encodedLocal}`
  }

  return await getJson<IResponseSiteActivity[]>(url)
}

export const postSiteActivity = async (data: IRequestSiteActivityBody) => {
  let url = `${defaultRoot}`
  return await postJson<IRequestSiteActivityBody, IResponseSiteActivity>(url, data)
}

export const deleteSiteActivity = async (data: IRequestSiteActivity) => {
  let url = `${defaultRoot}`

  if (data.id) {
    const encodedLocal = encodeURIComponent(data.id)
    url += `?id=${encodedLocal}`
  }

  return await deleteJson<IRequestSiteActivity, any>(url)
}