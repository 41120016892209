import * as S from '../../styles'
import { Cell, Pie, PieChart } from 'recharts'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

type PieChartModulesComponentProps = {
  totalModules: number,
  totalAnomalies: number
}

const ModulePieChartComponent = ({ totalAnomalies, totalModules }: PieChartModulesComponentProps) => {
  const { t } = useTranslation()

  const healthyModules = totalModules - totalAnomalies;
  const healthyPercentage = (healthyModules / totalModules) * 100;
  const anomaliesPercentage = (totalAnomalies / totalModules) * 100;
  const data = [
    { name: t('Normais'), value: healthyModules, percentage: Math.max(0, healthyPercentage), color: "#06C" },
    { name: t('Afetados'), value: totalAnomalies, percentage: Math.min(100, anomaliesPercentage), color: "#FF3C3C" }
  ];

  return (
    <S.PieChartWrapper>
      <h4 className="title">{t('Resumo de Módulos')}</h4>
      <S.PieChartContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '100%',
            height: 300,
            marginBottom: 2,
          }}
        >
          <PieChart width={300} height={300}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              fill="#8884d8"
              outerRadius={120}
              innerRadius={80}
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}
                />
              ))}
            </Pie>
          </PieChart>

          <S.CenterLegend>
            <h2>{totalModules}</h2>
            <p>{t('Total')}</p>
          </S.CenterLegend>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: 1,
            overflowY: 'auto',
            marginTop: 2,
            marginRight: "20px"
          }}
        >
          {data.map((entry, index) => (
            <S.SeverityValues key={entry.name}>
              <Box
                sx={{
                  backgroundColor: entry.color,
                  width: 20,
                  display: 'inline-block',
                  marginRight: 1,
                }}
                className="square"
              />
              <S.PieChartInfoWrapper>
                <p>{entry.name}</p>
                <h4 className='EntryValue'>{entry.value}</h4>
                <span>{entry.percentage.toFixed(2)}%</span>
              </S.PieChartInfoWrapper>
            </S.SeverityValues>
          ))}
        </Box>
      </S.PieChartContent>
    </S.PieChartWrapper>
  )
}

export default ModulePieChartComponent
