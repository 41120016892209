import { deleteJson, getJson, postJson } from "../RequestService"
import { IRequestDelete, IRequestGetInspectionFile, IRequestProcess, InspectionFileDto } from "./types"

const defaultRoot = 'inspectionfile'
export const getInspectionsAll = async (data?: IRequestGetInspectionFile) => {
  let url = `${defaultRoot}?`

  if (data?.InspectionId) {
    const encodedLocal = encodeURIComponent(data.InspectionId)
    url += `InspectionId=${encodedLocal}&`
  }
  if (data?.FileType) {
    const encodedLocal = encodeURIComponent(data.FileType)
    url += `FileType=${encodedLocal}`
  }

  return await getJson<InspectionFileDto[]>(url)
}

export const postFile = async (data: FormData) => {
  let url = `${defaultRoot}`
  return await postJson<FormData, string>(url, data)
}

export const deleteFileIrradiancia = async (data: IRequestDelete) => {
  let url = `${defaultRoot}`
  return await deleteJson<IRequestDelete, boolean>(url, data)
}

export const postProcessIrradiancia = async (data: IRequestProcess) => {
  let url = `${defaultRoot}/processirradiances`
  return await postJson<IRequestProcess, string>(url, data)
}
