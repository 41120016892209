import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ToastNotification from '~/components/ToastNotification'
import ViewLoading from '~/components/ViewLoading'
import { deleteActivity, getActivity, patchActivity, postActivity } from '~/services/Activity'

interface IActivity {
  id: string
  name: string
  description: string
}

interface IFormValues {
  name: string
  description: string
}

const Atividades = () => {
  const { t } = useTranslation()
  const [activities, setActivities] = useState<IActivity[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)
  const [activityToDelete, setActivityToDelete] = useState<string | null>(null)
  const [editActivity, setEditActivity] = useState<IActivity | null>(null)
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: { name: '', description: '' },
  })

  const fetchActivities = async () => {
    try {
      const response = await getActivity()
      if (response.success) {
        setActivities(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const handleAddActivity = async (data: IFormValues) => {
    try {
      const response = await postActivity(data)
      if (response.success) {
        setActivities((prev) => [...prev, { ...data, id: response.data as unknown as string }])
        reset()
        setOpenModal(false)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Os dados foram adicionados com sucesso!'),
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível adicionar os dados, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível adicionar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const handleEditActivity = async (data: IFormValues) => {
    if (editActivity) {
      try {
        const response = await patchActivity({ ...data, id: editActivity.id })
        if (response.success) {
          setActivities((prev) =>
            prev.map((activity) =>
              activity.id === editActivity.id ? { ...activity, ...data } : activity,
            ),
          )
          reset()
          setOpenModal(false)
          setEditActivity(null)
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Os dados foram atualizados com sucesso!'),
          })
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível atualizar os dados, tente novamente mais tarde.'),
          })
        }
      } catch (error) {
        console.error(error)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível atualizar os dados, tente novamente mais tarde.'),
        })
      }
    }
  }

  const handleDeleteActivity = async (id: string) => {
    try {
      const response = await deleteActivity({ id })
      if (response.success) {
        setActivities((prev) => prev.filter((activity) => activity.id !== id))
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Os dados foram excluídos com sucesso!'),
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível deletar os dados, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível deletar os dados, tente novamente mais tarde.'),
      })
    }
  }

  useEffect(() => {
    fetchActivities()
  }, [])

  useEffect(() => {
    if (editActivity) {
      setValue('name', editActivity.name)
      setValue('description', editActivity.description)
    } else {
      reset()
    }
  }, [editActivity])

  return (
    <Paper
      sx={{
        padding: 3,
        margin: '2px calc(10%)',
        backgroundColor: 'white',
        boxShadow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setEditActivity(null)
            setOpenModal(true)
          }}
          style={{ backgroundColor: '#4CBB27', color: 'white' }}
        >
          {t('Criar Nova Atividade')}
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: 'rgb(21, 95, 153)', height: 80 }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: 16, width: 450 }}>
                {t('Nome')}
              </TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: 16 }}>
                {t('Descrição')}
              </TableCell>
              <TableCell
                sx={{ color: 'white', fontWeight: 'bold', fontSize: 16, width: 150 }}
                align="center"
              >
                {t('Ações')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="center"
                  style={{ height: 180, fontSize: 22, fontWeight: 'bold', paddingTop: 60 }}
                >
                  <ViewLoading />
                </TableCell>
              </TableRow>
            ) : (
              activities.map((activity) => (
                <TableRow key={activity.id}>
                  <TableCell sx={{ fontWeight: 'bold' }}>{activity.name}</TableCell>
                  <TableCell>{activity.description}</TableCell>
                  <TableCell
                    align="right"
                    sx={{ display: 'flex', gap: 1, justifyContent: 'center', justifyItems: 'center' }}
                  >
                    <Tooltip title={t('Excluir')}>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setActivityToDelete(activity.id)
                          setConfirmDeleteDialogOpen(true)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Editar')}>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setEditActivity(activity)
                          setOpenModal(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>
          {editActivity ? t('Editar Atividade Existente') : t('Criar Nova Atividade')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(editActivity ? handleEditActivity : handleAddActivity)}>
            <Controller
              name="name"
              control={control}
              rules={{
                minLength: { value: 5, message: t('O nome deve ter pelo menos 5 caracteres') },
                maxLength: { value: 100, message: t('O nome deve ter no máximo 100 caracteres') },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Nome')}
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 100 }}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Descrição')}
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 200 }}
                />
              )}
            />
            <DialogActions>
              <Button type="submit" color="primary" variant="contained">
                {editActivity ? t('Salvar') : t('Criar')}
              </Button>
              <Button
                onClick={() => {
                  setOpenModal(false)
                  reset()
                }}
                color="primary"
                variant="outlined"
              >
                {t('Cancelar')}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={confirmDeleteDialogOpen} onClose={() => setConfirmDeleteDialogOpen(false)}>
        <DialogTitle>{t('Confirmação de Exclusão')}</DialogTitle>
        <DialogContent>
          <p>
            <span>{t('Você tem certeza de que deseja excluir esta atividade?')}</span>
            <br />
            <span style={{ fontWeight: 'bold', color: 'red' }}>
              {t('ATENÇÃO: Essa operação não pode ser desfeita.')}
            </span>
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (activityToDelete) {
                handleDeleteActivity(activityToDelete)
              }
              setConfirmDeleteDialogOpen(false)
              setActivityToDelete(null)
            }}
            color="error"
            variant="contained"
          >
            {t('Sim')}
          </Button>
          <Button
            onClick={() => {
              setConfirmDeleteDialogOpen(false)
              setActivityToDelete(null)
            }}
            color="primary"
            variant="outlined"
          >
            {t('Não')}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default Atividades
