import { Image } from 'image-js'

export const isValidHolder = (name: string) => {
  const regExp = new RegExp(/\b[a-zÀ-ú][a-zÀ-ú]+,?\s[a-zÀ-ú][a-zÀ-ú]{1,100}\b/gi)
  if (!regExp.test(name)) return false
  return true
}

export const isValidCpf = (input?: string | null): boolean => {
  if (!input) return false

  input = input.replace(/\D/g, '')

  const regExp = new RegExp(`^(\\d{${11}})$`)
  const checkNumbers = regExp.exec(input)
  if (!checkNumbers) return false

  const index0 = input[0]
  const checkEqualsNumber = input.split('').every((char) => char === index0)
  if (checkEqualsNumber) return false

  let sum1 = 0
  let sum2 = 0
  let j = input.length

  for (let i = 0; i < 10; i++, j--) {
    if (j > 2) {
      sum1 += Number(input.charAt(i)) * (j - 1)
    }
    sum2 += Number(input.charAt(i)) * j
  }

  const checker1 = 11 - (sum1 % 11)
  const chacker2 = 11 - (sum2 % 11)

  const isOk1 =
    ((checker1 === 10 || checker1 === 11) && Number(input.charAt(9)) === 0) ||
    Number(input.charAt(9)) === checker1

  const isOk2 =
    ((chacker2 === 10 || chacker2 === 11) && Number(input.charAt(10)) === 0) ||
    Number(input.charAt(10)) === chacker2

  return isOk1 && isOk2
}

function digit(numbers: string): number {
  let index = 2

  const sum = Array.from(numbers)
    .reverse()
    .reduce((buffer, number) => {
      buffer += Number(number) * index
      index = index === 9 ? 2 : index + 1
      return buffer
    }, 0)

  const mod = sum % 11

  return mod < 2 ? 0 : 11 - mod
}

export const isValidCNPJ = (cnpj: string | number): boolean => {
  const cleaned = cnpj.toString().replace(/[\.\/\-]/g, '')

  if (!cleaned || cleaned.length !== 14 || /^(\d)\1+$/.test(cleaned)) {
    return false
  }

  let registration = cleaned.substring(0, 12)
  registration += digit(registration)
  registration += digit(registration)

  return registration.substring(-2) === cleaned.substring(-2)
}

export function BirthDateValidation(birthDate: any) {
  const result = ValidationData(birthDate)
  if (!result.status) {
    return { msg: result.msg, status: result.status }
  }
  const d = new Date()
  const ano_atual = d.getFullYear()
  const mes_atual = d.getMonth() + 1
  const dia_atual = d.getDate()

  const diaNasc = parseInt(birthDate.substring(0, 2))
  const mesNasc = parseInt(birthDate.substring(2, 4))
  const anoNasc = parseInt(birthDate.substring(4, 8))
  let quantos_anos = ano_atual - anoNasc

  if (mes_atual < mesNasc || (mes_atual == mesNasc && dia_atual < diaNasc)) {
    quantos_anos = quantos_anos - 1
  }
  if (quantos_anos < 18) {
    return {
      msg: 'Cadastro permitido apenas para maiores de 18 anos',
      status: false,
    }
  }
  if (quantos_anos > 100) {
    return { msg: 'Desculpe, a data não é válida', status: false }
  }
  return { msg: '', status: true }
}

export function ValidationData(date: string) {
  const RegExPattern = new RegExp(/(\d{4})[-./](\d{2})[-./](\d{2})/)
  if (date.length <= 0) {
    return { msg: 'Campo obrigatório', status: false }
  }
  if (date.length < 8) {
    return { msg: 'Desculpe, a data não é válida', status: false }
  }
  if (!(RegExPattern.exec(date) && date != '')) {
    return { msg: 'Desculpe, a data não é válida', status: false }
  }
  return { msg: '', status: true }
}

export function validarLatitude(latitude: string): boolean {
  // Substituir vírgulas por pontos para padronizar o formato de entrada decimal
  const normalizedLatitude = latitude.replace(',', '.');

  // Expressão regular para verificar se a latitude está no formato correto
  const latitudeRegex = /^-?(?:\d+(\.\d*)?|\.\d+)?$/;

  // Verificar se a latitude normalizada está dentro do intervalo -90 e 90
  if (!isNaN(Number(normalizedLatitude))) {
    if (Number(normalizedLatitude) >= -90 && Number(normalizedLatitude) <= 90) {
      return true;
    }
    return false;
  }

  // Verifica se a entrada é apenas um traço, que pode ser um início de um valor negativo
  return latitudeRegex.test(normalizedLatitude) || /^-$/.test(normalizedLatitude);
}



export function validarLongitude(longitude: string) {
  // Substituir vírgulas por pontos para padronizar o formato de entrada decimal
  const normalizedLongitude = longitude.replace(',', '.');
  // Expressão regular para verificar se a longitude está no formato correto
  const latitudeRegex = /^-?(?:\d+(\.\d*)?|\.\d+)$/
  // Verificar se a longitude está dentro do intervalo -180 e 180

  if (!isNaN(Number(normalizedLongitude))) {
    if (Number(normalizedLongitude) >= -180 && Number(normalizedLongitude) <= 180) return true
    return false
  }

  return latitudeRegex.test(normalizedLongitude) || /^-$/.test(normalizedLongitude)
}

export function validarNumeroRealPositivo(numero: string): boolean {
  const padrao: RegExp = /^\d+([.,]\d*)?$/
  return padrao.test(numero)
}

export function validarNumeroInteiroPositivo(numero: string): boolean {
  const padrao: RegExp = /^\d*$/
  return padrao.test(numero)
}

export const convertTiffToImage = async (tiffUrl: string): Promise<string> => {
  try {

    // Carrega a imagem TIFF
    const image = await Image.load(tiffUrl)

    // Converte para um formato suportado pelo navegador (por exemplo, PNG)
    const pngDataUrl = image.toDataURL('image/png')

    return pngDataUrl ?? false
  } catch (error) {
    console.error('Erro ao converter imagem TIFF:', error)
    return 'false'
  }
}
