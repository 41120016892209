/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { IAuthRequest, IToken } from './types'
import { cleanLocalStorage, getLocalStorage, setLocalStorage } from '~/utils/StorageLocal'
import { USER_GET } from '~/config/constants'
import { sendRequest } from '../RequestService'
import { IUser } from '~/contexts/Auth/types'
import { useNavigate } from 'react-router-dom'

const defaultRoot = 'auth'

export const login = async (body: IAuthRequest) => {
  const url = `${defaultRoot}/signin`
  return await sendRequest<IUser, IAuthRequest>(url, 'POST', body)
}

export const getRefreshToken = async () => {
  
  const url = `${defaultRoot}/signin`
  const storagedUser = getLocalStorage<IUser>(USER_GET);
  const body = {
    grantType: 2,
    email: storagedUser?.email ? storagedUser.email : '',
    refreshToken: storagedUser?.refreshToken ? storagedUser.refreshToken : ''
  };
  const response = await sendRequest<IToken, { refreshToken: string }>(url, 'POST', body)
  if (response.success) {
    setLocalStorage(USER_GET, response.data);
  } else {
    cleanLocalStorage();
    window.location.reload();
  }
  return response
}

export const logout = () => {
  const navigate = useNavigate()
  cleanLocalStorage()
  navigate('/login')
}
