import { Dialog, Drawer, DialogContent, DialogActions } from '@mui/material'
import styled from 'styled-components'

export const MttDialogWeb = styled(Dialog)`
  .MuiDialogContent-dividers {
    padding: 16px;
  }

  .MuiDialog-paperWidthSm {
    max-width: 100%;
  }
`

export const MttDrawerMobile = styled(Drawer)`  
  .MuiDrawer-paperAnchorBottom {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    max-height: 500px;
  }
`

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  button {
    border: 0;
    background: none;
  }
`

export const MttDialogTitle = styled.h4`
  padding: 0;
`

export const MttDialogContent = styled(DialogContent)`
  padding: 0;
  margin: 0;
  min-width: 196px;
`

export const MttDialogActions = styled(DialogActions)`
  margin-right: 10px;
  margin-bottom: 4px;

  button {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    border: 0;
    padding: 8px 28px;
    border-radius: 8px;
    transition: 0.5s;
  }

  button:hover {
    background: ${props => props.theme.colors.colorBtn};

  }

  &.MuiDialogActions-spacing > :not(:first-child){
    margin-left: 1em;
  }
`
