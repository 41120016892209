import styled from 'styled-components'
import { theme } from '~/styles/theme'

export const Container = styled.div``

export const StyleBox = styled.div`
  position: absolute;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 16px;
  background-color: ${theme.colors.white};
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 16px;
`

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 5px;
  margin-bottom: 24px;

  p {
    font-size: 24px;
  }

  span {
    cursor: pointer;
  }
`
export const MainModal = styled.div`
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 40px 24px 40px;
`
