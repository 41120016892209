import AddIcon from '@mui/icons-material/Add'
import BarChartIcon from '@mui/icons-material/BarChart'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NextPlanIcon from '@mui/icons-material/NextPlan'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { Box, IconButton, Menu, MenuItem, TablePagination, TextField, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import { default as ButtonNew } from '@mui/material/Button'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Select, { StylesConfig } from 'react-select'
import Button from '~/components/Button'
import InputSearch from '~/components/InputSearch'
import MaterialTable from '~/components/Material/Table'
import ModalCustom from '~/components/ModalCustom'
import ToastNotification from '~/components/ToastNotification'
import ViewLoading from '~/components/ViewLoading'
import { useAuth } from '~/contexts/Auth'
import BaseLayout from '~/Layouts/BaseLayout'
import { deleteInspection, getAllStatus, getInspections, postUpdateStatus } from '~/services/Inspections'
import { EInspectionStatus, IInspection } from '~/services/Inspections/types'
import { getSimpleSite } from '~/services/Site'
import { theme } from '~/styles/theme'
import ErrorToast from '~/utils/toastErrorCatch'
import InspectionStatusModal from '../components/ModalExampleImages'
import * as S from '../styles'
import * as R from '../UploadImages/styles'
import FileIrradianciaModal from './components/ModalIrradiancia'
import { IMenuPosition, SelectOption } from './types'
import { CustomTooltipContent } from './components/customTooltip'

const selectStyles: StylesConfig<SelectOption, false> = {
  control: (provided: any, state: any) => ({
    ...provided,
    flex: 1,
    flexDirection: 'row',
    minWidth: 550,
    borderColor: state.isFocused ? theme.colors.primary : theme.colors.blueRGBA3,
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(32, 168, 216, 0.25)' : '',
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
  }),
}

export default function Sites() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { siteId } = useParams()
  const { user } = useAuth()

  const [inspectionId, setInspectionId] = useState('')
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openNextStageModal, setOpenNextStageModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [obsAdc, setObsAdc] = useState('')
  const [siteOptions, setSiteOptions] = useState<SelectOption[]>([])
  const [selectedSiteId, setSelectedSiteId] = useState('')
  const [inspections, setInspections] = useState<IInspection[]>([])
  const [statusOptions, setStatusOptions] = useState<SelectOption[]>([])
  const [selectedStatusId, setSelectedStatusId] = useState<string>('')
  const [initialLoad, setInitialLoad] = useState(true)
  const [hasLoadedInspections, setHasLoadedInspections] = useState(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const [itemsTotal, setItemsTotal] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [currentInspectionId, setCurrentInspectionId] = useState<string>('')

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const [menuPosition, setMenuPosition] = useState<IMenuPosition>({ top: 0, left: 0 })

  const [activeRow, setActiveRow] = useState<string | null>(null)
  const [isModalIrradianciaOpen, setIsModalIrradianciaOpen] = useState<boolean>(false)

  const openModal = (inspectionId: string) => {
    setCurrentInspectionId(inspectionId)
    setModalOpen(true)
  }

  const handleConfirm = () => {
    handleNextStage(currentInspectionId, EInspectionStatus.ImagesUploaded)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
    loadInspections(newPage + 1, rowsPerPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenModal = (inspectionId: string) => {
    setCurrentInspectionId(inspectionId)
    setIsModalIrradianciaOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalIrradianciaOpen(false)
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, rowId: string) => {
    const rect = event.currentTarget.getBoundingClientRect()
    const windowHeight = window.innerHeight

    let top: number
    // Verifica se há menos de 200px de espaço abaixo
    if (windowHeight - rect.bottom < 230) {
      // Ajusta para exibir acima do botão
      top = rect.top + window.scrollY - 230
    } else {
      // Exibe abaixo do botão
      top = rect.bottom + window.scrollY
    }

    const left = rect.left + window.scrollX
    setAnchorEl(event.currentTarget)
    setMenuPosition({ top, left })
    setActiveRow(rowId)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const edit = true
  const handleEditClick = (inspectionId: string, edit: boolean) => {
    navigate(`/upload-images/${inspectionId}`, {
      state: {
        edit: edit,
        key: new Date().getTime(),
      },
    })
  }

  const [formattedFromDate, setFormattedFromDate] = useState('')
  const [formattedToDate, setFormattedToDate] = useState('')

  const handleFromDateInspectionListChange = (e: any) => {
    const inputDate = e.target.value
    setFromDate(inputDate)

    if (inputDate) {
      const [year, month, day] = inputDate.split('-').map((num: any) => parseInt(num, 10))
      let date = new Date(Date.UTC(year, month - 1, day))
      const formattedDateTime = date.toISOString()
      setFormattedFromDate(formattedDateTime)
    }
  }

  const handleToDateInspectionListChange = (e: any) => {
    const inputDate = e.target.value
    setToDate(inputDate)
    if (inputDate) {
      const [year, month, day] = inputDate.split('-').map((num: any) => parseInt(num, 10))
      let date = new Date(Date.UTC(year, month - 1, day))
      const formattedDateTime = date.toISOString()
      setFormattedToDate(formattedDateTime)
    }
  }

  const loadSite = async () => {
    setLoading(true)
    try {
      const responseSites = await getSimpleSite()
      if (responseSites.success) {
        const sites = responseSites.data
        const options = sites.map((site) => ({
          label: site.nameAndLocal,
          value: site.id,
        }))
        setSiteOptions(options)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: responseSites.errorDetails,
          errors: responseSites.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar os sitios, tente novamente mais tarde.')} />
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    loadSite()
    loadAllStatuses()
  }, [])

  const loadAllStatuses = async () => {
    setLoading(true)
    try {
      const response = await getAllStatus()
      if (response.success) {
        const options = response.data.map((status) => ({
          label: t(`status.${status.name}`) ?? 'Status desconhecido',
          value: status.id.toString(),
        }))
        setStatusOptions(options)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  const loadInspections = async (
    pageNumber = page + 1,
    pageSize = rowsPerPage,
    siteId = selectedSiteId,
  ) => {
    setLoading(true)
    try {
      let status: number | undefined = undefined
      if (selectedStatusId != undefined && selectedStatusId != '' && selectedStatusId != null) {
        status = Number(selectedStatusId)
      }
      const response = await getInspections({
        SiteId: siteId,
        FromDate: formattedFromDate,
        ToDate: formattedToDate,
        Description: obsAdc,
        PageNumber: pageNumber,
        Status: status ?? undefined,
        PageSize: pageSize,
      })
      if (response.success) {
        setInspections(response.data)
        setItemsTotal(response.itemsTotal ?? 0)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false)
      if (siteId) {
        setSelectedSiteId(siteId)
      }
    }
  }, [initialLoad, siteId])

  useEffect(() => {
    if (!initialLoad) {
      if (siteId) {
        setSelectedSiteId(siteId)
      }
    }
  }, [siteId, initialLoad])

  useEffect(() => {
    if (!hasLoadedInspections && !initialLoad) {
      loadInspections(page + 1, rowsPerPage, selectedSiteId)
      setHasLoadedInspections(true)
    }
  }, [formattedFromDate, formattedToDate, obsAdc, page, rowsPerPage, selectedSiteId, initialLoad])

  const handleDeletarInspecao = (InspectionId: string) => {
    setInspectionId(InspectionId)
    setOpenDeleteModal(true)
  }

  const [modalDescription, setModalDescription] = useState<string>('')

  const getStatusDescription = (currentStatus: EInspectionStatus): string => {
    const currentStatusLabel = statusLabels[currentStatus]
    const nextStatus = currentStatus + 1
    const nextStatusLabel = statusLabels[nextStatus] || 'N/A'

    return t('advance_inspection_confirmation', {
      currentStatus: currentStatusLabel,
      nextStatus: nextStatusLabel,
    })
  }

  const handleNextStage = (InspectionId: string, currentStatus: EInspectionStatus) => {
    setInspectionId(InspectionId)
    setOpenNextStageModal(true)

    const description = getStatusDescription(currentStatus)
    setModalDescription(description)
  }
  const handleDeleteInspection = async () => {
    setLoading(true)
    try {
      const responseDelete = await deleteInspection(inspectionId)
      if (responseDelete.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Inspeção excluída com sucesso!'),
        })

        setInspections(inspections.filter((inspection) => inspection.inspectionId !== inspectionId))
        setOpenDeleteModal(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível excluir a inspeção, tente novamente mais tarde.'),
          errorMessage: responseDelete.errorDetails,
          errors: responseDelete.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar a inspeção, tente novamente mais tarde.')} />
      )
    }
    setLoading(false)
  }

  const updateStage = async () => {
    setLoading(true)
    try {
      const responseUpdate = await postUpdateStatus({
        inspectionId: inspectionId,
        isUploadingNewImages: false,
      })
      if (responseUpdate.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Etapa avançada com sucesso!'),
        })
        setModalOpen(false)
        setOpenNextStageModal(false)
        loadInspections()
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível avançar a etapa, tente novamente mais tarde.'),
          errorMessage: responseUpdate.errorDetails,
          errors: responseUpdate.errors,
        })
      }
    } catch (error) {
      return <ErrorToast message={t('Não foi possível avançar a etapa, tente novamente mais tarde.')} />
    }
    setLoading(false)
  }

  const colunasListaInspecoes: {
    label: any
    value?: string
    alignment?: 'left' | 'right' | 'inherit' | 'center' | 'justify'
  }[] = [
      {
        label: t('Data da Inspeção'),
        value: 'inspectionDate',
        alignment: 'left',
      },
      {
        label: t('Sítio'),
        value: 'siteName',
        alignment: 'left',
      },
      {
        label: t('Informações Adicionais'),
        value: 'description',
        alignment: 'left',
      },
      {
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('Status')}
            <Tooltip sx={{ cursor: 'pointer' }} title={<CustomTooltipContent />} placement="bottom" arrow
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 4],
                    },
                  },
                ],
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: '#EFF3F9',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }
                }
              }}
            >
              <InfoIcon fontSize="small" color='info' style={{ marginLeft: 5 }} />
            </Tooltip>
          </div>
        ),
        value: 'status',
        alignment: 'left',
      },
      {
        label: t('Ações'),
        value: '',
        alignment: 'right',
      },
    ]

  const statusLabels: any = {
    [EInspectionStatus.Created]: t('status.Created'),
    [EInspectionStatus.ImagesUploaded]: t('status.ImagesUploaded'),
    [EInspectionStatus.ProcessingImages]: t('status.ProcessingImages'),
    [EInspectionStatus.ImagesProcessed]: t('status.ImagesProcessed'),
    [EInspectionStatus.Complete]: t('status.Complete'),
  }

  useEffect(() => {
    if (selectedSiteId !== null && selectedSiteId !== '') {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [selectedSiteId])

  return (
    <BaseLayout
      title={t('Inspeções')}
      extraContent={
        <Box sx={{ display: 'flex' }}>
          <ButtonNew
            variant="contained"
            onClick={() => navigate(`/upload-images/${selectedSiteId}`)}
            startIcon={<AddIcon />}
            sx={{
              height: '40px',
              marginTop: 0,
              marginLeft: '24px',
              width: 230,
              backgroundColor: 'rgb(12, 74, 125)',
              textTransform: 'none',
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgb(10, 62, 105)',
              },
              '&.Mui-disabled': {
                backgroundColor: 'rgb(200, 200, 200)',
                color: 'rgba(255, 255, 255, 0.5)',
              },
              fontFamily: '"Open Sans", sans-serif',
              fontWeight: 'bold',
            }}
            disabled={isDisabled}
          >
            {t('Nova Inspeção')}
          </ButtonNew>
        </Box>
      }
    >
      <S.FlexWrapper>
        <S.ContainerFilter style={{ margin: '40px 10% 10px 10%' }}>
          <S.ContainerButton>
            <Select
              className="w-full"
              menuPosition="fixed"
              placeholder={t('Selecione o Sítio') ?? 'Selecione o Sitio'}
              isClearable={true}
              styles={selectStyles}
              noOptionsMessage={() => 'Nenhuma opção disponível'}
              value={siteOptions.find((option) => option.value === selectedSiteId) || null}
              onChange={(selectedOption: any) => {
                setSelectedSiteId(selectedOption ? selectedOption.value : '')
              }}
              options={siteOptions}
            />
          </S.ContainerButton>
          <div>
            <R.InputWrapper>
              <TextField
                size="small"
                type="date"
                value={fromDate || new Date()}
                onChange={handleFromDateInspectionListChange}
                label={t('De') ?? 'De'}
                fullWidth
              />
            </R.InputWrapper>
          </div>
          <div>
            <R.InputWrapper>
              <TextField
                size="small"
                type="date"
                value={toDate || new Date()}
                onChange={handleToDateInspectionListChange}
                label={t('Até') ?? 'Até'}
                fullWidth
              />
            </R.InputWrapper>
          </div>

          <Select
            menuPosition="fixed"
            placeholder={t('Selecione o Status') ?? 'Selecione o Status'}
            isClearable={true}
            styles={selectStyles}
            noOptionsMessage={() => 'Nenhuma opção disponível'}
            value={statusOptions.find((option) => option.value === selectedStatusId) || null}
            onChange={(selectedOption) => {
              const value = selectedOption ? selectedOption.value.toString() : ''
              setSelectedStatusId(value)
            }}
            options={statusOptions}
          />
        </S.ContainerFilter>
        <S.ContainerFilter style={{ margin: '5px 10% 40px 10%', width: '80%' }}>
          <InputSearch
            placeholder={
              t('Pesquise por Informações Adicionais') ?? 'Pesquise por Informações Adicionais'
            }
            value={obsAdc}
            onChange={(e) => setObsAdc(e.target.value)}
            variant="outlined"
            fullWidth
          />
          <Tooltip title="Pesquisar">
            <S.ContainerButton>
              <Button
                type="button"
                onClick={() => loadInspections()}
                label={t('Pesquisar')}
                style={{ marginTop: 0, width: '150px' }}
              />
            </S.ContainerButton>
          </Tooltip>
        </S.ContainerFilter>

        {loading ? (
          <ViewLoading />
        ) : (
          <S.TableWrapper>
            <MaterialTable<{
              inspectionId: string
              description: string | null
              inspectionDate: string
              status: EInspectionStatus
              siteName: string | null
              siteId: string
            }>
              headCells={colunasListaInspecoes}
              page={0}
              tableValues={inspections.map((item) => ({
                inspectionDate: item?.inspectionDate,
                inspectionId: item?.inspectionId,
                description: item?.description,
                status: item?.status,
                siteName: item?.siteName,
                siteId: item?.siteId,
              }))}
              renderRow={(row) => {
                return (
                  <>
                    <S.TableCell width={'10%'} align="left">
                      {moment(row.inspectionDate).format('DD/MM/YYYY')}
                    </S.TableCell>
                    <S.TableCell width={'30%'} align="left">
                      <Link
                        style={{
                          fontWeight: 'bold',
                          color: 'rgb(32, 146, 255)',
                          textDecoration: 'none',
                        }}
                        to={`/inspection/${row.inspectionId}`}
                      >
                        {row.siteName}
                      </Link>
                    </S.TableCell>

                    <S.TableCell width={'33%'} align="left">
                      {row.description}
                    </S.TableCell>

                    <S.TableCell width={'15%'} align="left">
                      {row.status === EInspectionStatus.ImagesUploaded ? (
                        <button
                          onClick={() => openModal(row.inspectionId)}
                          style={{
                            background: 'none',
                            color: 'rgb(32, 146, 255)',
                            border: 'none',
                            padding: 0,
                            fontSize: 'inherit',
                            fontFamily: 'inherit',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            textTransform: 'none',
                          }}
                        >
                          {statusLabels[row.status]}
                        </button>
                      ) : (
                        statusLabels[row.status]
                      )}
                    </S.TableCell>
                    <S.TableCell width={'20%'} align={'right'}>
                      <S.IconsWrapper style={{ justifyContent: 'right' }}>
                        <IconButton
                          aria-controls="actions-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleMenuClick(event, row.inspectionId)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="actions-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && activeRow === row.inspectionId}
                          onClose={handleMenuClose}
                          style={{ position: 'absolute', ...menuPosition }}
                          slotProps={{
                            paper: {
                              style: {
                                minWidth: '200px',
                                minHeight: '235px',
                                overflow: 'auto',
                              },
                            },
                          }}
                        >
                          {user?.accessLevel === 1 && (
                            <MenuItem
                              onClick={() => {
                                handleNextStage(row.inspectionId, row.status)
                                handleMenuClose()
                              }}
                              disabled={row.status !== 3}
                            >
                              <NextPlanIcon style={{ marginRight: 8, color: 'rgb(25, 118, 210)' }} />
                              {t('Avançar Inspeção')}
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => {
                              navigate(`/upload-images/${row.siteId}`)
                              handleMenuClose()
                            }}
                          >
                            <NoteAddIcon style={{ marginRight: 8, color: 'rgb(25, 118, 210)' }} />
                            {t('Criar Inspeção')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              navigate(`/inspection/${row.inspectionId}`)
                              handleMenuClose()
                            }}
                          >
                            <BarChartIcon style={{ marginRight: 8, color: 'rgb(25, 118, 210)' }} />
                            {t('Detalhes da Inspeção')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleOpenModal(row.inspectionId)
                              handleMenuClose()
                            }}
                          >
                            <BarChartIcon style={{ marginRight: 8, color: 'rgb(25, 118, 210)' }} />
                            {t('Irradiância')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleEditClick(row.inspectionId, edit)
                              handleMenuClose()
                            }}
                          >
                            <EditIcon style={{ marginRight: 8, color: 'rgb(25, 118, 210)' }} />
                            {t('Editar Inspeção')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleDeletarInspecao(row.inspectionId)
                              handleMenuClose()
                            }}
                          >
                            <DeleteIcon style={{ marginRight: 8, color: 'red' }} />
                            {t('Deletar Inspeção')}
                          </MenuItem>
                        </Menu>
                      </S.IconsWrapper>
                    </S.TableCell>
                  </>
                )
              }}
              pagination={() => (
                <TablePagination
                  component={'div'}
                  rowsPerPageOptions={[10]}
                  count={itemsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            />
          </S.TableWrapper>
        )}

        <ModalCustom
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onClick={() => {
            handleDeleteInspection()
          }}
          loading={loading}
          title={t('Excluir Inspeção')}
          description={t(
            'Tem certeza de que deseja excluir a Inspeção? Após a confirmação, não há como voltar atrás.',
          )}
          confirmationButtonText={t('Excluir')}
          textDeclineButton={t('Cancelar')}
        />
        <ModalCustom
          open={openNextStageModal}
          onClose={() => setOpenNextStageModal(false)}
          onClick={() => {
            updateStage()
          }}
          loading={loading}
          title={t('Avançar Inspeção')}
          description={t(
            'Tem certeza de que deseja avançar do status a Inspeção? Após a confirmação, não há como voltar atrás.',
          )}
          secondDescription={modalDescription}
          confirmationButtonText={t('Avançar')}
          textDeclineButton={t('Cancelar')}
        />
        <InspectionStatusModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          inspectionId={currentInspectionId}
          onConfirm={handleConfirm}
        />

        <FileIrradianciaModal
          open={isModalIrradianciaOpen}
          onClose={handleCloseModal}
          inspectionId={currentInspectionId}
        />
      </S.FlexWrapper>
    </BaseLayout>
  )
}
