import { theme } from '~/styles/theme'
import { TableCell as MUITableCell } from '@mui/material'
import styled from 'styled-components/macro'

export const TableCell = styled(MUITableCell)`
  &.MuiTableCell-root {
    a {
      text-decoration: none;
      color: ${theme.colors.link};
      font-weight: bold;
      letter-spacing: 0.8px;
    }
  }
`
