import { ICompaniesAndBranchesList } from './types'

export const emptyItem: ICompaniesAndBranchesList = {
  id: '',
  companyBranch: '',
  city: '',
  state: '',
  place: '',
  street: '',
  status: 0,
}
