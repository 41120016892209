export const LOCALE_YUP_BR = {
  mixed: {
    required: 'Campo obrigatório',
    default: 'Campo não é válido'
  },
  string: {
    min: 'Mínimo de ${min} caracteres',
    max: 'Máximo de ${max} caracteres',
    email: 'Email deve ser valido'
  },
  number: {
    moreThan: 'Campo deve ter um valor selecionado'
  },
  date: {
    min: 'Data deve ser superior a ${min}'
  }
};

export const LOCALE_YUP_US = {
  mixed: {
    required: 'Required field',
    default: 'Field is not valid'
  },
  string: {
    min: 'Minimum ${min} characters',
    max: 'Maximum ${max} characters',
    email: 'Email must be valid'
  },
  number: {
    moreThan: 'Field must have a selected value'
  },
  date: {
    min: 'Date must be greater than ${min}'
  }
};

export const LOCALE_YUP_ES = {
  mixed: {
    required: 'Campo obligatorio',
    default: 'El campo no es válido'
  },
  string: {
    min: 'Mínimo ${min} caracteres',
    max: 'Máximo ${max} caracteres',
    email: 'El email debe ser válido'
  },
  number: {
    moreThan: 'El campo debe tener un valor seleccionado'
  },
  date: {
    min: 'La fecha debe ser mayor que ${min}'
  }
};

export const PAGE_SIZE = 8;