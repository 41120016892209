import { getJson } from '../RequestService'
import { IDashboardInspectionProps, IDashboardProps } from './types'

const defaultRoot = 'dashboard'

export const getDashboardDataService = async () => {
  let url = `${defaultRoot}/main`
  return await getJson<IDashboardProps>(url)
}
export const getDashboardInspectionService = async (inspectionId: string) => {
  let url = `${defaultRoot}/inspection?Id=${inspectionId}`
  return await getJson<IDashboardInspectionProps>(url)
}
