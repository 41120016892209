import { Box, Paper, Chip as MuiChip, TableCell as MUITableCell } from '@mui/material'
import styled from 'styled-components/macro'
import { RestartAlt as MuiRestartAlt } from '@mui/icons-material'
import { theme } from '~/styles/theme'
import { Legend, BarChart } from 'recharts'

const BaseBorder = styled(Paper)`
  padding: 10px;
`

export const HeaderInspection = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  white-space: nowrap;

  p {
    font-size: 14px;
  }
`

export const ContentWrapper = styled(Box)`
  padding: 24px 10%;
  width: 100%;
  display: grid;
  gap: 20px;
`

export const HeaderContent = styled(Box)`
  width: 100%;
  align-items: center;
  align-items: flex-start;
  display: grid;
  grid-template-areas: 'Summary Severity';
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 1350px) {
    grid-template-columns: 1fr;
    grid-template-rows: 72px 130px;
    grid-template-areas:
      'Summary'
      'Severity';
  }
`

export const SummaryWrapper = styled(BaseBorder)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  grid-area: Summary;
`

export const SummaryOptionWrapper = styled(BaseBorder)`
  display: flex;
`

export const SummaryOption = styled(Box)`
  white-space: nowrap;
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 10px;
  margin: 0 auto;
`

export const IconWrapper = styled(Box)`
  border-radius: 50%;
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const OptionInfos = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  p {
    font-size: 14px;
  }
`

export const AnomalieSeverityWrapper = styled(BaseBorder)`
  width: 100%;

  grid-area: Severity;

  .circle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  .bar {
    height: 20px;
  }

  .alta {
    background: #FF3C3C;
  }

  .media {
    background: #F7CE3D
  }

  .baixa {
    background: #3F8A05

  }
`

export const AnomalieTitle = styled.h4`
  padding: 0 10px;
`

export const HorizontalBar = styled(Box)`
  display: flex;
  padding: 10px 0;
`

export const DataContent = styled(Box)`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ValuesContent = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
`

export const TotalAnomalies = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AlignLine = styled(Box)`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 1fr;
  gap: 5px;
`

export const SeverityValues = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  padding-right: 20px;

  .square {
    width: 8px;
    height: 8px;
  }
`

export const AllInspectionsTable = styled(Box)``

export const PieChartWrapper = styled(Paper)`
  width: 100%;

  h4 {
    padding: 10px;
  }

  .title {
    padding: 20px;
    padding-bottom: 0;
  }
`

export const PieChartContent = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: calc(100% - 40px);
`

export const Pie = styled(Box)`
  position: relative;
  text-align: center;

  p {
    font-size: 10px;
  }
`

export const AnomaliesDescription = styled(Box)`
  max-height: 450px;
  overflow: auto;
`

export const PieChartInfoWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 40px 120px;
  align-items: center;
  text-align: end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    margin-right: 10px;
  }

  span {
    margin-left: 20px;
  }

  .EntryValue{
    margin-right: 10px;
  }
`

export const EqualSizes = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`
export const OneSize = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr ;
  grid-gap: 20px;
`

export const MoreLeft = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
`

export const DescriptionWrapper = styled(BaseBorder)`
  width: 100%;
  max-height: 500px;
  padding: 0;

  h4 {
    border-bottom: 1px solid ${theme.colors.gray2};
    padding: 20px;
  }
`

export const DescriptionContent = styled(Box)`
  padding: 20px;
  height: calc(100% - 60px);

  overflow-y: auto;
`

export const CenterLegend = styled(Box)`
  position: absolute;

  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

export const AllAnomaliesEffectWrapper = styled(BaseBorder)`
  position: relative;
  padding: 20px;
  max-height: 400px;

  .recharts-responsive-container {
    max-height: calc(100% - 55px);
    box-sizing: border-box;
  }

  .table-type {
    position: absolute;
    writing-mode: vertical-rl;
    white-space: nowrap;

    top: 45%;
    left: 45px;
    transform-origin: left;
    transform: translate(-50%, -50%) rotate(180deg);
  }

  .recharts-wrapper {
    width: 100% !important;
  }


`

export const CustomBarChart = styled(BarChart)`
  padding: 20px 20px 0;

  .recharts-default-legend {
    display: flex !important;
    gap: 20px;

    .recharts-legend-item {
      display: flex !important;
      align-items: center;
    }
  }
`

export const RechatLegend = styled(Legend)``

export const Label = styled.span`
  color: #333333;
`

export const CustomTooltipWrapper = styled(BaseBorder)``

export const OlderInspectionsWrapper = styled(BaseBorder)`
  padding: 0px;
  box-sizing: border-box;
`

export const HeaderInfos = styled(Box)`
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-bottom: 10px;
`

export const StatusTooltipWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
  margin-right: 5px;
`

export const OptionWrapper = styled(Box)`
  height: calc(100% - 100px);
  max-height: 320px;
  padding: 0 20px;
  overflow: auto;
`

export const OlderInspectionItem = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray);
`

export const OlderInspectionLine = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  padding-right: 20px;
  cursor: default;
`

export const BottomInfo = styled.p`
  box-sizing: border-box;
  padding: 20px;
  padding-top: 10px;
`

export const Benchmark = styled(BaseBorder)`
  position: relative;
  padding: 20px;
  min-height: 400px;
  max-height: 400px;

  .recharts-wrapper {
    width: 100% !important;

    .recharts-legend-wrapper,
    .recharts-default-legend {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 350px !important;
    }
  }

  .table-type {
    position: absolute;
    writing-mode: vertical-rl;
    white-space: nowrap;

    top: 50%;
    left: 45px;
    transform-origin: left;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  .MuiOutlinedInput-input {
    background-color: #155f99;
    color: var(--light) !important;
  }
`

export const MapWrapper = styled(BaseBorder)`
  padding: 20px;
  width: 100%;
  height: 500px;
`

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SiteInputWrapper = styled(Box)`
  margin: 10px 0;
`

export const Chip = styled(MuiChip)`
  cursor: pointer;
  font-weight: bold;
`

export const RestartAlt = styled(MuiRestartAlt)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};
  border-radius: 50%;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
`

export const MapContent = styled(Box)`
  width: 100%;
  height: 100%;

  .mapboxgl-map {
    width: 100% !important;
    height: calc(100% - 85px) !important;
  }
`

export const TableCell = styled(MUITableCell)`
  &.MuiTableCell-root {
    a {
      text-decoration: none;
      color: ${theme.colors.link};
      font-weight: bold;
      letter-spacing: 0.8px;
    }
  }
`

export const SiteReportWrapper = styled(BaseBorder)`
  border: 1px solid ${theme.colors.gray2};
  padding: 0;

  h4 {
    padding: 20px;
  }
`

export const LegendWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
`

export const LegendItem = styled(Box)`
  display: flex;
  gap: 5px;
  align-items: center;
`
