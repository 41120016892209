import { Box } from '@mui/material'
import styled from 'styled-components'

export const CloseButtonWrapper = styled(Box)`
  position: absolute;
  top: 30px;
  right: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`
