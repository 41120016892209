/* eslint-disable react/react-in-jsx-scope */
import { ToastContent } from 'react-toastify'
import { IErrorModel } from '~/services/RequestService/types'
import { DatabaseIcon } from './Icons/DatabaseIcon'
import { IToastProps } from './Interface/IToastProps'

const ToastTypes = (params: IToastProps) => {
  const { type, iconFirst, message, errorMessage, errors = [] } = params
  const toastCss = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: '8px'
  }

  const errorListing = (mistakes: IErrorModel[]) => {
    const errorMessages: any[] = [];
    const listErrorMessages: any[] = [];
    let counterMistakes = 0;

    mistakes.map((item) => {
      counterMistakes + 1;
      item.message.map((item) => {
        return errorMessages.push(item);
      });
    });
    errorMessages.map((item, index) => {
      return listErrorMessages.push(item + `${index + 1 < errorMessages.length ? index + 2 < errorMessages.length ? ', ' : ', ' : ''}`);
    });
    return listErrorMessages.join('');
  };

  const renderToast = (icon?: JSX.Element): ToastContent => (
    <span style={toastCss}>
      <div style={{ marginRight: '16px' }}>{icon}</div>
      <div>
        <p style={{ padding: 0, margin: 0, textAlign: 'center', fontSize: 16 }}>{`${message}`}</p>
        <p style={{ padding: 0, margin: 0, textAlign: 'center', fontSize: 16, marginTop: '4px' }}>{`${errors?.length > 0 ? errorListing(errors) : (errorMessage ?? '')}`}</p>
      </div>
    </span>
  )

  if (message !== '') {
    switch (type) {
      case 'success':
        return renderToast()
      case 'error':
        return renderToast()
      case 'update':
        return renderToast()
      case 'info':
        return renderToast()
      case 'loading-info':
        return renderToast(<DatabaseIcon />)
      case 'custom':
        return renderToast(iconFirst)
      default:
        return renderToast()
    }
  }
}

export default ToastTypes
