import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Box, TableCell, TableContainer, Paper, TablePagination, Typography, Tooltip, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { deleteFileIrradiancia, getInspectionsAll, postFile, postProcessIrradiancia } from '~/services/InspectionsFile';
import ToastNotification from '~/components/ToastNotification';
import ErrorToast from '~/utils/toastErrorCatch';
import { InspectionFileDto } from '~/services/InspectionsFile/types';
import MaterialTable from '~/components/Material/Table';
type FileTableData = {
  originalFileName: string;
  uploadDate: string;
  length: string;
  actions: JSX.Element;
};

const FileIrradianciaModal: React.FC<{ open: boolean, onClose: () => void, inspectionId: string }> = ({
  open,
  onClose,
  inspectionId
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [files, setFiles] = useState<InspectionFileDto[]>([]);
  const [fileName, setFileName] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const loadFiles = async () => {
    try {
      const responseFiles = await getInspectionsAll({
        InspectionId: inspectionId,
        FileType: 7,
        PageNumber: page + 1,
        PageSize: rowsPerPage
      });
      if (responseFiles.success) {
        setFiles(responseFiles.data);
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os arquivos, tente novamente mais tarde.'),
          errorMessage: responseFiles.errorDetails,
          errors: responseFiles.errors,
        });
      }
    } catch (error) {
      ErrorToast({ message: t('Não foi possível carregar os arquivos, tente novamente mais tarde.') });
    }
  };

  const uploadFiles = async () => {
    if (!file) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Selecione um arquivo para fazer upload.')
      });
      return;
    }
    setLoading(true);
    try {

      const formData = new FormData();
      formData.append('File', file);
      formData.append('InspectionId', inspectionId);
      formData.append('FileType', '7');
      formData.append('ValidateSubparksNames', 'false');

      const response = await postFile(formData)
      if (response.success) {
        loadFiles();
        setFile(null);
        setFileName('');
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Arquivo de irradiância enviada com sucesso!') ?? 'Arquivo de irradiância enviada com sucesso!',
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t(response.message),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível enviar o arquivo de irradiância, tente novamente mais tarde.')} />
      )
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async (fileId: string) => {
    try {
      const response = await deleteFileIrradiancia({
        id: fileId
      })
      if (response.success) {
        loadFiles();
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Arquivo de irradiância removido com sucesso!') ?? 'Arquivo de irradiância removido com sucesso!',
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t(response.message),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível remover o arquivo de irradiância, tente novamente mais tarde.')} />
      )
    }
  };

  const handleProcess = async () => {
    try {
      const response = await postProcessIrradiancia({
        allAnomalies: false,
        inspectionId: inspectionId
      })
      if (response.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Processamento iniciado com sucesso! Lembre-se que demora um certo tempo.') ?? 'Processamento iniciado com sucesso! Lembre-se que demora um certo tempo.',
        })
        onClose()
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t(response.message),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Erro ao iniciar o processamento, tente novamente mais tarde.')} />
      )
    }
  }

  useEffect(() => {
    if (open) {
      loadFiles();
    }
  }, [open, page, rowsPerPage]);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  const mappedFiles: FileTableData[] = files.map(file => ({
    originalFileName: file.originalFileName ?? t('Não encontrado.'),
    uploadDate: new Date(file.uploadDate).toLocaleDateString(),
    length: `${(file.length / 1024).toFixed(2)} KB`,
    actions: (
      <IconButton onClick={() => handleDelete(file.id)} color="error">
        <DeleteIcon />
      </IconButton>
    )
  }));

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle sx={{ backgroundColor: '#f5f5f5', padding: '16px', borderBottom: '1px solid #ddd', textAlign: 'center', fontWeight: 'bold', color: '#333' }}>
        {t('Gerenciar Irradiância')}
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} style={{ height: '450px', overflow: 'auto' }}>
          <MaterialTable<FileTableData>
            headCells={[
              { label: t('Nome do Arquivo'), value: 'originalFileName', width: '60%', alignment: 'left' },
              { label: t('Data de Envio'), value: 'uploadDate', width: '20%', alignment: 'left' },
              { label: t('Tamanho'), value: 'length', width: '10%', alignment: 'left' },
              { label: t('Ações'), value: '', width: '10%' }
            ]}

            page={0}
            tableValues={mappedFiles}
            renderRow={(row) => {
              return (
                <>
                  <TableCell style={{ width: '60%' }}>{row.originalFileName}</TableCell>
                  <TableCell style={{ width: '20%' }}>{row.uploadDate}</TableCell>
                  <TableCell style={{ width: '10%' }}>{row.length}</TableCell>
                  <TableCell align="right" style={{ width: '10%' }}>{row.actions}</TableCell>
                </>
              );
            }}
            pagination={() => (
              <TablePagination
                component="div"
                rowsPerPageOptions={[10]}
                count={files.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}

          />

        </TableContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title={t('Envie somente arquivos do tipo: .xlsx')}>
              <InfoIcon sx={{ fontSize: 20, verticalAlign: 'middle', ml: 0.5 }} color="primary" />
            </Tooltip>
            <Typography variant="h6">
              {t('Clique abaixo para adicionar um novo arquivo.')}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              minHeight: '6rem',
              p: 2,

              border: '2px dashed #ccc',
              flexDirection: 'column',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >

            {fileName && (
              <Typography variant="subtitle1" sx={{ width: '100%', textAlign: 'center' }}>
                {t('Arquivo selecionado')}: {fileName}
              </Typography>
            )}

            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: 1 }}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                component="label"
                fullWidth
              >
                {t('Adicionar Arquivo')}
                <input
                  type="file"
                  hidden
                  accept=".xlsx"
                  onChange={handleChangeFile}
                />
              </Button>
              <Button
                variant="contained"
                startIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <FileUploadIcon />}
                onClick={uploadFiles}
                sx={{ flexGrow: 1, ml: 1 }}
                disabled={!file}
              >
                {t('Enviar')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 1, mr: 2 }}>
        <Button onClick={onClose} variant='outlined' color="primary" >
          {t('Fechar')}
        </Button>
        <Button onClick={handleProcess} variant='contained' color="primary">
          {t('Processar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileIrradianciaModal;
