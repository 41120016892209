import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface CustomTypographyProps extends TypographyProps {
  bold?: boolean;
  marginTop?: string;
}

export const CustomTypography: React.FC<CustomTypographyProps> = ({
  children,
  bold = false,
  marginTop = '0px',
  ...props
}) => (
  <Typography
    variant="body2"
    component="div"
    style={{
      fontWeight: bold ? 'bold' : 'normal',
      marginTop: marginTop,
    }}
    {...props}
  >
    {children}
  </Typography>
);
