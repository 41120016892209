import { makeStyles } from '@mui/material'
import styled from 'styled-components/macro'
import { InputWrapperProps } from '../Sites/UploadImages/types'

export const Container = styled.div`
  margin: 0 10%;
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`

export const Input = styled.input`
  padding: 10px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #c5c5c5;
  width: 100%;
  background-color: #f7f7f7;
  height: 40px;

  &:focus {
    border: 1px solid #f89722;
  }

  &[type='file'] {
    border: none;
    display: flex;
    align-items: center;
  }
`
export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  font-family: 'open sans', sans-serif;
  font-size: 14px;
  color: #393939;
  width: ${(props) => props.width ?? '100%'};
`
export const FloatingLabel = styled.label`
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 0 4px;
  background-color: #f7f7f7;
  transition: all 0.3s;
  pointer-events: none;
  color: #8c9aa4;
  font-family: 'open sans', sans-serif;

  ${Input}:focus ~ & {
    top: -10px;
    left: 5px;
    font-size: 12px;
    color: #f89722;
  }

  ${Input}:not(:placeholder-shown) ~ & {
    top: -10px;
    left: 5px;
    font-size: 12px;
  }
`

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Imagem = styled.img`
  display: block;
  border-radius: 10px;
`
export const Label = styled.label`
  color: #5a5c5e;
  display: flex;
  flex-direction: column;
`
export const ContainerInput = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-self: center;
`

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-self: center;
  padding: 62px 40px 24px 40px;
`

export const ContainerDoubleInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  align-self: center;
`

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  align-self: center;

  border: 2px solid ${(props) => props.theme.colors.blueRGBA2};
  border-radius: 16px;
  padding: 40px 40px 16px 40px;
  margin-top: 4px;
  margin-bottom: 40px;
  background-color: ${(props) => props.theme.colors.white};
`

export const TextForm = styled.p`
  line-height: 24px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 18px;
  align-self: center;
  display: flex;
  width: 80%;
  color: ${(props) => props.theme.colors.black};
`