import { getJson, postJson } from "../RequestService"
import { IRequestPostSiteValues, IRequestSiteValues, IResponseSiteValues } from "./types"

const defaultRoot = 'sitevalues'

export const getSiteValues = async (data: IRequestSiteValues) => {
  let url = `${defaultRoot}?siteId=${data.siteId}`
  return await getJson<IResponseSiteValues>(url)
}

export const postSiteValues = async (data: IRequestPostSiteValues) => {
  let url = `${defaultRoot}`
  return await postJson<IRequestPostSiteValues, string>(url, data)
}

