import { ImageProps } from '~/services/Site/types'

export class Registration {
  id = ''
  name = ''
  local = ''
  descricao = ''
  powerOutput = ''
  inverters = ''
  mounting = ''
  moduleType = ''
  moduleTechnology = ''
  moduleLayout = ''
  panelInfo = ''
  latitudeSite = ''
  longitudeSite = ''
  coverImageUrl = ''
  OrthomosaicImage = ''
  electricalDiagramImage = ''
  companyId = ''
  PanelHeight: number | string = 1.5;
  CommissioningDate = ''
  ArcGisName = ''
}

export class RegistrationError {
  id = ''
  name = ''
  local = ''
  descricao = ''
  powerOutput = ''
  inverters = ''
  mounting = ''
  moduleType = ''
  moduleTechnology = ''
  moduleLayout = ''
  panelInfo = ''
  latitudeSite = ''
  longitudeSite = ''
  coverImageUrl = ''
  OrthomosaicImage = ''
  electricalDiagramImage = ''
  companyId = ''
  PanelHeight = '';
  CommissioningDate = ''
  ArcGisName = ''
}

export interface FileState {
  name: string
}

export interface ImageValuesProps {
  orthomosaic: ImageProps
  electricalDiagram: ImageProps
  moduleReferenceImage: ImageProps

}
